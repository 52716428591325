import { observable, makeObservable, reaction } from 'mobx';
import { firebaseAuth } from './firebase.auth.js';
import { UserManager } from '../models/user-manager.js';

export class Auth {
  constructor() {
    this.authUser = null; // the firebase auth provided user data
    this.userManager = UserManager({listenMode: false});

    makeObservable(this, {
      authUser:observable.ref,
    });

    firebaseAuth.onAuthStateChanged(user => {
      this.authUser = user;
      if (user) {
        this.userManager.loadUserByEmail(user.email, false); // listenMode: false
      }
    });
  }

  get appUser() {
    return this.userManager.model;
  }
}
