import { makeObservable, observable } from 'mobx';
import { isEmpty } from 'lodash-es';
import { db } from '../db/firebase.init.js';
import { BaseLoader } from '../../firestore-db/base-loader';

export const UserManager = (options) => new UserManager0(options);

export class UserManager0 extends BaseLoader {
  constructor(options) {
    super(options);
    this.collectionRef = db.collection("User__metadata");

    this.queriedEmail = options.email;
    this.filterText = '';
    makeObservable(this, {
      filterText: observable.ref,
    })
  }

  setFilterText(text) {
    this.filterText = text;
  }

  loadUserByEmail(email, listenMode=true) {
    this.queriedEmail = email;
    this.data = [];
    this.listenMode = listenMode;
    this.load({email});
  }

  loadAll(listenMode=true) {
    this.queriedEmail = null;
    this.data = [];
    this.listenMode = listenMode;
    this.load({});
  }

  // needs more thought
  get model() {
    if (isEmpty(this.queriedEmail)) {
      // 'loadAll' mode
      if (isEmpty(this.filterText)) {
        return this.data;
      } else {
        // todo: matched against more fields
        return this.data.filter(user => user.alias.includes(this.filterText));
      }
    } else {
      // fetching single user by email mode
      return this.data[0];
    }
  }

  /** @return CollectionReference or DocumentReference of data to be loaded */
  loadReference({email = null} = {}) {
    if (isEmpty(email)) {
      return this.collectionRef;
    } else {
      return this.collectionRef.where('email', '==', email);
    }
  }

  /**
  * @snapshot QuerySnapshot or DocumentSnapshot
  * @return model data
  */
  snapshotToData(snapshot) {
    const result = [];
    // todo, think about specialized handling of document specific updates callbacks
    snapshot.forEach(documentSnapshot => {
      result.push(documentSnapshot.data());
    });
    return result;
  }
}
