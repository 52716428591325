import { comparer as comparer_1, when, configure } from "mobx";
import { equals } from "../../.fable/fable-library.3.1.0-beta-001/Util.js";

export const mobxConfigure = configure;

export function promiseWhenChanged(expression) {
    const value = expression();
    return when(() => (!equals(value, expression())), {});
}

export const comparer = comparer_1;

