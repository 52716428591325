import { NO_INDEX } from "../../sorted/sorted.fs.js";
import { elementIdToDomId } from "../../basic-types.fs.js";

export const wordOpening = "\u003cspan class=\"word ";

export const wordClosing0 = "\u003c/span\u003e";

export const wordClosing = " " + wordClosing0;

export const transitionClosing = wordClosing0;

export const wordGroupOpening = "\u003cspan class=\"word-group ";

export const wordGroupClosing = "\u003c/span\u003e ";

export const wordGroupKindStyles = {
    SIC: " sic ",
    TRICKY: " tricky ",
    VOCAB: " vocab ",
};

export function renderWordRange(domScope, styleOverlays, wordElements, indexRange, wordGroups) {
    let html = "";
    const words = wordElements.elements;
    const wordGroupIntervals = wordGroups.wordIntervals;
    let wordGroupStartIndex = NO_INDEX | 0;
    let wordGroupEnds = false;
    let nestLevel = 0;
    for (let i = indexRange.starts; i <= indexRange.ends; i++) {
        const word = words[i];
        const text = word.text;
        const wordId = word.id;
        const domId = elementIdToDomId(domScope, wordId);
        const wordStyles = styleOverlays.getStyleForWordAddress(i);
        wordGroupStartIndex = wordGroupIntervals.startsAt(i);
        if (wordGroupStartIndex !== NO_INDEX) {
            if (nestLevel > 0) {
                wordGroupStartIndex = NO_INDEX;
            }
            nestLevel = (nestLevel + 1);
        }
        const wordGroupEndsIndex = wordGroupIntervals.endsAt(i) | 0;
        wordGroupEnds = (wordGroupEndsIndex !== NO_INDEX);
        if (wordGroupEnds) {
            if (nestLevel > 1) {
                wordGroupEnds = false;
            }
            nestLevel = (nestLevel - 1);
        }
        if (wordGroupStartIndex !== NO_INDEX) {
            html = (html + wordGroupOpening);
            const wordGroup = wordGroups.elements[wordGroupStartIndex];
            const wordGroupId = wordGroup.id;
            const wordGroupKindStyle = (wordGroupKindStyles[wordGroup.subKind]);
            const wordGroupStyles = " " + styleOverlays.getStyleForElement(wordGroupId);
            html = (((((html + wordGroupKindStyle) + wordGroupStyles) + "\" id=\"") + (wordGroupId)) + "\" \u003e");
        }
        html = (((((html + wordOpening) + wordStyles) + "\" id=\"") + domId) + "\" \u003e");
        html = (html + text);
        html = (html + (wordGroupEnds ? (transitionClosing + wordGroupClosing) : wordClosing));
    }
    return html;
}

