import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { auth, conversationManager } from "../app-root.fs.js";
import { CommentBox } from "../masala-lib/editorial/ui/comment-box.js";
import { Conversation } from "../masala-lib/editorial/ui/timeline-components.js";

export const ConversationView = observer(({element}) => {

  const elementId = element.id;
  const conversation = conversationManager.getConversation(elementId);

  const [editCommentId, setEditCommentId] = useState(null);
  const [initialText, setInitialText] = useState("");

  const handleCommentSubmit = (message) => {
    if (editCommentId) {
      conversationManager.updateComment(elementId, editCommentId, message)
      setEditCommentId(null);
      setInitialText("");
    } else {
      conversationManager.postComment(elementId, auth.appUser?.id, message)
    }
  };

  const handleDeleteComment = (comment) => {
    conversationManager.deleteComment(elementId, comment.id);
  };

  const handleEditComment = (comment) => {
    setEditCommentId(comment.id);
    setInitialText(comment.content);
  };

  return (
    <div>
      <Conversation conversation={conversation} onDelete={handleDeleteComment} onEdit={handleEditComment}/>
      <CommentBox onSubmit={handleCommentSubmit} initialText={initialText}/>
    </div>
  );
});
