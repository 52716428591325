export const deploymentConfig = {
  consoleUrl: process.env.REACT_APP_CONSOLE_URL || "http://localhost:4000",
  scriptEditorUrl: process.env.REACT_APP_SCRIPT_EDITOR_URL || "http://localhost:4100",
  chaatUrl: process.env.REACT_APP_CHAAT_URL || "http://localhost:4200",
  reviewToolUrl: process.env.REACT_APP_REVIEW_TOOL_URL || "http://localhost:4300",
  masalaServerUrl: process.env.REACT_APP_MASALA_SERVER_URL || "http://localhost:4500",
  falconServerUrl: process.env.REACT_APP_FALCON_SERVER_URL || "http://localhost:4600",
  railsServerUrl: process.env.REACT_APP_RAILS_SERVER_URL || "http://localhost:3000",
  slackApiKey: process.env.SREACT_APP_SLACK_API_KEY || "xoxb-155822064134-1365738549143-y1ZXZZdDwjcedDCBKo6mF4qi",
  slackConversationsChannel: process.env.REACT_APP_SLACK_CONVERSATIONS_CHANNEL || "eng-testing-feed",
};

console.log(`deploymentConfig: ${JSON.stringify(deploymentConfig)}`);

export const getDeploymentConfig = (key) => {
  // console.log(`getDeploymentConfig(${key}): ${JSON.stringify(deploymentConfig[key])}`);
  return deploymentConfig[key];
}
