import { isDeletedId, getIndex } from "../ids/positional-ids.fs.js";
import { JSObject, Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505, System_Array__$005B$005D$1_get_lastIndex, System_Array__$005B$005D$1_append_1505 } from "../../basic-types.fs.js";
import { getSentenceWordIdRange } from "../../content-funcs.fs.js";
import { empty, singleton, collect, indexed, getEnumerator, map, delay } from "../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { Precedence } from "../../elements/precedence.fs.js";
import { makeAdhocWordRange } from "../../elements/adhoc-word-range.fs.js";
import { map as map_1, sortBy, copy, indexed as indexed_1 } from "../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { comparePrimitives, equals } from "../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { ElementList } from "../../elements/element-list.fs.js";
import { FSharpRef } from "../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type } from "../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { sortElements } from "../../elements/element-sort.fs.js";
import lodash from "lodash";
import { Interval, fromIntervals, size, SortedOptsRecord, Sorted } from "../../sorted/sorted.fs.js";
import { interpolate, toText } from "../../../.fable/fable-library.3.1.0-beta-001/String.js";

export function mapSentenceAnchorsToWordAddresses(sentences, words) {
    for (let idx = 0; idx <= (sentences.length - 1); idx++) {
        const sentence = sentences[idx];
        const sentenceAnchors = sentence.anchors;
        sentence.wordAddress = words.getIndex(sentenceAnchors.startWordId);
        sentence.endWordAddress = (words.getIndex(sentenceAnchors.endWordIdExclusive) - 1);
    }
}

export function mapStructuralAnchorsToWordAddresses(structural, words, sentenceIntervals) {
    for (let idx = 0; idx <= (structural.length - 1); idx++) {
        const element = structural[idx];
        const wordId = (element.anchors) | 0;
        element.wordAddress = words.getIndex(wordId);
    }
}

export function mapWordGroupAnchorsToWordAddresses(wordGroups, wordIdMapping) {
    for (let idx = 0; idx <= (wordGroups.length - 1); idx++) {
        const wordGroup = wordGroups[idx];
        const wordGroupAnchors = wordGroup.anchors;
        wordGroup.wordAddress = getIndex(wordIdMapping, wordGroupAnchors.startWordId);
        const endWordId = wordGroupAnchors.endWordId | 0;
        let index0 = getIndex(wordIdMapping, endWordId) | 0;
        if (isDeletedId(wordIdMapping, endWordId) ? (index0 > 0) : false) {
            index0 = (index0 - 1);
        }
        wordGroup.endWordAddress = index0;
    }
}

export function partitionOrphanedWordGroups(wordGroups) {
    const nonOrphaned = [];
    const orphaned = [];
    for (let idx = 0; idx <= (wordGroups.length - 1); idx++) {
        const g = wordGroups[idx];
        if (g.wordAddress > g.endWordAddress) {
            System_Array__$005B$005D$1_append_1505(orphaned, g);
        }
        else {
            System_Array__$005B$005D$1_append_1505(nonOrphaned, g);
        }
    }
    return {
        nonOrphaned: nonOrphaned,
        orphaned: orphaned,
    };
}

export function mapElementsWordAddressesToTimes(elements, words) {
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const elem = elements[idx];
        elem.time = words[elem.wordAddress].time;
        elem.endTime = words[elem.endWordAddress].endTime;
    }
    return elements;
}

export function getElementsTimestampingSignature(elements) {
    let result = "";
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const element = elements[idx];
        result = (((result + element.id) + "_") + (element.time));
    }
    return result;
}

export function getSentenceTimestampingSignature(sentence, words) {
    const wordRange = getSentenceWordIdRange(sentence, words);
    const sentenceWords = Array.from(delay(() => map((word) => word, words.idRangeAsElementSeq(wordRange))));
    return getElementsTimestampingSignature(sentenceWords);
}

export function translationId(id, translationLanguage) {
    const stringId = id;
    return ((stringId + "/") + translationLanguage);
}

export function interleave(elements, lookup, direction) {
    const result = [];
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const el = elements[idx];
        const found = lookup(el.id);
        if (found) {
            if (direction === -1) {
                System_Array__$005B$005D$1_append_1505(result, found);
                System_Array__$005B$005D$1_append_1505(result, el);
            }
            else {
                System_Array__$005B$005D$1_append_1505(result, el);
                System_Array__$005B$005D$1_append_1505(result, found);
            }
        }
        else {
            System_Array__$005B$005D$1_append_1505(result, el);
        }
    }
    return result;
}

export const metadataOrder = Precedence(["METADATA-URL", "NOTES", "ASSET-LINKS", "CAST"]);

export const languageCodeToLanguage = {
    ["de-DE"]: "german",
    ["en-US"]: "english",
    ["es-US"]: "spanish",
    ["pt-BR"]: "portuguese",
};

export function cuedWordsFromCues(cues, words) {
    const wordIds = Array.from(delay(() => map((cue) => cue.wordId, cues)));
    return words.fromIds(wordIds);
}

export function segmentStopWordsFromWords(words) {
    const allGaps = words.timeIntervals.fromGapIntervals(-1).asIntervalSeq();
    let lastUsedGapEnd = 0;
    const wordIndexes = [];
    const enumerator = getEnumerator(indexed(allGaps));
    try {
        while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
            const forLoopVar = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
            const index = forLoopVar[0] | 0;
            const gap = forLoopVar[1];
            const gapSize = (gap.ends - gap.starts) | 0;
            if (gapSize >= 30) {
                if (((gap.starts - lastUsedGapEnd) < 1500) ? (gapSize < 225) : false) {
                }
                else {
                    System_Array__$005B$005D$1_append_1505(wordIndexes, index);
                    lastUsedGapEnd = gap.ends;
                }
            }
        }
    }
    finally {
        enumerator.Dispose();
    }
    return words.fromIndexes(wordIndexes);
}

export function wordIdRangesFromTimeIntervals(intervals, words) {
    const wordTimeIntervals = words.timeIntervals;
    const indexRanges = wordTimeIntervals.mapRangesContained(intervals.asIntervalSeq());
    const resultElements = [];
    for (let idx = 0; idx <= (indexRanges.length - 1); idx++) {
        const range = indexRanges[idx];
        if (range) {
            const idRange = words.indexRangeToIdRange(range);
            System_Array__$005B$005D$1_append_1505(resultElements, makeAdhocWordRange(idRange, words));
        }
        else {
            System_Array__$005B$005D$1_append_1505(resultElements, null);
        }
    }
    return resultElements;
}

export function filterDeleted(elements) {
    const results = [];
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const element = elements[idx];
        if (!element.deleted) {
            System_Array__$005B$005D$1_append_1505(results, element);
        }
    }
    return results;
}

export function splitWarningElementsByWarningType(idRanges, warningData, words) {
    const minorWarningRanges = [];
    const majorWarningRanges = [];
    const arr = indexed_1(idRanges);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const range = forLoopVar[1];
        const index = forLoopVar[0] | 0;
        if (range) {
            if (equals(warningData[index], "silence")) {
                System_Array__$005B$005D$1_append_1505(majorWarningRanges, range);
            }
            else {
                System_Array__$005B$005D$1_append_1505(minorWarningRanges, range);
            }
        }
    }
    const minorWarnings = ElementList(minorWarningRanges, null, null, words, null, null, null);
    return {
        majorWarnings: ElementList(majorWarningRanges, null, null, words, null, null, null),
        minorWarnings: minorWarnings,
    };
}

export class ContentRootsBase0 {
    constructor() {
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = (s0.contents);
        this.episodeMetadataDoc = (null);
        this.verbatimDoc = (null);
        this.structuralDoc = (null);
        this.wordGroupsDoc = (null);
        this.translationsDoc = (null);
        this.metadataBlocksDoc = (null);
        this.warningSuppressionsDoc = (null);
        this.episodeKey = "";
        const audioLanguageCode = "es-US";
        this.timestampsDoc = (null);
        this.cuesDoc = (null);
        this.speechTranscriptDoc = (null);
        this.audioAnalysisDoc = (null);
        this.audioRegionsDoc = (null);
        this.audioMarkersDoc = (null);
        this.chaatMetadataDoc = (null);
        this.audioProcessingJobDoc = (null);
        const transcriptionJobDoc = null;
        this.chaatSignoffsDoc = (null);
        this["init@220-1"] = 1;
        this.audioLanguageCode = audioLanguageCode;
        this.transcriptionJobDoc = transcriptionJobDoc;
        this.orphanedAndNonOrphanedWordGroups$c1iijRy1l767 = null;
        makeObservable(this, {
            episodeMetadataDoc: observable.ref,
            verbatimDoc: observable.ref,
            structuralDoc: observable.ref,
            wordGroupsDoc: observable.ref,
            translationsDoc: observable.ref,
            metadataBlocksDoc: observable.ref,
            warningSuppressionsDoc: observable.ref,
            episodeKey: observable.ref,
            timestampsDoc: observable.ref,
            cuesDoc: observable.ref,
            speechTranscriptDoc: observable.ref,
            audioAnalysisDoc: observable.ref,
            audioRegionsDoc: observable.ref,
            audioMarkersDoc: observable.ref,
            chaatMetadataDoc: observable.ref,
            audioProcessingJobDoc: observable.ref,
            transcriptionJobDoc: observable.ref,
            chaatSignoffsDoc: observable.ref,
            words0: computed,
            words1: computed,
            sentences0: computed,
            sentences1: computed,
            sentencesList0: computed,
            structural0: computed,
            wordGroups1: computed,
            translations0: computed,
            metadataBlocks0: computed,
            warningSuppressions: computed({keepAlive: true}),
            chaatInputCues: computed({keepAlive: true}),
            cuedWords: computed({keepAlive: true}),
            cueTimestamps: computed({keepAlive: true}),
            cueDisplayTimeIntervals: computed({keepAlive: true}),
            segmentTimeIntervals: computed({keepAlive: true}),
            segmentStopWords: computed({keepAlive: true}),
            notchTimeIntervals: computed({keepAlive: true}),
            silenceTimeIntervals: computed({keepAlive: true}),
            nonVoiceAudioRegions: computed({keepAlive: true}),
            nonVoiceAudioRegionIntervals: computed({keepAlive: true}),
            audioMarkers: computed({keepAlive: true}),
            audioMarkerHitIntervals: computed({keepAlive: true}),
            interpolatedTimeIntervals: computed({keepAlive: true}),
            warningTimeIntervals: computed({keepAlive: true}),
            sentenceTimestampingSignatures: computed({keepAlive: true}),
            chaatSignoffs: computed({keepAlive: true}),
            chaatUnsignedoffSentences: computed({keepAlive: true}),
            warnings: computed({keepAlive: true}),
            majorWarnings: computed({keepAlive: true}),
            minorWarnings: computed({keepAlive: true}),
            warningSentenceIds: computed({keepAlive: true}),
            transcriptWords: computed({keepAlive: true}),
            transcriptWordTimeIntervals: computed({keepAlive: true}),
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    get audioLanguage() {
        return ContentRootsBase0__audioLanguage(this)
    }
    get audioMarkerHitIntervals() {
        return ContentRootsBase0__audioMarkerHitIntervals(this)
    }
    get audioMarkers() {
        return ContentRootsBase0__audioMarkers(this)
    }
    get audioUrls() {
        return ContentRootsBase0__audioUrls(this)
    }
    get chaatInputCues() {
        return ContentRootsBase0__chaatInputCues(this)
    }
    get chaatSignoffs() {
        return ContentRootsBase0__chaatSignoffs(this)
    }
    get chaatUnsignedoffSentences() {
        return ContentRootsBase0__chaatUnsignedoffSentences(this)
    }
    get cueDisplayTimeIntervals() {
        return ContentRootsBase0__cueDisplayTimeIntervals(this)
    }
    get cueTimestamps() {
        return ContentRootsBase0__cueTimestamps(this)
    }
    get cuedWords() {
        return ContentRootsBase0__cuedWords(this)
    }
    get editEnabled() {
        return ContentRootsBase0__editEnabled(this)
    }
    get interpolatedTimeIntervals() {
        return ContentRootsBase0__interpolatedTimeIntervals(this)
    }
    get majorWarnings() {
        return ContentRootsBase0__majorWarnings(this)
    }
    get metadataBlocks0() {
        return ContentRootsBase0__metadataBlocks0(this)
    }
    get minorWarnings() {
        return ContentRootsBase0__minorWarnings(this)
    }
    get nonVoiceAudioRegionIntervals() {
        return ContentRootsBase0__nonVoiceAudioRegionIntervals(this)
    }
    get nonVoiceAudioRegions() {
        return ContentRootsBase0__nonVoiceAudioRegions(this)
    }
    get notchTimeIntervals() {
        return ContentRootsBase0__notchTimeIntervals(this)
    }
    get orphanedWordGroups() {
        return ContentRootsBase0__orphanedWordGroups(this)
    }
    get segmentStopWords() {
        return ContentRootsBase0__segmentStopWords(this)
    }
    get segmentTimeIntervals() {
        return ContentRootsBase0__segmentTimeIntervals(this)
    }
    get sentenceTimestampingSignatures() {
        return ContentRootsBase0__sentenceTimestampingSignatures(this)
    }
    get sentences0() {
        return ContentRootsBase0__sentences0(this)
    }
    get sentences1() {
        return ContentRootsBase0__sentences1(this)
    }
    get sentencesList0() {
        return ContentRootsBase0__sentencesList0(this)
    }
    get silenceTimeIntervals() {
        return ContentRootsBase0__silenceTimeIntervals(this)
    }
    get structural0() {
        return ContentRootsBase0__structural0(this)
    }
    get transcriptWordTimeIntervals() {
        return ContentRootsBase0__transcriptWordTimeIntervals(this)
    }
    get transcriptWords() {
        return ContentRootsBase0__transcriptWords(this)
    }
    get translationLanguage() {
        return ContentRootsBase0__translationLanguage(this)
    }
    get translations0() {
        return ContentRootsBase0__translations0(this)
    }
    get warningData() {
        return ContentRootsBase0__warningData(this)
    }
    get warningSentenceIds() {
        return ContentRootsBase0__warningSentenceIds(this)
    }
    get warningSuppressions() {
        return ContentRootsBase0__warningSuppressions(this)
    }
    get warningTimeIntervals() {
        return ContentRootsBase0__warningTimeIntervals(this)
    }
    get warnings() {
        return ContentRootsBase0__warnings(this)
    }
    get wordGroups0() {
        return ContentRootsBase0__wordGroups0(this)
    }
    get wordGroups1() {
        return ContentRootsBase0__wordGroups1(this)
    }
    get words0() {
        return ContentRootsBase0__words0(this)
    }
    get words1() {
        return ContentRootsBase0__words1(this)
    }
    get audioUrls() {
        return ContentRootsBase0__audioUrls(this)
    }
}

export function ContentRootsBase0$reflection() {
    return class_type("ContentRoots.ContentRootsBase0", void 0, ContentRootsBase0);
}

export function ContentRootsBase0_$ctor() {
    return new ContentRootsBase0();
}

function ContentRootsBase0__wordIdMapping(this$) {
    return this$.verbatimDoc.wordIdMapping;
}

function ContentRootsBase0__editEnabled(this$) {
    return this$.episodeMetadataDoc.editEnabled;
}

function ContentRootsBase0__words0(this$) {
    const mapping = ContentRootsBase0__wordIdMapping(this$);
    return ElementList(this$.verbatimDoc.words, this$.episodeKey, null, null, mapping, null, ((contentId) => getIndex(mapping, contentId)));
}

function ContentRootsBase0__words1(this$) {
    const wordIdMapping = this$.verbatimDoc.wordIdMapping;
    const words = this$.verbatimDoc.words;
    const startTimes = this$.timestampsDoc.wordTimeIntervals.startTimes;
    const endTimes = this$.timestampsDoc.wordTimeIntervals.endTimes;
    for (let i = 0; i <= System_Array__$005B$005D$1_get_lastIndex(words); i++) {
        const word = words[i];
        word.time = startTimes[i];
        word.endTime = endTimes[i];
    }
    return ElementList(words, this$.episodeKey, null, null, wordIdMapping, null, ((contentId) => getIndex(wordIdMapping, contentId)));
}

function ContentRootsBase0__audioLanguage(this$) {
    return "spanish";
}

function ContentRootsBase0__sentences0(this$) {
    const sentences = Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.verbatimDoc.sentences);
    const activeSentences = filterDeleted(sentences);
    mapSentenceAnchorsToWordAddresses(activeSentences, this$.self.words0);
    return activeSentences;
}

function ContentRootsBase0__sentences1(this$) {
    return mapElementsWordAddressesToTimes(this$.self.sentences0, this$.self.words1.elements);
}

function ContentRootsBase0__sentencesList0(this$) {
    const sentences = copy(this$.self.sentences0);
    sortElements(sentences);
    return ElementList(sentences, this$.episodeKey, null, null, null, null, null);
}

function ContentRootsBase0__structural0(this$) {
    if (lodash.isNull(this$.structuralDoc)) {
        return [];
    }
    else {
        const structural = Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.structuralDoc.structural);
        const activeStructural = filterDeleted(structural);
        mapStructuralAnchorsToWordAddresses(activeStructural, this$.self.words0, this$.self.sentencesList0);
        return activeStructural;
    }
}

function ContentRootsBase0__orphanedAndNonOrphanedWordGroups(this$) {  if (!this$.orphanedAndNonOrphanedWordGroups$c1iijRy1l767) { this$.orphanedAndNonOrphanedWordGroups$c1iijRy1l767 = computed(() => {
    const wordGroups = Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.wordGroupsDoc.wordGroups);
    const activeWordGroups = filterDeleted(wordGroups);
    mapWordGroupAnchorsToWordAddresses(activeWordGroups, ContentRootsBase0__wordIdMapping(this$));
    return partitionOrphanedWordGroups(activeWordGroups);
})}; return this$.orphanedAndNonOrphanedWordGroups$c1iijRy1l767.get(); }

function ContentRootsBase0__orphanedWordGroups(this$) {
    return ContentRootsBase0__orphanedAndNonOrphanedWordGroups(this$).orphaned;
}

function ContentRootsBase0__wordGroups0(this$) {
    return ContentRootsBase0__orphanedAndNonOrphanedWordGroups(this$).nonOrphaned;
}

function ContentRootsBase0__wordGroups1(this$) {
    return this$.self.wordGroups0;
}

function ContentRootsBase0__translationLanguage(this$) {
    return this$.episodeMetadataDoc.learnersLanguage;
}

function ContentRootsBase0__translations0(this$) {
    const result = this$.translationsDoc.translations[this$.self.translationLanguage];
    if (result) {
        return result;
    }
    else {
        return {};
    }
}

function ContentRootsBase0__metadataBlocks0(this$) {
    return Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.metadataBlocksDoc.metadata);
}

function ContentRootsBase0__warningSuppressions(this$) {
    if (lodash.isNull(this$.warningSuppressionsDoc)) {
        return new Set([]);
    }
    else {
        return new Set(this$.warningSuppressionsDoc.suppressions);
    }
}

function ContentRootsBase0__chaatInputCues(this$) {
    const result = Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.cuesDoc.cues);
    return sortBy((cue) => cue.timestamp, result, {
        Compare: comparePrimitives,
    });
}

function ContentRootsBase0__cuedWords(this$) {
    return cuedWordsFromCues(this$.self.chaatInputCues, this$.self.words1);
}

function ContentRootsBase0__cueTimestamps(this$) {
    return Int32Array.from(delay(() => map((cue) => cue.timestamp, this$.self.chaatInputCues)));
}

function ContentRootsBase0__cueDisplayTimeIntervals(this$) {
    const timestamps = ContentRootsBase0__cueTimestamps(this$);
    const startPoints = map_1((t) => (t - 2), timestamps, Int32Array);
    const endPoints = map_1((t_1) => (t_1 + 10), timestamps, Int32Array);
    return Sorted(new SortedOptsRecord(startPoints, endPoints, 0, 0));
}

function ContentRootsBase0__segmentTimeIntervals(this$) {
    return this$.self.words1.timeIntervals.fromGapIntervals(20);
}

function ContentRootsBase0__segmentStopWords(this$) {
    return segmentStopWordsFromWords(this$.self.words1);
}

function ContentRootsBase0__notchTimeIntervals(this$) {
    const intervals = this$.audioAnalysisDoc.notchTimeIntervals;
    return Sorted(new SortedOptsRecord(intervals.startTimes, intervals.endTimes, 0, 0));
}

function ContentRootsBase0__silenceTimeIntervals(this$) {
    const notches = this$.self.notchTimeIntervals;
    const silences = Array.from(delay(() => collect((interval) => ((size(interval) >= 125) ? singleton(interval) : empty()), notches.asIntervalSeq())));
    return fromIntervals(silences);
}

function ContentRootsBase0__nonVoiceAudioRegions(this$) {
    const audioRegions = Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(Object, this$.audioRegionsDoc.regions);
    return sortBy((r) => r.startTime, audioRegions, {
        Compare: comparePrimitives,
    });
}

function ContentRootsBase0__nonVoiceAudioRegionIntervals(this$) {
    const intervals = Array.from(delay(() => map((region) => (new Interval(region.startTime, region.endTime)), this$.self.nonVoiceAudioRegions)));
    return fromIntervals(intervals);
}

function ContentRootsBase0__audioMarkers(this$) {
    const markers = Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(Object, this$.audioMarkersDoc.markers);
    return sortBy((r) => r.time, markers, {
        Compare: comparePrimitives,
    });
}

function ContentRootsBase0__audioMarkerHitIntervals(this$) {
    const intervals = Array.from(delay(() => map((marker) => (new Interval(marker.time, marker.time + 35)), this$.self.audioMarkers)));
    return fromIntervals(intervals);
}

function ContentRootsBase0__interpolatedTimeIntervals(this$) {
    const interpolatedIntervals0 = this$.timestampsDoc.interpolationTimeIntervals;
    return Sorted(new SortedOptsRecord(interpolatedIntervals0.startTimes, interpolatedIntervals0.endTimes, 0, 0));
}

function ContentRootsBase0__warningTimeIntervals(this$) {
    const warningIntervals0 = this$.timestampsDoc.warningTimeIntervals;
    return Sorted(new SortedOptsRecord(warningIntervals0.startTimes, warningIntervals0.endTimes, 0, 0));
}

function ContentRootsBase0__sentenceTimestampingSignatures(this$) {
    const words = this$.self.words1;
    const sentences = this$.self.sentences1;
    const result = {};
    for (let idx = 0; idx <= (sentences.length - 1); idx++) {
        const sentence = sentences[idx];
        result[sentence.id]=getSentenceTimestampingSignature(sentence, words);
    }
    return result;
}

function ContentRootsBase0__chaatSignoffs(this$) {
    if (lodash.isNull(this$.chaatSignoffsDoc)) {
        return new Set([]);
    }
    else {
        return new Set(this$.chaatSignoffsDoc.signoffs);
    }
}

function ContentRootsBase0__chaatUnsignedoffSentences(this$) {
    const result = [];
    const sentences = this$.self.sentences1;
    const signatures = this$.self.sentenceTimestampingSignatures;
    const signoffs = this$.self.chaatSignoffs;
    for (let idx = 0; idx <= (sentences.length - 1); idx++) {
        const sentence = sentences[idx];
        const signature = (signatures[sentence.id]);
        if (!signoffs.has(signature)) {
            System_Array__$005B$005D$1_append_1505(result, sentence);
        }
    }
    return ElementList(result, this$.episodeKey, null, this$.self.words1, null, null, null);
}

function ContentRootsBase0__warningData(this$) {
    return this$.timestampsDoc.warningData;
}

function ContentRootsBase0__warnings(this$) {
    const warningElements = wordIdRangesFromTimeIntervals(this$.self.warningTimeIntervals, this$.self.words1);
    const warningData = this$.timestampsDoc.warningData;
    const matchedWarningElements = [];
    for (let i = 0; i <= System_Array__$005B$005D$1_get_lastIndex(warningElements); i++) {
        const warningElement = warningElements[i];
        if (warningElement) {
            const data = warningData[i];
            const subKind = (data === "silences") ? "MAJOR" : "MINOR";
            warningElement.subKind = subKind;
            System_Array__$005B$005D$1_append_1505(matchedWarningElements, warningElement);
        }
    }
    return ElementList(matchedWarningElements, this$.episodeKey, null, this$.self.words1, null, null, null);
}

function ContentRootsBase0__majorWarnings(this$) {
    return this$.self.warnings.filter((e) => (e.subKind === "MAJOR"));
}

function ContentRootsBase0__minorWarnings(this$) {
    return this$.self.warnings.filter((e) => (e.subKind === "MINOR"));
}

function ContentRootsBase0__warningSentenceIds(this$) {
    const warnIntervals = this$.self.warningTimeIntervals;
    return Array.from(delay(() => collect((s) => (warnIntervals.hasIntersecting(s.time, s.endTime) ? singleton(s.id) : empty()), this$.self.sentences1)));
}

function ContentRootsBase0__transcriptWords(this$) {
    return this$.speechTranscriptDoc.transcriptWords;
}

function ContentRootsBase0__transcriptWordTimeIntervals(this$) {
    const intervals = this$.speechTranscriptDoc.transcriptWordTimeIntervals;
    return Sorted(new SortedOptsRecord(intervals.startTimes, intervals.endTimes, 0, 0));
}

function ContentRootsBase0__audioUrls(this$) {
    const audioStorageId = this$.audioProcessingJobDoc.m16000AudioId;
    const downsampledAudioURL = toText(interpolate("https://storage.googleapis.com/jw-timestamper/%P()", [audioStorageId]));
    return {
        audioUrl: this$.chaatMetadataDoc.finalAudioUrl,
        noMusicAudioUrl: this$.chaatMetadataDoc.audioNoMusicUrl,
        transcribeAudioUrl: downsampledAudioURL,
    };
}

export function ContentRootsBase() {
    return ContentRootsBase0_$ctor();
}

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 