import { Record } from "../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type, record_type, enum_type, bool_type, int32_type, string_type } from "../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { append } from "../.fable/fable-library.3.1.0-beta-001/Array.js";

export class AlertMessage extends Record {
    constructor(text, timestamp, forceAcknowlege, level) {
        super();
        this.text = text;
        this.timestamp = (timestamp | 0);
        this.forceAcknowlege = forceAcknowlege;
        this.level = (level | 0);
    }
}

export function AlertMessage$reflection() {
    return record_type("AlertMessages.AlertMessage", [], AlertMessage, () => [["text", string_type], ["timestamp", int32_type], ["forceAcknowlege", bool_type], ["level", enum_type("AlertMessages.AlertLevel", int32_type, [["Info", 1], ["Warning1", 2], ["Warning2", 3]])]]);
}

export const Alert = new AlertMessage("", 0, false, 2);

export class AlertMessages0 {
    constructor() {
        this.keepDuration = 4000;
        this.currentMessages = [];
        makeObservable(this, {
            currentMessages: observable.ref,
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    add(message0) {
        return AlertMessages0__add_Z13DE1548(this, message0)
    }
}

export function AlertMessages0$reflection() {
    return class_type("AlertMessages.AlertMessages0", void 0, AlertMessages0);
}

export function AlertMessages0_$ctor() {
    return new AlertMessages0();
}

function AlertMessages0__clearExpiredMessages(this$) {
    const expiredTime = ((~(~Date.now())) - this$.keepDuration) | 0;
    this$.currentMessages = this$.currentMessages.filter((m) => (m.timestamp > expiredTime));
}

function AlertMessages0__add_Z13DE1548(this$, message0) {
    let message = new AlertMessage(message0.text, Date.now(), message0.forceAcknowlege, message0.level);
    if (message.level === 3) {
        message = (new AlertMessage(message.text, message.timestamp, true, message.level));
    }
    this$.currentMessages = append([message], this$.currentMessages);
    return setTimeout(() => {
        AlertMessages0__clearExpiredMessages(this$);
    }, this$.keepDuration + 200) | 0;
}

export function AlertMessages() {
    return AlertMessages0_$ctor();
}

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 