import {pastePlainText, setCaretToEnd} from "./content-editable-utils.js";

export class DomTextEditor {
  constructor() {
    this.editableDomElement = null;
    this.editing = false;
    this.opts = null;
    this.lastSyncedContent = null;
  }

  setEditableElement(element, opts={}) {
    this.editableDomElement = element;
    this.opts = opts
    this.configureEditing();
  }

  setEditing(edit) {
    this.editing = edit;
    this.configureEditing();
  }

  configureEditing() {
    if (!this.editableDomElement) {
      return;
    }
    if (this.editing) {
      if (this.editableDomElement.contentEditable === 'false') {
        this.editableDomElement.contentEditable = true;
        this.editableDomElement.onpaste = pastePlainText;
        this.editableDomElement.classList.add("in-edit");
        this.editableDomElement.classList.remove("not-in-edit");
        this.editableDomElement.oninput = (event) => this.handleContentEditableInput(event);
        this.editableDomElement.onkeypress = (event) => this.handleKeypress(event);
        setCaretToEnd(this.editableDomElement);
      }
    } else {
      if (this.editableDomElement.contentEditable === 'true') {
        this.editableDomElement.contentEditable = false;
        this.editableDomElement.classList.remove("in-edit");
        this.editableDomElement.classList.add("not-in-edit");
      }
    }
  }

  getCurrentContent() {
    if (!this.lastSyncedContent) {
      this.syncContentFromEditable();
    }
    return this.lastSyncedContent;
  }

  handleEnter(event) {
    if (this.opts.multiline) {
      if (event) {
        event.preventDefault();
      }
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const br = document.createElement('br');

      range.deleteContents();
      range.insertNode(br);
      range.setStartAfter(br);
      range.setEndAfter(br);
      range.collapse(false);

      selection.removeAllRanges();
      selection.addRange(range);
      this.syncContentFromEditable(event);
      return true;
    } else {
      return false;
    }
  }

  handleKeypress(event) {
    if (event.code === 'Enter' ) {
      this.handleEnter(event);
    }
  }

  syncContentFromEditable() {
    this.lastSyncedContent = this.editableDomElement.innerText;
  }

  handleContentEditableInput(event) {
    this.syncContentFromEditable();
  }

  fromContent(content, syncToEditable=false) {
    this.lastSyncedContent = content;
    if (syncToEditable) {
      this.editableDomElement.innerText = this.lastSyncedContent;
    }
  }
}
