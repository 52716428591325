import { Record } from "../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type, array_type, record_type, int32_type } from "../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { empty, singleton, collect, rangeNumber, map, delay } from "../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { searchSorted } from "./search-sorted.fs.js";
import lodash from "lodash";
import { System_Array__$005B$005D$1_append_1505 } from "../basic-types.fs.js";
import { iterateIndexed } from "../../.fable/fable-library.3.1.0-beta-001/Array.js";

export class Interval extends Record {
    constructor(starts, ends) {
        super();
        this.starts = (starts | 0);
        this.ends = (ends | 0);
    }
}

export function Interval$reflection() {
    return record_type("Sorted.Interval", [], Interval, () => [["starts", int32_type], ["ends", int32_type]]);
}

export class SortedOptsRecord extends Record {
    constructor(startPoints, endPoints, domainStart, domainEnd) {
        super();
        this.startPoints = startPoints;
        this.endPoints = endPoints;
        this.domainStart = (domainStart | 0);
        this.domainEnd = (domainEnd | 0);
    }
}

export function SortedOptsRecord$reflection() {
    return record_type("Sorted.SortedOptsRecord", [], SortedOptsRecord, () => [["startPoints", array_type(int32_type)], ["endPoints", array_type(int32_type)], ["domainStart", int32_type], ["domainEnd", int32_type]]);
}

export const SortedOpts = new SortedOptsRecord(new Int32Array([]), null, 0, 0);

export const NO_INDEX = -1;

export function size(interval) {
    return (interval.ends - interval.starts) + 1;
}

export function midPoint(interval) {
    return (interval.starts + interval.ends) >> 1;
}

export function isPoint(interval) {
    return interval.ends === NO_INDEX;
}

export function intervalsToStartEndPoints(intervals) {
    const startPoints = Int32Array.from(delay(() => map((interval) => interval.starts, intervals)));
    const endPoints = Int32Array.from(delay(() => map((interval_1) => interval_1.ends, intervals)));
    return {
        endPoints: endPoints,
        startPoints: startPoints,
    };
}

export class Sorted0 {
    constructor(opts) {
        this.startPoints = opts.startPoints;
        this.endPoints = opts.endPoints;
        this.domainStart = opts.domainStart;
        this.domainEnd = opts.domainEnd;
        this.midPoints0 = (null);
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    asIntervalSeq() {
        return Sorted0__asIntervalSeq(this)
    }
    checkValidIndex(idx) {
        return Sorted0__checkValidIndex_Z524259A4(this, idx)
    }
    containing(value) {
        return Sorted0__containing_Z524259A4(this, value)
    }
    doesContain(idx, value) {
        return Sorted0__doesContain_Z37302880(this, idx, value)
    }
    doesStartBeforeOrAt(idx, value) {
        return Sorted0__doesStartBeforeOrAt_Z37302880(this, idx, value)
    }
    endsAt(value) {
        return Sorted0__endsAt_Z524259A4(this, value)
    }
    firstAfter(value) {
        return Sorted0__firstAfter_Z524259A4(this, value)
    }
    firstEndsAfter(value) {
        return Sorted0__firstEndsAfter_Z524259A4(this, value)
    }
    firstStartsAfter(value) {
        return Sorted0__firstStartsAfter_Z524259A4(this, value)
    }
    firstStartsAfterOrAt(value) {
        return Sorted0__firstStartsAfterOrAt_Z524259A4(this, value)
    }
    fromEndPoints(sorted) {
        return Sorted0__fromEndPoints_1505(this, sorted)
    }
    fromExpandedIntervals(expandSize, direction) {
        return Sorted0__fromExpandedIntervals_657264D9(this, expandSize, direction)
    }
    fromGapIntervals(minSize) {
        return Sorted0__fromGapIntervals_Z524259A4(this, minSize)
    }
    fromMidPoints(sorted) {
        return Sorted0__fromMidPoints_1505(this, sorted)
    }
    fromStartPoints(sorted) {
        return Sorted0__fromStartPoints_1505(this, sorted)
    }
    hasContained(startValue, endValue) {
        return Sorted0__hasContained_Z37302880(this, startValue, endValue)
    }
    hasIntersecting(startValue, endValue) {
        return Sorted0__hasIntersecting_Z37302880(this, startValue, endValue)
    }
    hasInterval(startValue, endValue) {
        return Sorted0__hasInterval_Z37302880(this, startValue, endValue)
    }
    intervalAt(idx) {
        return Sorted0__intervalAt_Z524259A4(this, idx)
    }
    lastBeforeOrAt(value) {
        return Sorted0__lastBeforeOrAt_Z524259A4(this, value)
    }
    lastEndsBeforeOrAt(value) {
        return Sorted0__lastEndsBeforeOrAt_Z524259A4(this, value)
    }
    lastStartsBeforeOrAt(value) {
        return Sorted0__lastStartsBeforeOrAt_Z524259A4(this, value)
    }
    mapRangesContained(intervals) {
        return Sorted0__mapRangesContained_69165B40(this, intervals)
    }
    openTransitions() {
        return Sorted0__openTransitions(this)
    }
    pointAt(idx) {
        return Sorted0__pointAt_Z524259A4(this, idx)
    }
    rangeContained(startValue, endValue) {
        return Sorted0__rangeContained_Z37302880(this, startValue, endValue)
    }
    rangeIntersecting(startValue, endValue) {
        return Sorted0__rangeIntersecting_Z37302880(this, startValue, endValue)
    }
    retrieveRange(startIdx, endIdx) {
        return Sorted0__retrieveRange_Z37302880(this, startIdx, endIdx)
    }
    startsAt(value) {
        return Sorted0__startsAt_Z524259A4(this, value)
    }
    translate(startIndexes, endIndexes) {
        return Sorted0__translate_2A0A0(this, startIndexes, endIndexes)
    }
    translateEndPointsToValues(endIndexes) {
        return Sorted0__translateEndPointsToValues_13C0A23A(this, endIndexes)
    }
    translateStartPointsToValues(startIndexes) {
        return Sorted0__translateStartPointsToValues_13C0A23A(this, startIndexes)
    }
    valueBounds(startIdx, endIdx) {
        return Sorted0__valueBounds_Z37302880(this, startIdx, endIdx)
    }
    get length() {
        return Sorted0__length(this)
    }
    get midPoints() {
        return Sorted0__midPoints(this)
    }
}

export function Sorted0$reflection() {
    return class_type("Sorted.Sorted0", void 0, Sorted0);
}

export function Sorted0_$ctor_438D3AFE(opts) {
    return new Sorted0(opts);
}

function Sorted0__checkValidIndex_Z524259A4(this$, idx) {
    if (idx >= 0) {
        return idx < this$.startPoints.length;
    }
    else {
        return false;
    }
}

function Sorted0__getValidIdx_Z524259A4(this$, idx) {
    if (Sorted0__checkValidIndex_Z524259A4(this$, idx)) {
        return idx | 0;
    }
    else {
        return NO_INDEX | 0;
    }
}

function Sorted0__getValidInterval_Z37302880(this$, startIdx, endIdx) {
    if ((startIdx < 0) ? true : (endIdx < 0)) {
        return null;
    }
    else if (startIdx > endIdx) {
        return null;
    }
    else {
        return new Interval(startIdx, endIdx);
    }
}

function Sorted0__length(this$) {
    return this$.startPoints.length;
}

function Sorted0__intervalAt_Z524259A4(this$, idx) {
    if (this$.endPoints) {
        return new Interval(this$.startPoints[idx], this$.endPoints[idx]);
    }
    else {
        return new Interval(this$.startPoints[idx], NO_INDEX);
    }
}

function Sorted0__pointAt_Z524259A4(this$, idx) {
    return Sorted0__intervalAt_Z524259A4(this$, idx);
}

function Sorted0__asIntervalSeq(this$) {
    return delay(() => map((i) => Sorted0__intervalAt_Z524259A4(this$, i), rangeNumber(0, 1, this$.startPoints.length - 1)));
}

function Sorted0__midPoints(this$) {
    if (this$.midPoints0) {
        return this$.midPoints0;
    }
    else {
        const intervals = Sorted0__asIntervalSeq(this$);
        this$.midPoints0 = Int32Array.from(delay(() => map(midPoint, intervals)));
        return this$.midPoints0;
    }
}

function Sorted0__fromIntervals0_Z6E021437(this$, intervals) {
    const points = intervalsToStartEndPoints(intervals);
    return Sorted0_$ctor_438D3AFE(new SortedOptsRecord(points.startPoints, points.endPoints, 0, 0));
}

function Sorted0__fromStartPoints_1505(this$, sorted) {
    return Sorted0_$ctor_438D3AFE(new SortedOptsRecord(this$.startPoints, SortedOpts.endPoints, this$.domainStart, this$.domainEnd));
}

function Sorted0__fromEndPoints_1505(this$, sorted) {
    return Sorted0_$ctor_438D3AFE(new SortedOptsRecord(this$.endPoints, SortedOpts.endPoints, this$.domainStart, this$.domainEnd));
}

function Sorted0__fromMidPoints_1505(this$, sorted) {
    return Sorted0_$ctor_438D3AFE(new SortedOptsRecord(Sorted0__midPoints(this$), SortedOpts.endPoints, this$.domainStart, this$.domainEnd));
}

function Sorted0__lastStartsBeforeOrAt_Z524259A4(this$, value) {
    const idx = searchSorted(this$.startPoints, value, true) | 0;
    return Sorted0__getValidIdx_Z524259A4(this$, idx - 1) | 0;
}

function Sorted0__lastBeforeOrAt_Z524259A4(this$, value) {
    return Sorted0__lastStartsBeforeOrAt_Z524259A4(this$, value);
}

function Sorted0__doesStartBeforeOrAt_Z37302880(this$, idx, value) {
    return this$.startPoints[idx] <= value;
}

function Sorted0__firstStartsAfterOrAt_Z524259A4(this$, value) {
    const idx = searchSorted(this$.startPoints, value, false) | 0;
    return Sorted0__getValidIdx_Z524259A4(this$, idx) | 0;
}

function Sorted0__firstStartsAfter_Z524259A4(this$, value) {
    const idx = searchSorted(this$.startPoints, value, true) | 0;
    return Sorted0__getValidIdx_Z524259A4(this$, idx) | 0;
}

function Sorted0__firstAfter_Z524259A4(this$, value) {
    return Sorted0__firstStartsAfter_Z524259A4(this$, value);
}

function Sorted0__lastEndsBeforeOrAt_Z524259A4(this$, value) {
    const idx = searchSorted(this$.endPoints, value, true) | 0;
    return Sorted0__getValidIdx_Z524259A4(this$, idx - 1) | 0;
}

function Sorted0__firstEndsAfter_Z524259A4(this$, value) {
    const idx = searchSorted(this$.endPoints, value, true) | 0;
    return Sorted0__getValidIdx_Z524259A4(this$, idx) | 0;
}

function Sorted0__firstEndsAfterOrAt_Z524259A4(this$, value) {
    const idx = searchSorted(this$.endPoints, value, false) | 0;
    return Sorted0__getValidIdx_Z524259A4(this$, idx) | 0;
}

function Sorted0__startsAt_Z524259A4(this$, value) {
    const idx = Sorted0__lastStartsBeforeOrAt_Z524259A4(this$, value) | 0;
    if ((idx !== NO_INDEX) ? (this$.startPoints[idx] === value) : false) {
        return idx | 0;
    }
    else {
        return NO_INDEX | 0;
    }
}

function Sorted0__endsAt_Z524259A4(this$, value) {
    const idx = Sorted0__lastEndsBeforeOrAt_Z524259A4(this$, value) | 0;
    if ((idx !== NO_INDEX) ? (this$.endPoints[idx] === value) : false) {
        return idx | 0;
    }
    else {
        return NO_INDEX | 0;
    }
}

function Sorted0__containing_Z524259A4(this$, value) {
    const idx = Sorted0__lastStartsBeforeOrAt_Z524259A4(this$, value) | 0;
    if (!this$.endPoints) {
        return idx | 0;
    }
    else if ((idx >= 0) ? (value <= this$.endPoints[idx]) : false) {
        return idx | 0;
    }
    else {
        return NO_INDEX | 0;
    }
}

function Sorted0__doesContain_Z37302880(this$, idx, value) {
    if (idx < 0) {
        return false;
    }
    else if (this$.startPoints[idx] <= value) {
        return this$.endPoints[idx] >= value;
    }
    else {
        return false;
    }
}

function Sorted0__rangeContained_Z37302880(this$, startValue, endValue) {
    const startIdx = Sorted0__firstStartsAfterOrAt_Z524259A4(this$, startValue) | 0;
    const endIdx = Sorted0__lastEndsBeforeOrAt_Z524259A4(this$, endValue) | 0;
    return Sorted0__getValidInterval_Z37302880(this$, startIdx, endIdx);
}

function Sorted0__hasContained_Z37302880(this$, startValue, endValue) {
    return Sorted0__rangeContained_Z37302880(this$, startValue, endValue);
}

function Sorted0__hasInterval_Z37302880(this$, startValue, endValue) {
    const idx = Sorted0__startsAt_Z524259A4(this$, startValue) | 0;
    if (idx !== NO_INDEX) {
        return this$.endPoints[idx] === endValue;
    }
    else {
        return false;
    }
}

function Sorted0__rangeIntersecting_Z37302880(this$, startValue, endValue) {
    const startIdx = Sorted0__firstEndsAfterOrAt_Z524259A4(this$, startValue) | 0;
    const endIdx = Sorted0__lastStartsBeforeOrAt_Z524259A4(this$, endValue) | 0;
    return Sorted0__getValidInterval_Z37302880(this$, startIdx, endIdx);
}

function Sorted0__hasIntersecting_Z37302880(this$, startValue, endValue) {
    const x = Sorted0__rangeIntersecting_Z37302880(this$, startValue, endValue);
    return !(lodash.isNull(x));
}

function Sorted0__retrieveRange_Z37302880(this$, startIdx, endIdx) {
    return Array.from(delay(() => map((i) => Sorted0__intervalAt_Z524259A4(this$, i), rangeNumber(startIdx, 1, endIdx))));
}

function Sorted0__valueBounds_Z37302880(this$, startIdx, endIdx) {
    return new Interval(this$.startPoints[startIdx], this$.endPoints[endIdx]);
}

function Sorted0__translateStartPointsToValues_13C0A23A(this$, startIndexes) {
    return Int32Array.from(delay(() => map((idx) => this$.startPoints[idx], startIndexes)));
}

function Sorted0__translateEndPointsToValues_13C0A23A(this$, endIndexes) {
    return Int32Array.from(delay(() => map((idx) => this$.endPoints[idx], endIndexes)));
}

function Sorted0__translate_2A0A0(this$, startIndexes, endIndexes) {
    return null;
}

function Sorted0__mapRangesContained_69165B40(this$, intervals) {
    return Array.from(delay(() => collect((i) => {
        const result = Sorted0__rangeContained_Z37302880(this$, i.starts, i.ends);
        if (result) {
            return singleton(new Interval(result.starts, result.ends));
        }
        else {
            return empty();
        }
    }, intervals)));
}

function Sorted0__fromGapIntervals_Z524259A4(this$, minSize) {
    const gapStarts = this$.endPoints;
    const gapEnds = this$.startPoints;
    const gapIntervals = [];
    const firstInterval = new Interval(this$.domainStart, this$.startPoints[0]);
    if (size(firstInterval) > minSize) {
        System_Array__$005B$005D$1_append_1505(gapIntervals, firstInterval);
    }
    iterateIndexed((i, starts) => {
        const interval = new Interval(starts, gapEnds[i + 1]);
        if (size(interval) > minSize) {
            System_Array__$005B$005D$1_append_1505(gapIntervals, interval);
        }
    }, gapStarts);
    return Sorted0__fromIntervals0_Z6E021437(this$, gapIntervals);
}

function Sorted0__fromExpandedIntervals_657264D9(this$, expandSize, direction) {
    const intervals = Sorted0__asIntervalSeq(this$);
    const intervals_1 = Array.from(delay(() => collect((interval) => {
        if (size(interval) >= expandSize) {
            return singleton(interval);
        }
        else {
            const ends = interval.ends | 0;
            return singleton(new Interval(ends - expandSize, ends));
        }
    }, intervals)));
    return Sorted0__fromIntervals0_Z6E021437(this$, intervals_1);
}

function Sorted0__openTransitions(this$) {
    return this$.startPoints;
}

export function Sorted(opts) {
    return Sorted0_$ctor_438D3AFE(opts);
}

export function fromIntervals(intervals) {
    const points = intervalsToStartEndPoints(intervals);
    return Sorted(new SortedOptsRecord(points.startPoints, points.endPoints, 0, 0));
}

export const EmptySorted = Sorted(new SortedOptsRecord(new Int32Array([]), new Int32Array([]), 0, 0));

// JS BOILERPLATE GENERATED
 