import sweetalert2 from "sweetalert2";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../promise/promise.fs.js";
import { promise } from "../promise/promise-impl.fs.js";

export const Swal = sweetalert2;

export function swalFire(options) {
    return Swal.fire(options);
}

export function swalPromptYN(options) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (swalFire(options).then(((_arg1) => {
        const confirm = _arg1;
        const yes = confirm.value;
        return Promise.resolve(yes);
    })))));
}

