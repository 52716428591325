import { FSharpRef, Record } from "../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type, record_type, string_type } from "../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { PASSAGE_HINT } from "../../elements/element-types.fs.js";
import { System_Array__$005B$005D$1_append_1505 } from "../../basic-types.fs.js";

export class ValidatorWarning extends Record {
    constructor(elementId, message) {
        super();
        this.elementId = elementId;
        this.message = message;
    }
}

export function ValidatorWarning$reflection() {
    return record_type("Validations.ValidatorWarning", [], ValidatorWarning, () => [["elementId", string_type], ["message", string_type]]);
}

export class Validations0 {
    constructor(contentRoots0) {
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = (s0.contents);
        this.contentRoots = contentRoots0;
        this.filterSuppressed = true;
        this["init@24-2"] = 1;
        makeObservable(this, {
            allWarnings: computed({keepAlive: true}),
            activeWarnings: computed({keepAlive: true}),
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    setFilterSuppressed(value) {
        return Validations0__setFilterSuppressed_Z1FBCCD16(this, value)
    }
    get activeWarnings() {
        return Validations0__activeWarnings(this)
    }
    get allWarnings() {
        return Validations0__allWarnings(this)
    }
}

export function Validations0$reflection() {
    return class_type("Validations.Validations0", void 0, Validations0);
}

export function Validations0_$ctor_1F2672B7(contentRoots0) {
    return new Validations0(contentRoots0);
}

function Validations0__allWarnings(this$) {
    const structuralElements = this$.contentRoots.structural0;
    const result = [];
    for (let idx = 0; idx <= (structuralElements.length - 1); idx++) {
        const element = structuralElements[idx];
        const content = element.content;
        if (element.kind === PASSAGE_HINT) {
            if (content ? (content.length > 80) : false) {
                const warning = new ValidatorWarning(element.id, "Passage hint text is over char limit");
                System_Array__$005B$005D$1_append_1505(result, warning);
            }
        }
    }
    return result;
}

function Validations0__activeWarnings(this$) {
    if (!this$.filterSuppressed) {
        return this$.self.allWarnings;
    }
    else {
        const suppressions = this$.contentRoots.warningSuppressions;
        return this$.self.allWarnings.filter((warning) => (!suppressions.has(warning.elementId)));
    }
}

function Validations0__setFilterSuppressed_Z1FBCCD16(this$, value) {
    this$.filterSuppressed = value;
}

export function Validations(contentRoots) {
    return Validations0_$ctor_1F2672B7(contentRoots);
}

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 