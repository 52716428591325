import lodash from "lodash";
import { elementIdToDomId } from "../../basic-types.fs.js";

export function getVisibilityDomId(domId, containerDomId) {
    const node = document.getElementById(domId);
    if (lodash.isNull(node)) {
        return 0;
    }
    else {
        let top = 0;
        let bottom = window.innerHeight;
        if (!(lodash.isNull(containerDomId))) {
            const container = document.getElementById(containerDomId);
            const containerRect = container.getBoundingClientRect();
            top = containerRect.top;
            bottom = containerRect.bottom;
        }
        const rect = node.getBoundingClientRect();
        if (rect.top < top) {
            return 3;
        }
        else if (rect.bottom > bottom) {
            return 1;
        }
        else {
            return 2;
        }
    }
}

export function scrollIfNotVisibleDomId(domId, placement, containerDomId) {
    const currentLocation = getVisibilityDomId(domId, containerDomId) | 0;
    if ((currentLocation !== 2) ? (currentLocation !== 0) : false) {
        const node = document.getElementById(domId);
        const options = {};
        options.block = placement;
        node.scrollIntoView(options);
    }
}

export function getVisibility(elementId, containerDomId) {
    const domId = elementIdToDomId(null, elementId);
    return getVisibilityDomId(domId, containerDomId) | 0;
}

export function scrollIfNotVisible(elementId, placement, containerDomId) {
    if (elementId) {
        const domId = elementIdToDomId(null, elementId);
        scrollIfNotVisibleDomId(domId, placement, containerDomId);
    }
}

