import React from 'react';
import { Link } from 'rebass/styled-components';

const prevent = action => e => {
  e.preventDefault();
  action();
};

export const ActionLink = ({ onPress = () => {}, title = '', children }) => {
  return (
    <Link href="#" onClick={prevent(onPress)} fontSize={1} mr={2}>
      {title}
      {children}
    </Link>
  );
};
