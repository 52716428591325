import { saveScriptDataToFirestore, parseJWScript } from "./editorial/jw-script/jwscript-parser.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./promise/promise.fs.js";
import { promise } from "./promise/promise-impl.fs.js";
import { loadScriptContent, basicScriptElementsFromContentRoots, loadScriptContentRoots, loadChaatRoots } from "./editorial/db/loader-funcs.fs.js";
import { runTimestampingWithContentRoots } from "./editorial/chaat/timestamper/chaat-timestamper.fs.js";
import { ScriptRenderer } from "./editorial/jw-script/jw-script-renderer.fs.js";
import { IndexRange, System_Array__$005B$005D$1_append_1505 } from "./basic-types.fs.js";
import { StyleLayersRenderer } from "./editorial/ui/style-painting/style-layers.fs.js";
import { renderWordRange } from "./editorial/ui/word-range-render.fs.js";
import { Precedence } from "./elements/precedence.fs.js";
import { WORD_GROUP, SENTENCE, SPEAKER_LABEL, PASSAGE_HINT, CHAPTER_TITLE } from "./elements/element-types.fs.js";
import { ElementTreeModel } from "./elements/element-tree-model.fs.js";

export function parseAndSaveToFirestore(episodeKey, scriptText) {
    const parsed = parseJWScript(scriptText, void 0);
    return saveScriptDataToFirestore(episodeKey, parsed);
}

export function runChaatTimestamping(episodeKey) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (loadChaatRoots(episodeKey).then(((_arg1) => {
        const contentRoots = _arg1;
        return runTimestampingWithContentRoots(contentRoots);
    })))));
}

export function exportJWScript(episodeKey) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (loadScriptContentRoots(episodeKey).then(((_arg1) => {
        const roots = _arg1;
        const content = basicScriptElementsFromContentRoots(roots);
        const translations = roots.translations0;
        const renderer = ScriptRenderer(content, [], translations);
        return Promise.resolve(renderer.renderScript());
    })))));
}

export function getElementsAsJSONAble(episodeKey, kind) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (loadScriptContent(episodeKey).then(((_arg1) => {
        const content = _arg1;
        const selectedElements = kind ? content.getKindSubList(kind) : content;
        const result = [];
        return PromiseBuilder__For_1565554B(promise, selectedElements.elements, (_arg2) => {
            const element = _arg2;
            const data = {};
            data.id = element.id;
            data.kind = element.kind;
            data.wordAddress = element.wordAddress;
            data.endWordAddress = element.endWordAddress;
            data.content = element.content;
            System_Array__$005B$005D$1_append_1505(result, data);
            return Promise.resolve();
        }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(result))));
    })))));
}

export function mapContentOnSpanElements(elements, words, wordGroups) {
    const styleRenderer = StyleLayersRenderer();
    const wordList = {
        elements: words,
    };
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const element = elements[idx];
        const startIndex = element.wordAddress | 0;
        const endIndex = element.endWordAddress | 0;
        element.content = renderWordRange(null, styleRenderer, wordList, new IndexRange(startIndex, endIndex), wordGroups);
    }
}

export const playerTreePrecedence = Precedence([CHAPTER_TITLE, PASSAGE_HINT, SPEAKER_LABEL, SENTENCE, WORD_GROUP]);

export function testRenderToTreeForPlayer(elements) {
    const treeModel = ElementTreeModel(elements, playerTreePrecedence);
    const tree = treeModel.getTreeOfNodes();
    return tree;
}

