import { MDBModal, MDBModalBody } from "mdbreact";
import React from "react";
import Keyboardist from 'react-keyboardist';
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import { scriptEditorModel } from "./app-root.fs.js";
import { SENTENCE, WORD_GROUP, VOCAB, TRICKY, SIC } from "./masala-lib/elements/element-types.fs.js";
import { STRUCTURAL, UNFILLED, OPEN, WARNING, ASSIGNED, AREPARTICIPANT} from "./masala-lib/editorial/filters/filters-model.fs.js";

class FiltersDialogState {
  constructor() {
    this.open = false;
    this.oneChoice = false;
    makeObservable(this, {
      open: observable,
    })
  }
}

const dialogState = new FiltersDialogState();

export function openFiltersDialog(oneChoice) {
  dialogState.open = true;
  dialogState.oneChoice = oneChoice;
  scriptEditorModel.setKbDeactivated(true);
}

export const FiltersDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
    scriptEditorModel.setKbDeactivated(dialogState.open);
  };

  if (!dialogState.open) {
    return null;
  }

  const getFilterOnState = (key) => {
    return scriptEditorModel.filters.activeFlags.has(key);
  };

  const setFilterOnState = (key, value) => {
    scriptEditorModel.filters.setFlag(key, value);
  };

  const setAllFiltersOffExcept = (exceptKey) => {
    for (const key of scriptEditorModel.filters.activeFlags) {
      if (key !== exceptKey) {
        setFilterOnState(key, false);
      }
    }
  }

  const toggleFilterOnState = (key) => {
    if (dialogState.oneChoice) {
      setAllFiltersOffExcept(key);
    }
    scriptEditorModel.filters.setFlag(key, !getFilterOnState(key));
    if (dialogState.oneChoice) {
      toggleModal();
      setTimeout(() => scriptEditorModel.nextSearchResult(), 50);
    }
  };

  const clearChoices = () => {
    setAllFiltersOffExcept(null);
    if (dialogState.oneChoice) {
      toggleModal();
    }
  }

  return (
    <MDBModal size="lg" isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalBody>
      <Keyboardist
          bindings={{
            KeyG: () => toggleFilterOnState(WORD_GROUP),
            KeyV: () => toggleFilterOnState(VOCAB),
            KeyT: () => toggleFilterOnState(TRICKY),
            KeyS: () => toggleFilterOnState(STRUCTURAL),
            KeyU: () => toggleFilterOnState(UNFILLED),
            KeyW: () => toggleFilterOnState(WARNING),
            KeyA: () => toggleFilterOnState(ASSIGNED),
            KeyP: () => toggleFilterOnState(AREPARTICIPANT),
            KeyO: () => toggleFilterOnState(OPEN),
            KeyX: () => clearChoices(),
          }}
        />
        <div>
          <div className="filter-column">
            <div>
              {/* TODO factor the duplicate code in these labels to component which takes key string and ui text string? */}
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(WORD_GROUP)}
                  onChange={(e) => setFilterOnState(WORD_GROUP, e.target.checked)}
                />
                <span> word <b><u>g</u></b>roup </span>
              </label>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(SENTENCE)}
                  onChange={(e) => setFilterOnState(SENTENCE, e.target.checked)}
                />
                <span> sentence </span>
              </label>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(STRUCTURAL)}
                  onChange={(e) => setFilterOnState(STRUCTURAL, e.target.checked)}
                />
                <span> <b><u>s</u></b>tructural </span>
              </label>
            </div>
          </div>
          <div className="filter-column">
            <div>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(VOCAB)}
                  onChange={(e) => setFilterOnState(VOCAB, e.target.checked)}
                />
                <span> <b><u>v</u></b>ocab </span>
              </label>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(TRICKY)}
                  onChange={(e) => setFilterOnState(TRICKY, e.target.checked)}
                />
                <span> <b><u>t</u></b>ricky </span>
              </label>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(SIC)}
                  onChange={(e) => setFilterOnState(SIC, e.target.checked)}
                />
                <span> sic </span>
              </label>
            </div>
          </div>
          <div className="filter-column">
            <div>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(OPEN)}
                  onChange={(e) => setFilterOnState(OPEN, e.target.checked)}
                />
                <span> <b><u>o</u></b>pen </span>
              </label>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(AREPARTICIPANT)}
                  onChange={(e) => setFilterOnState(AREPARTICIPANT, e.target.checked)}
                />
                <span> <b><u>p</u></b>articipant </span>
              </label>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(ASSIGNED)}
                  onChange={(e) => setFilterOnState(ASSIGNED, e.target.checked)}
                />
                <span> <b><u>a</u></b>ssigned </span>
              </label>
            </div>
          </div>
          <div className="filter-column">
            <div>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(WARNING)}
                  onChange={(e) => setFilterOnState(WARNING, e.target.checked)}
                />
                <span> <b><u>w</u></b>arning </span>
              </label>
              <label >
                <input
                  type="checkbox"
                  checked={getFilterOnState(UNFILLED)}
                  onChange={(e) => setFilterOnState(UNFILLED, e.target.checked)}
                />
                <span> <b><u>u</u></b>nfilled </span>
              </label>
            </div>
          </div>
          <div>
            X = clear all choices
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
});
