import { printf, toConsole, split, replace } from "../../../.fable/fable-library.3.1.0-beta-001/String.js";
import { indexed as indexed_1, sortBy, sortInPlaceBy, contains, copy } from "../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { bool_type, array_type, record_type, string_type, int32_type, class_type } from "../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { empty, singleton, collect, getEnumerator, map, delay } from "../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { comparePrimitives, stringHash } from "../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { toString, Record, FSharpRef } from "../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { System_Array__$005B$005D$1_extend_5975E3, System_Array__$005B$005D$1_append_1505 } from "../../basic-types.fs.js";
import { matches as matches_1, match, replace as replace_1 } from "../../../.fable/fable-library.3.1.0-beta-001/RegExp.js";
import { CHAPTER_COMPLETE, CHAPTER_SUMMARY, CULTURAL_NOTE, SPEAKER_LABEL, PARAGRAPH_BREAK, PASSAGE_HINT, CHAPTER_TITLE, WORD, VOCAB, SIC, TRICKY, WORD_GROUP, METADATA_BLOCK } from "../../elements/element-types.fs.js";
import { value } from "../../../.fable/fable-library.3.1.0-beta-001/Option.js";

export const NO_INDEX = -1;

export const DIRECTIVE_LINE = "DIRECTIVE_LINE";

export const EOL = "EOL";

export const SENTENCE_END = "SENTENCE_END";

export const SEGMENT_LABEL = "SEGMENT_LABEL";

export const TRANSLATION = "TRANSLATION";

export const EMPTY_LINE = "EMPTY_LINE";

export const COMMENT = "COMMENT";

export const SOURCE_LANGUAGE_COMMENT = "SOURCE_LANGUAGE_COMMENT";

export function includePunctuation(str, address0) {
    const len = str.length | 0;
    let address = address0 | 0;
    const punctuation = ",;\"\u0027.?!-¡¿;\\:—+_–​";
    while ((address < len) ? (punctuation.indexOf(str[address]) >= 0) : false) {
        address = (address + 1);
    }
    return address | 0;
}

export class MatchElement0 {
    constructor(m, textRegion, tags0) {
        this.textRegion = textRegion;
        const props = {};
        this.wholeText = replace(m[0], "​", " ");
        const startOffset = (m.index + this.textRegion.startOffset) | 0;
        this.endOffset0 = ((startOffset + m[0].length) - 1);
        this.endOffset = (includePunctuation(this.textRegion.rootText, this.endOffset0 + 1) - 1);
        this.rawWordsText = (m.groups ? m.groups.words : (void 0));
        const words = MatchElement0__getWords(this);
        const wordCount = words.length | 0;
        this.wordAddress = 0;
        this.scriptIndex = 0;
        this.tags = copy(tags0);
        this.props = props;
        this.startOffset = startOffset;
        this.wordCount = wordCount;
        this.words = words;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    hasTag(tag) {
        return MatchElement0__hasTag_Z721C83C5(this, tag)
    }
}

export function MatchElement0$reflection() {
    return class_type("JWScriptParserTools.MatchElement0", void 0, MatchElement0);
}

export function MatchElement0_$ctor_243E4C7F(m, textRegion, tags0) {
    return new MatchElement0(m, textRegion, tags0);
}

function MatchElement0__getWordsTextWithTrailing(this$) {
    if (this$.endOffset0 !== this$.endOffset) {
        return this$.rawWordsText.trim() + this$.textRegion.rootText.slice(this$.endOffset0 + 1, this$.endOffset + 1).trim();
    }
    else {
        return this$.rawWordsText.trim();
    }
}

function MatchElement0__getWords(this$) {
    if (!this$.rawWordsText) {
        return [];
    }
    else {
        const words = split(MatchElement0__getWordsTextWithTrailing(this$), []);
        return Array.from(delay(() => map((word) => replace(word, "​", " "), words)));
    }
}

function MatchElement0__annotations(this$) {
    return [];
}

function MatchElement0__hasTag_Z721C83C5(this$, tag) {
    return contains(tag, this$.tags, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    });
}

export function MatchElement(m, textRegion, tags) {
    return MatchElement0_$ctor_243E4C7F(m, textRegion, tags);
}

export class TextRegion0 {
    constructor(rootText0, startOffset0, endOffset0) {
        this.self = (new FSharpRef(null));
        const self = this.self;
        this.self.contents = this;
        this.startOffset = startOffset0;
        this.endOffset = endOffset0;
        this.rootText = rootText0;
        this.foundElements = [];
        this["init@111-11"] = 1;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    foundElement(element) {
        return TextRegion0__foundElement_1505(this, element)
    }
    splitWithFoundElements() {
        return TextRegion0__splitWithFoundElements(this)
    }
    get text() {
        return TextRegion0__text(this)
    }
}

export function TextRegion0$reflection() {
    return class_type("JWScriptParserTools.TextRegion0", void 0, TextRegion0);
}

export function TextRegion0_$ctor_487EF8FB(rootText0, startOffset0, endOffset0) {
    return new TextRegion0(rootText0, startOffset0, endOffset0);
}

function TextRegion0__splitWithFoundElements(this$) {
    if (!this$.foundElements) {
        return [this$.self.contents];
    }
    else {
        const splitRegions = [];
        let start = this$.startOffset | 0;
        let _end = 0;
        sortInPlaceBy((t) => t.startOffset, this$.foundElements, {
            Compare: comparePrimitives,
        });
        const arr = this$.foundElements;
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const element = arr[idx];
            _end = (element.startOffset - 1);
            if (_end >= start) {
                System_Array__$005B$005D$1_append_1505(splitRegions, TextRegion0_$ctor_487EF8FB(this$.rootText, start, _end));
            }
            start = (element.endOffset + 1);
        }
        if (this$.endOffset >= start) {
            System_Array__$005B$005D$1_append_1505(splitRegions, TextRegion0_$ctor_487EF8FB(this$.rootText, start, this$.endOffset));
        }
        return splitRegions;
    }
}

function TextRegion0__foundElement_1505(this$, element) {
    System_Array__$005B$005D$1_append_1505(this$.foundElements, element);
}

function TextRegion0__text(this$) {
    return this$.rootText.slice(this$.startOffset, this$.endOffset + 1);
}

export function TextRegion(rootText, startOffset, endOffset) {
    return TextRegion0_$ctor_487EF8FB(rootText, startOffset, endOffset);
}

export function normalizeScriptText(text0) {
    let text = text0;
    text = replace(text, "﻿", "");
    const endToken = "STOP-HERE";
    const endTokenIndex = text.indexOf(endToken) | 0;
    if (endTokenIndex !== NO_INDEX) {
        text = text.slice(0, ((endTokenIndex + endToken.length) + 1) + 1);
    }
    const sub = (pattern, replacement, str) => replace_1(str, pattern, replacement);
    const subm = (pattern_1, replacement_1, str_1) => replace_1(str_1, pattern_1, replacement_1, 2);
    text = sub("[\\u201c\\u201d]", "\"", text);
    text = sub("[\\u2018\\u2019]", "\u0027", text);
    text = sub("\\u2014", "--", text);
    text = sub("[\\u2026\\u22ef]", "...", text);
    text = subm("^[ \\t]{1,4}(?=[\u003e%#/@=])", "", text);
    text = sub("([.,\"\u0027?!-¡¿;\\\\:—+_–(]+)\u003c~", "\u003c~$1", text);
    text = sub("([.,\"\u0027?!-¡¿;\\\\:—+_–(]+)\u003c\\$", "\u003c$$1", text);
    text = sub("([.,\"\u0027?!-¡¿;\\\\:—+_–(]+)\u003c", "\u003c$1", text);
    text = sub("[ \\t]+\\n", "\n", text);
    const m = match(text, "^\u003e", 2);
    if (!(m != null)) {
        text = sub("(\\[.*)\\r$", "$1\\r\\n\u003e\\r\\n", text);
    }
    else {
        text = (text + "\n\u003e");
    }
    text = subm("\\s+--\\s*?$", "​--\r", text);
    text = sub("\\s+--\\s+", "​-- ", text);
    text = sub("\\[[a-zA-Z\\s]+?\\]", "", text);
    text = subm("^\\[[0-9.\\s]+?\\] *SILENCE *\\r\\n", "", text);
    text = subm("^//! *SILENCE.*\\r\\n", "", text);
    text = subm("^\\[[0-9.\\s]+?\\] *PROMOTION *\\r\\n", "", text);
    text = subm("^//! *CHAPTER-COMPLETE *\\r\\n", "", text);
    text = sub("{[^}]}", "", text);
    return text;
}

export class Annotation extends Record {
    constructor(address, content) {
        super();
        this.address = (address | 0);
        this.content = content;
    }
}

export function Annotation$reflection() {
    return record_type("JWScriptParserTools.Annotation", [], Annotation, () => [["address", int32_type], ["content", string_type]]);
}

export function findAnnotations(text, shiftOffset) {
    const matches = matches_1(text, "{(?\u003ccontent\u003e[^}]*)}");
    const result = [];
    let shift = 0;
    const enumerator = getEnumerator(matches);
    try {
        while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
            const m = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
            const annotation = new Annotation(m.index - shift, m.groups.content);
            System_Array__$005B$005D$1_append_1505(result, annotation);
            if (shiftOffset) {
                shift = (shift + m[0].length);
            }
        }
    }
    finally {
        enumerator.Dispose();
    }
    return result;
}

export class ScriptScanner0 {
    constructor(scriptText0) {
        this.scriptText = normalizeScriptText(scriptText0);
        const annotations = ScriptScanner0__extractAnnotations(this);
        this.workingTextRegions = [TextRegion(this.scriptText, 0, this.scriptText.length - 1)];
        this.foundElements = [];
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    collectPendingFoundElements(clear) {
        return ScriptScanner0__collectPendingFoundElements_Z1FBCCD16(this, clear)
    }
    discardEmptyLines() {
        return ScriptScanner0__discardEmptyLines(this)
    }
    findDirectiveLines() {
        return ScriptScanner0__findDirectiveLines(this)
    }
    findElements(pattern, tags, opts) {
        return ScriptScanner0__findElements(this, pattern, tags, opts)
    }
    findEmptyLines() {
        return ScriptScanner0__findEmptyLines(this)
    }
    findMetadataBlocks() {
        return ScriptScanner0__findMetadataBlocks(this)
    }
    findNonEmptyLineEnds() {
        return ScriptScanner0__findNonEmptyLineEnds(this)
    }
    findSegmentLabels() {
        return ScriptScanner0__findSegmentLabels(this)
    }
    findWordGroups() {
        return ScriptScanner0__findWordGroups(this)
    }
    findWords() {
        return ScriptScanner0__findWords(this)
    }
    splitWithFoundElements(discard) {
        return ScriptScanner0__splitWithFoundElements_Z1FBCCD16(this, discard)
    }
    get scriptElements() {
        return ScriptScanner0__scriptElements(this)
    }
}

export function ScriptScanner0$reflection() {
    return class_type("JWScriptParserTools.ScriptScanner0", void 0, ScriptScanner0);
}

export function ScriptScanner0_$ctor_Z721C83C5(scriptText0) {
    return new ScriptScanner0(scriptText0);
}

function ScriptScanner0__extractAnnotations(this$) {
    const annotations = [];
    return annotations;
}

function ScriptScanner0__scriptElements(this$) {
    return sortBy((t) => t.startOffset, this$.foundElements, {
        Compare: comparePrimitives,
    });
}

function ScriptScanner0__findElements(this$, pattern, tags, opts) {
    const arr = this$.workingTextRegions;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const region = arr[idx];
        const matches = matches_1(region.text, pattern, opts);
        const enumerator = getEnumerator(matches);
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                const m = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                region.foundElement(MatchElement(m, region, tags));
            }
        }
        finally {
            enumerator.Dispose();
        }
    }
}

function ScriptScanner0__splitWithFoundElements_Z1FBCCD16(this$, discard) {
    const newRegions = [];
    const arr = this$.workingTextRegions;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const region = arr[idx];
        System_Array__$005B$005D$1_extend_5975E3(newRegions, region.splitWithFoundElements());
        if (!discard) {
            System_Array__$005B$005D$1_extend_5975E3(this$.foundElements, region.foundElements);
        }
    }
    this$.workingTextRegions = newRegions;
}

function ScriptScanner0__collectPendingFoundElements_Z1FBCCD16(this$, clear) {
    const arr = this$.workingTextRegions;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const region = arr[idx];
        System_Array__$005B$005D$1_extend_5975E3(this$.foundElements, region.foundElements);
        if (clear) {
            region.foundElements = [];
        }
    }
}

function ScriptScanner0__findMetadataBlocks(this$) {
    ScriptScanner0__findElements(this$, "/\\*.*?\\*/", [METADATA_BLOCK], 16);
}

function ScriptScanner0__findEmptyLines(this$) {
    ScriptScanner0__findElements(this$, "^[ \\t]*?\\r\\n", [EMPTY_LINE], 2);
}

function ScriptScanner0__findNonEmptyLineEnds(this$) {
    ScriptScanner0__findElements(this$, "(?\u003c=\\S)\\s*$\\n", [EOL], 2);
}

function ScriptScanner0__findDirectiveLines(this$) {
    ScriptScanner0__findElements(this$, "^[\u003e%#/@=].*(?=$)", [DIRECTIVE_LINE], 2);
}

function ScriptScanner0__findWordGroups(this$) {
    ScriptScanner0__findElements(this$, "\u003c~(?\u003cwords\u003e[^\u003e|=]+?)\u003e", [WORD_GROUP, TRICKY], 2);
    ScriptScanner0__splitWithFoundElements_Z1FBCCD16(this$, false);
    ScriptScanner0__findElements(this$, "\u003c~(?\u003cwords\u003e[^|=]+?)[|=][^\u003e]+?\u003e", [WORD_GROUP, TRICKY], 2);
    ScriptScanner0__findElements(this$, "\u003c\\$(?\u003cwords\u003e[^|=]+?)[|=][^\u003e]+?\u003e", [WORD_GROUP, SIC], 2);
    ScriptScanner0__findElements(this$, "\u003c(?\u003cwords\u003e[^|=$~]+?)[|=][^\u003e]+?\u003e", [WORD_GROUP, VOCAB], 2);
    ScriptScanner0__splitWithFoundElements_Z1FBCCD16(this$, false);
}

function ScriptScanner0__findWords(this$) {
    ScriptScanner0__findElements(this$, "(?\u003cwords\u003e[\\S\\u200b]+)", [WORD], 0);
}

function ScriptScanner0__findSegmentLabels(this$) {
    ScriptScanner0__findElements(this$, "^\\[[0-9.\\s]+?\\]", [SEGMENT_LABEL], 2);
}

function ScriptScanner0__discardEmptyLines(this$) {
    ScriptScanner0__findEmptyLines(this$);
    ScriptScanner0__splitWithFoundElements_Z1FBCCD16(this$, true);
}

export function ScriptScanner(scriptText) {
    return ScriptScanner0_$ctor_Z721C83C5(scriptText);
}

export function getWords(elements) {
    const result = [];
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const element = elements[idx];
        if (element.wordCount > 0) {
            System_Array__$005B$005D$1_extend_5975E3(result, element.words);
        }
    }
    return result;
}

export function assignElementsWordPositions(elements) {
    let wordAddress = 0;
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const element = elements[idx];
        element.wordAddress = wordAddress;
        wordAddress = (wordAddress + element.wordCount);
    }
}

export function assignElementsScriptIndexes(elements) {
    let index = 0;
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const element = elements[idx];
        element.scriptIndex = index;
        index = (index + 1);
    }
}

export function elementMatchWithTags(element, tags) {
    for (let idx = 0; idx <= (tags.length - 1); idx++) {
        const tag = tags[idx];
        if (element.hasTag(tag)) {
            return true;
        }
    }
    return false;
}

export function getElementsWithTags(elements, tags) {
    return Array.from(delay(() => collect((el) => (elementMatchWithTags(el, tags) ? singleton(el) : empty()), elements)));
}

export function enumerateElementsWithTags(elements, tags) {
    const indexed = indexed_1(elements);
    return Array.from(delay(() => collect((matchValue) => {
        const index = matchValue[0] | 0;
        const el = matchValue[1];
        return elementMatchWithTags(el, tags) ? singleton([index, el]) : empty();
    }, indexed)));
}

export function findElementWithTagsStep(elements, pos, tags, step, acrossTags) {
    const lastIndex = (elements.length - 1) | 0;
    let index = (pos + step) | 0;
    while ((index < lastIndex) ? (index >= 0) : false) {
        if (elementMatchWithTags(elements[index], tags)) {
            return [index, elements[index]];
        }
        else if ((acrossTags != null) ? (!elementMatchWithTags(elements[index], value(acrossTags))) : false) {
            return [NO_INDEX, void 0];
        }
        index = (index + step);
    }
    return [NO_INDEX, void 0];
}

export function findNextElementWithTags(elements, pos, tags) {
    return findElementWithTagsStep(elements, pos, tags, 1, void 0);
}

export function findPreviousElementWithTags(elements, pos, tags) {
    return findElementWithTagsStep(elements, pos, tags, -1, void 0);
}

export function discardElementsWithTags(elements, tags) {
    return Array.from(delay(() => collect((el) => ((!elementMatchWithTags(el, tags)) ? singleton(el) : empty()), elements)));
}

export function chainElementsWithLeading(elements, tag) {
    let leading = true;
    const result = [];
    let previous = void 0;
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const element = elements[idx];
        if (element.hasTag(tag)) {
            if (leading) {
                System_Array__$005B$005D$1_append_1505(result, element);
                previous = element;
                leading = false;
            }
            else {
                previous.props["next"]=element;
                previous = element;
            }
        }
        else {
            leading = true;
            previous = (void 0);
        }
    }
    return result;
}

export function getWordCount(elements) {
    return getWords(elements).length;
}

export function getDirectiveLineKind(element) {
    const wholeText = element.wholeText;
    if (wholeText.indexOf("# ") === 0) {
        return CHAPTER_TITLE;
    }
    else if (wholeText.indexOf("## ") === 0) {
        return PASSAGE_HINT;
    }
    else if (wholeText.indexOf("###") === 0) {
        return PARAGRAPH_BREAK;
    }
    else if (wholeText.indexOf("\u003e") === 0) {
        return TRANSLATION;
    }
    else if (wholeText.indexOf("@") === 0) {
        return SPEAKER_LABEL;
    }
    else if (wholeText.indexOf("% ") === 0) {
        return CULTURAL_NOTE;
    }
    else if (wholeText.indexOf("%%") === 0) {
        return CHAPTER_SUMMARY;
    }
    else if (wholeText.indexOf("=") === 0) {
        return SOURCE_LANGUAGE_COMMENT;
    }
    else if (wholeText.indexOf("//! CHAPTER-COMPLETE") === 0) {
        return CHAPTER_COMPLETE;
    }
    else if (wholeText.indexOf("//") === 0) {
        return COMMENT;
    }
    else {
        const el = element;
        const arg10 = toString(el.textRegion.rootText.slice((element.startOffset - 10), (element.startOffset + 10)));
        toConsole(printf("11%s"))(arg10);
        return "BLORT";
    }
}

export function addTagsElement(element, tags) {
    System_Array__$005B$005D$1_extend_5975E3(element.tags, tags);
}

export function addTagsElements(elements, tags) {
    for (let idx = 0; idx <= (elements.length - 1); idx++) {
        const el = elements[idx];
        addTagsElement(el, tags);
    }
}

export function tagAllDirectiveLinesWithKind(elements) {
    const directiveLines = getElementsWithTags(elements, [DIRECTIVE_LINE]);
    for (let idx = 0; idx <= (directiveLines.length - 1); idx++) {
        const el = directiveLines[idx];
        System_Array__$005B$005D$1_append_1505(el.tags, getDirectiveLineKind(el));
    }
}

export function defaultScan(scanner, keepEmptyLines) {
    scanner.findMetadataBlocks();
    scanner.splitWithFoundElements(false);
    scanner.findEmptyLines();
    scanner.splitWithFoundElements(!keepEmptyLines);
    scanner.findDirectiveLines();
    scanner.findSegmentLabels();
    scanner.splitWithFoundElements(false);
    scanner.findWordGroups();
    scanner.splitWithFoundElements(false);
    scanner.findNonEmptyLineEnds();
    scanner.splitWithFoundElements(false);
    scanner.findWords();
    scanner.splitWithFoundElements(false);
}

export function parseWordGroup(element) {
    const $007CParseGroup$007C_$007C = (kind, pattern, content) => {
        const m = match(content, pattern);
        if (m != null) {
            return [kind, m, m.groups];
        }
        else {
            return void 0;
        }
    };
    let patternInput;
    const matchValue = element.wholeText;
    const activePatternResult4964 = $007CParseGroup$007C_$007C("VOCAB", "\u003c[^|=$~]+?[|=](?\u003cnote\u003e[^\u003e]+?)\u003e", matchValue);
    if (activePatternResult4964 != null) {
        const result = activePatternResult4964;
        patternInput = result;
    }
    else {
        const activePatternResult4962 = $007CParseGroup$007C_$007C("TRICKY", "\u003c~[^\u003e|=]+?\u003e", matchValue);
        if (activePatternResult4962 != null) {
            const result_1 = activePatternResult4962;
            patternInput = result_1;
        }
        else {
            const activePatternResult4960 = $007CParseGroup$007C_$007C("TRICKY", "\u003c~.+?[|=](?\u003cnote\u003e[^\u003e]+?)\u003e", matchValue);
            if (activePatternResult4960 != null) {
                const result_2 = activePatternResult4960;
                patternInput = result_2;
            }
            else {
                const activePatternResult4958 = $007CParseGroup$007C_$007C("SIC", "\u003c\\$.+?[|=](?\u003cnote\u003e[^\u003e]+?)\u003e", matchValue);
                if (activePatternResult4958 != null) {
                    const result_3 = activePatternResult4958;
                    patternInput = result_3;
                }
                else {
                    throw (new Error("can\u0027t parse word group"));
                }
            }
        }
    }
    const m_1 = patternInput[1];
    const kind_1 = patternInput[0];
    const groups = patternInput[2];
    return {
        groups: groups,
        kind: kind_1,
        m: m_1,
    };
}

export function parseMetadataBlock(element) {
    const $007CParseMetadataContent$007C_$007C = (pattern, content) => {
        const m = match(content, pattern, 16);
        if (m != null) {
            return [replace(replace(toString(m.groups.kind), "-", "_"), " ", "_"), toString(m.groups.content)];
        }
        else {
            return void 0;
        }
    };
    let patternInput;
    const matchValue = element.wholeText;
    const activePatternResult4978 = $007CParseMetadataContent$007C_$007C("^/\\*\\*!\\s*(?\u003ckind\u003eMETADATA.URL).*?\\r\\n(?\u003ccontent\u003e.*?)\\*/", matchValue);
    if (activePatternResult4978 != null) {
        const result = activePatternResult4978;
        patternInput = result;
    }
    else {
        const activePatternResult4976 = $007CParseMetadataContent$007C_$007C("^/\\*\\*!\\s*(?\u003ckind\u003eMETADATA).*?\\r\\n(?\u003ccontent\u003e.*?)\\*/", matchValue);
        if (activePatternResult4976 != null) {
            const result_1 = activePatternResult4976;
            patternInput = result_1;
        }
        else {
            const activePatternResult4974 = $007CParseMetadataContent$007C_$007C("^/\\*\\*!\\s*(?\u003ckind\u003eNOTES).*?\\r\\n(?\u003ccontent\u003e.*?)\\*/", matchValue);
            if (activePatternResult4974 != null) {
                const result_2 = activePatternResult4974;
                patternInput = result_2;
            }
            else {
                const activePatternResult4972 = $007CParseMetadataContent$007C_$007C("^/\\*\\*!\\s*(?\u003ckind\u003eCAST).*?\\r\\n(?\u003ccontent\u003e.*?)\\*/", matchValue);
                if (activePatternResult4972 != null) {
                    const result_3 = activePatternResult4972;
                    patternInput = result_3;
                }
                else {
                    const activePatternResult4970 = $007CParseMetadataContent$007C_$007C("^/\\*\\*!\\s*(?\u003ckind\u003eASSET-LINKS).*?\\r\\n(?\u003ccontent\u003e.*?)\\*/", matchValue);
                    if (activePatternResult4970 != null) {
                        const result_4 = activePatternResult4970;
                        patternInput = result_4;
                    }
                    else {
                        throw (new Error("can\u0027t parse metadata block"));
                    }
                }
            }
        }
    }
    const kind = patternInput[0];
    const content_1 = patternInput[1];
    return {
        content: content_1,
        kind: kind,
    };
}

export function parseStructuralContent(element) {
    const text = element.wholeText.trim();
    return replace_1(text, "^[@#%=]+\\s*", "");
}

export function parseCue(element) {
    return {};
}

export class PairOptsRecord extends Record {
    constructor(direction, allowAcross, allowAcrossSourceType, allowAcrossEOL, allowAcrossEmptyLine, mustPair, allowSelfPair, errors) {
        super();
        this.direction = (direction | 0);
        this.allowAcross = allowAcross;
        this.allowAcrossSourceType = allowAcrossSourceType;
        this.allowAcrossEOL = allowAcrossEOL;
        this.allowAcrossEmptyLine = allowAcrossEmptyLine;
        this.mustPair = mustPair;
        this.allowSelfPair = allowSelfPair;
        this.errors = errors;
    }
}

export function PairOptsRecord$reflection() {
    return record_type("JWScriptParserTools.PairOptsRecord", [], PairOptsRecord, () => [["direction", int32_type], ["allowAcross", array_type(string_type)], ["allowAcrossSourceType", bool_type], ["allowAcrossEOL", bool_type], ["allowAcrossEmptyLine", bool_type], ["mustPair", bool_type], ["allowSelfPair", bool_type], ["errors", array_type(string_type)]]);
}

export const PairOpts = new PairOptsRecord(1, [], false, true, true, true, true, void 0);

export class PairingError extends Record {
    constructor(blort) {
        super();
        this.blort = blort;
    }
}

export function PairingError$reflection() {
    return record_type("JWScriptParserTools.PairingError", [], PairingError, () => [["blort", string_type]]);
}

export function pairElements(elements, sourceTags, targetTags, opts) {
    const allowAcross = copy(opts.allowAcross);
    if (opts.allowAcrossEOL ? (!contains(EOL, allowAcross, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })) : false) {
        System_Array__$005B$005D$1_append_1505(allowAcross, EOL);
    }
    if (opts.allowAcrossEmptyLine ? (!contains(EMPTY_LINE, allowAcross, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    })) : false) {
        System_Array__$005B$005D$1_append_1505(allowAcross, EMPTY_LINE);
    }
    const sources = enumerateElementsWithTags(elements, sourceTags);
    const lastIndex = (elements.length - 1) | 0;
    const pairs = [];
    const step = opts.direction | 0;
    const matchTags = (tupledArg) => elementMatchWithTags(tupledArg[0], tupledArg[1]);
    for (let idx = 0; idx <= (sources.length - 1); idx++) {
        const forLoopVar = sources[idx];
        const sourcePos = forLoopVar[0] | 0;
        const source = forLoopVar[1];
        let index = (opts.allowSelfPair ? sourcePos : (sourcePos + step)) | 0;
        let paired = false;
        while ((index < lastIndex) ? (index >= 0) : false) {
            if (matchTags([elements[index], targetTags])) {
                System_Array__$005B$005D$1_append_1505(pairs, [source, elements[index]]);
                paired = true;
                break;
            }
            else if (index !== sourcePos) {
                if (!matchTags([elements[index], allowAcross])) {
                    if (!(opts.allowAcrossSourceType ? matchTags([elements[index], sourceTags]) : false)) {
                        break;
                    }
                }
            }
            index = (index + step);
        }
        if ((!paired) ? opts.mustPair : false) {
            throw (new Error("failed to pair " + sourceTags[0]));
        }
    }
    return pairs;
}

// JS BOILERPLATE GENERATED
 