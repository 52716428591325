import {MutationActions} from './mutation-actions.js';
import {ScriptEditorModel} from './script-editor-model.fs.js';
import {KeyboardModes} from './masala-lib/keyboardist/keyboard-modes.fs.js';
import {StructuralActions} from './models/structural-actions.fs.js';
import {MetadataBlockActions} from './models/metadata-block-actions.fs.js';
import {WordGroupActions} from './models/word-group-actions.fs.js';
import {ConversationManager} from './masala-lib/editorial/models/conversation-manager.js';
import {VerbatimActions} from './models/verbatim-actions.fs.js';
import {TranslationActions} from './models/translation-actions.fs.js';
import {Versions} from './masala-lib/editorial/models/versions.fs.js';
import {AlertMessages} from './masala-lib/alert-messages.fs.js';
import {Auth} from './masala-lib/editorial/db/auth.js';

export const auth = new Auth();
export const alertMessages = AlertMessages();
export const mutationActions = new MutationActions();
export const scriptEditorModel = ScriptEditorModel();
export const keyboardModes = KeyboardModes();
export const structuralActions = StructuralActions();
export const metadataBlockActions = MetadataBlockActions();
export const wordGroupActions = WordGroupActions();
export const conversationManager = ConversationManager();
export const verbatimActions = VerbatimActions();
export const translationActions = TranslationActions();
export const versions = Versions();
