import { Record } from "../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type, unit_type, lambda_type, record_type, obj_type, bool_type, string_type } from "../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { matches as matches_1, match } from "../../../.fable/fable-library.3.1.0-beta-001/RegExp.js";
import { PASSAGE_HINT, SPEAKER_LABEL, CHAPTER_SUMMARY, CHAPTER_TITLE, SENTENCE, SIC, TRICKY, VOCAB, WORD_GROUP } from "../../elements/element-types.fs.js";
import { append, sortWith, zip, concat, tryFindIndex, contains } from "../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { partialApply, compare, equals, stringHash } from "../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import lodash from "lodash";
import { map, empty, singleton, collect, delay } from "../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { System_Array__$005B$005D$1_get_lastIndex, System_Array__$005B$005D$1_append_1505 } from "../../basic-types.fs.js";
import { reaction } from "mobx";
import { join } from "../../../.fable/fable-library.3.1.0-beta-001/String.js";

export class FilterTerm extends Record {
    constructor(inputText, parsed, kind, props) {
        super();
        this.inputText = inputText;
        this.parsed = parsed;
        this.kind = kind;
        this.props = props;
    }
}

export function FilterTerm$reflection() {
    return record_type("FilterModel.FilterTerm", [], FilterTerm, () => [["inputText", string_type], ["parsed", bool_type], ["kind", string_type], ["props", obj_type]]);
}

export class FilterDef extends Record {
    constructor(kind, isFlag, canonicalText, parse, func) {
        super();
        this.kind = kind;
        this.isFlag = isFlag;
        this.canonicalText = canonicalText;
        this.parse = parse;
        this.func = func;
    }
}

export function FilterDef$reflection() {
    return record_type("FilterModel.FilterDef", [], FilterDef, () => [["kind", string_type], ["isFlag", bool_type], ["canonicalText", lambda_type(FilterTerm$reflection(), string_type)], ["parse", lambda_type(FilterTerm$reflection(), unit_type)], ["func", lambda_type(FilterTerm$reflection(), lambda_type(class_type("BasicTypes.IElement"), bool_type))]]);
}

export const UNFILLED = "UNFILLED";

export const STRUCTURAL = "STRUCTURAL";

export const OPEN = "OPEN";

export const WARNING = "WARNING";

export const ASSIGNED = "ASSIGNED";

export const AREPARTICIPANT = "AREPARTICIPANT";

export const MENTION = "MENTION";

export const BY = "BY";

export function regexTermParser(kind, pattern, term) {
    const m = match(term.inputText, pattern);
    if (m != null) {
        term.kind = kind;
        term.parsed = true;
        term.props = m.groups;
    }
}

export const wordGroupFilter = new FilterDef(WORD_GROUP, true, (t) => "#group ", (term) => {
    regexTermParser(WORD_GROUP, "#group", term);
}, (term_1, el) => (el.kind === WORD_GROUP));

export const vocabFilter = new FilterDef(VOCAB, true, (t) => "#vocab ", (term) => {
    regexTermParser(VOCAB, "#vocab", term);
}, (term_1, el) => (el.subKind === VOCAB));

export const trickyFilter = new FilterDef(TRICKY, true, (t) => "#tricky ", (term) => {
    regexTermParser(TRICKY, "#tricky", term);
}, (term_1, el) => (el.subKind === TRICKY));

export const sicFilter = new FilterDef(SIC, true, (t) => "#sic ", (term) => {
    regexTermParser(SIC, "#sic", term);
}, (term_1, el) => (el.subKind === SIC));

export const sentenceFilter = new FilterDef(SENTENCE, true, (t) => "#sentence ", (term) => {
    regexTermParser(SENTENCE, "#sentence", term);
}, (term_1, el) => (el.kind === SENTENCE));

export const structuralFilter = new FilterDef(STRUCTURAL, true, (t) => "#structural ", (term) => {
    regexTermParser(STRUCTURAL, "#structural", term);
}, (term_1, el) => contains(el.kind, [CHAPTER_TITLE, CHAPTER_SUMMARY, SPEAKER_LABEL, PASSAGE_HINT], {
    Equals: (x, y) => (x === y),
    GetHashCode: stringHash,
}));

export const unfilledFilter = new FilterDef(UNFILLED, true, (t) => "#unfilled ", (term) => {
    regexTermParser(UNFILLED, "#unfilled", term);
}, (term_1, el) => {
    if ((el.kind === WORD_GROUP) ? (el.subKind === VOCAB) : false) {
        const x = el.content.note;
        return lodash.isEmpty(x);
    }
    else {
        return false;
    }
});

export const openFilter = new FilterDef(OPEN, true, (t) => "#open ", (term) => {
    regexTermParser(OPEN, "#open", term);
}, (term_1, el) => (el.thread.withMessages ? (!el.thread.resolved) : false));

export function username(term) {
    if (term.props.username) {
        return term.props.username;
    }
    else {
        return null;
    }
}

export const byFilter = new FilterDef(BY, false, (t) => (("@" + t.props.username) + " "), (term) => {
    regexTermParser(BY, "@(?\u003cusername\u003e\\w*)", term);
}, (term_1, el) => {
    const name = username(term_1);
    return name ? ((el.author.indexOf(name)) !== -1) : false;
});

export function functionListAND(funcs, v) {
    if (funcs.length === 0) {
        return false;
    }
    else {
        return !((typeof tryFindIndex((f) => (!f(v)), funcs) === 'number'));
    }
}

export class FilterModel0 {
    constructor(filterDefs0) {
        let clo1, clo1_1, clo1_2;
        this.filterDefs = filterDefs0;
        this.filterDefMap = (new Map());
        this.uiText = "";
        this.inputFilterTerms = [];
        this.filterFunction = ((e) => false);
        const disposers = [];
        const allFlagKindsList = Array.from(delay(() => collect((def) => (def.isFlag ? singleton(def.kind) : empty()), this.filterDefs)));
        this.allFlagKindsSet = (new Set(allFlagKindsList));
        this.termKindsCanonicalOrder = Array.from(delay(() => map((def_1) => def_1.kind, this.filterDefs)));
        this.parsedFilterTerms$yrfGnqiqc_pz = null;
        this.activeFlags$wcJeE9gJ3u80 = null;
        this.filterKey$busroE0zdLWm = null;
        makeObservable(this, {
            uiText: observable.ref,
            inputFilterTerms: observable.ref,
            filterFunction: observable.ref,
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
        const arr = this.filterDefs;
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const def_2 = arr[idx];
            this.filterDefMap.set(def_2.kind, def_2);
        }
        System_Array__$005B$005D$1_append_1505(disposers, (clo1 = reaction((() => this.uiText), () => {
            FilterModel0__computeInputFilterTerms(this);
        }, {}), () => {
            clo1();
        }));
        System_Array__$005B$005D$1_append_1505(disposers, (clo1_1 = reaction((() => this.inputFilterTerms), () => {
            FilterModel0__computeUiText(this);
        }, {}), () => {
            clo1_1();
        }));
        System_Array__$005B$005D$1_append_1505(disposers, (clo1_2 = reaction((() => FilterModel0__filterKey(this)), () => {
            FilterModel0__computeFilterFunction(this);
        }, {}), () => {
            clo1_2();
        }));
    }
    setFlag(flag, value) {
        return FilterModel0__setFlag_Z55EFCE8F(this, flag, value)
    }
    get activeFlags() {
        return FilterModel0__activeFlags(this)
    }
}

export function FilterModel0$reflection() {
    return class_type("FilterModel.FilterModel0", void 0, FilterModel0);
}

export function FilterModel0_$ctor_138E5945(filterDefs0) {
    return new FilterModel0(filterDefs0);
}

function FilterModel0__termSortComparer(this$, t1, t2) {
    const t1index = tryFindIndex((kind) => (t1.kind === kind), this$.termKindsCanonicalOrder);
    const t2index = tryFindIndex((kind_1) => (t2.kind === kind_1), this$.termKindsCanonicalOrder);
    if (equals(t1index, t2index)) {
        return 0;
    }
    else if (compare(t1index, t2index) > 0) {
        return 1;
    }
    else {
        return -1;
    }
}

function FilterModel0__rawTermsFromText_Z721C83C5(this$, text) {
    const delimiters = "@|#";
    const matches = matches_1(text, delimiters, 0);
    const lookBehindAt = (offset) => {
        if (offset > 0) {
            return text[offset - 1] === "@";
        }
        else {
            return false;
        }
    };
    let offsets = Int32Array.from(delay(() => collect((m) => ((!lookBehindAt(m.index)) ? singleton(m.index) : empty()), matches)));
    offsets = concat([new Int32Array([0]), offsets, new Int32Array([text.length])], Int32Array);
    const starts = offsets.slice(0, (System_Array__$005B$005D$1_get_lastIndex(offsets) - 1) + 1);
    const ends = offsets.slice(1, System_Array__$005B$005D$1_get_lastIndex(offsets) + 1);
    const bounds = zip(starts, ends);
    return Array.from(delay(() => collect((matchValue) => {
        const s = matchValue[0] | 0;
        const e = matchValue[1] | 0;
        return singleton(new FilterTerm(text.slice(s, (e - 1) + 1), false, null, null));
    }, bounds)));
}

function FilterModel0__parseTerm_10245AAA(this$, term) {
    const arr = this$.filterDefs;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const def = arr[idx];
        def.parse(term);
        if (term.parsed) {
            break;
        }
    }
}

function FilterModel0__termCanonicalText_10245AAA(this$, term) {
    if (term.parsed) {
        const def = this$.filterDefMap.get(term.kind);
        return def.canonicalText(term);
    }
    else {
        return null;
    }
}

function FilterModel0__termUiText_10245AAA(this$, term) {
    if (term.inputText) {
        return term.inputText;
    }
    else {
        return FilterModel0__termCanonicalText_10245AAA(this$, term);
    }
}

function FilterModel0__termFilterFunction_10245AAA(this$, term) {
    if (term.parsed) {
        const def = this$.filterDefMap.get(term.kind);
        return partialApply(1, def.func, [term]);
    }
    else {
        throw (new Error("not parsed in termFilterFunction"));
        return null;
    }
}

function FilterModel0__computeInputFilterTerms(this$) {
    const terms = FilterModel0__rawTermsFromText_Z721C83C5(this$, this$.uiText);
    for (let idx = 0; idx <= (terms.length - 1); idx++) {
        const term = terms[idx];
        FilterModel0__parseTerm_10245AAA(this$, term);
    }
    this$.inputFilterTerms = terms;
}

function FilterModel0__computeUiText(this$) {
    const termStrings = Array.from(delay(() => map((term) => FilterModel0__termUiText_10245AAA(this$, term), this$.inputFilterTerms)));
    this$.uiText = join("", termStrings);
}

function FilterModel0__parsedFilterTerms(this$) {  if (!this$.parsedFilterTerms$yrfGnqiqc_pz) { this$.parsedFilterTerms$yrfGnqiqc_pz = computed(() => {
    return Array.from(delay(() => collect((term) => (term.parsed ? singleton(term) : empty()), this$.inputFilterTerms)));
})}; return this$.parsedFilterTerms$yrfGnqiqc_pz.get(); }

function FilterModel0__activeFlags(this$) {  if (!this$.activeFlags$wcJeE9gJ3u80) { this$.activeFlags$wcJeE9gJ3u80 = computed(() => {
    const flagList = Array.from(delay(() => collect((term) => (this$.allFlagKindsSet.has(term.kind) ? singleton(term.kind) : empty()), FilterModel0__parsedFilterTerms(this$))));
    return new Set(flagList);
})}; return this$.activeFlags$wcJeE9gJ3u80.get(); }

function FilterModel0__filterKey(this$) {  if (!this$.filterKey$busroE0zdLWm) { this$.filterKey$busroE0zdLWm = computed(() => {
    const terms = sortWith((t1, t2) => FilterModel0__termSortComparer(this$, t1, t2), FilterModel0__parsedFilterTerms(this$));
    return join("", Array.from(delay(() => map((term) => FilterModel0__termCanonicalText_10245AAA(this$, term), terms))));
})}; return this$.filterKey$busroE0zdLWm.get(); }

function FilterModel0__computeFilterFunction(this$) {
    const filters = Array.from(delay(() => map((term) => FilterModel0__termFilterFunction_10245AAA(this$, term), FilterModel0__parsedFilterTerms(this$))));
    this$.filterFunction = ((v) => functionListAND(filters, v));
}

function FilterModel0__setFlag_Z55EFCE8F(this$, flag, value) {
    if (value) {
        this$.inputFilterTerms = append(this$.inputFilterTerms, [new FilterTerm(null, true, flag, null)]);
    }
    else {
        this$.inputFilterTerms = this$.inputFilterTerms.filter((t) => (t.kind !== flag));
    }
}

export function FilterModel(filterDefs) {
    return FilterModel0_$ctor_138E5945(filterDefs);
}

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 