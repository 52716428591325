import React from "react";
import {StyleLayersRenderer} from "./style-painting/style-layers.fs.js";
import {renderWordRange} from "./word-range-render.fs.js";

export function renderView({wordRange, domScope, styleLayers, wordGroups, domainWords, onClick}) {
  const styleLayersRenderer = StyleLayersRenderer();
  styleLayersRenderer.setStyleLayers(styleLayers);
  const html = renderWordRange(domScope, styleLayersRenderer, domainWords, wordRange, wordGroups);
  return (
    <div
      className="word-range-select"
      dangerouslySetInnerHTML={{__html:html}}
      onClick={onClick}
    />
  );
}
