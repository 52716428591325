import { alertMessages, scriptEditorModel, mutationActions } from "../app-root.fs.js";
import { VerbatimActions0$reflection, VerbatimActions0 } from "../masala-lib/editorial/models/actions/verbatim-actions.fs.js";
import { class_type } from "../.fable/fable-library.3.1.0-beta-001/Reflection.js";

export class VerbatimActions1 extends VerbatimActions0 {
    constructor() {
        super();
        this["init@7-20"] = 1;
    }
    ["VerbatimActions.VerbatimActions0.get_mutationActions"]() {
        return mutationActions;
    }
    ["VerbatimActions.VerbatimActions0.get_content"]() {
        return scriptEditorModel.elements;
    }
    ["VerbatimActions.VerbatimActions0.get_alertMessages"]() {
        return alertMessages;
    }
}

export function VerbatimActions1$reflection() {
    return class_type("ScriptEditorVerbatimActions.VerbatimActions1", void 0, VerbatimActions1, VerbatimActions0$reflection());
}

export function VerbatimActions1_$ctor() {
    return new VerbatimActions1();
}

export function VerbatimActions() {
    return VerbatimActions1_$ctor();
}

