// TODO: rename to firebase-init.js

import {firebase} from './firebase.init.js';
// import firebase_init from "./firebase.init";
// const {firebase} = firebase_init;
// import {firebase} from "./firebase.init.cjs";

export const firebaseAuth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => firebaseAuth.signInWithPopup(provider);
