import { isWordRange } from "../../../elements/adhoc-word-range.fs.js";
import { getEnumerator } from "../../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { System_Array__$005B$005D$1_extend_5975E3, elementIdToDomId } from "../../../basic-types.fs.js";
import { Record } from "../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { record_type, bool_type, class_type, array_type, string_type } from "../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { join } from "../../../../.fable/fable-library.3.1.0-beta-001/String.js";
import lodash from "lodash";

export function renderStylesForDomId(domId, styles, remove) {
    const node = document.getElementById(domId);
    if (node) {
        if (remove) {
            node.classList.remove(...styles);
        }
        else {
            node.classList.add(...styles);
        }
    }
}

export function renderStylesForElement(element, styles, elementList, domScope, remove) {
    if (isWordRange(element)) {
        const adhocWordRange = element;
        const range = adhocWordRange.range;
        const wordIds = elementList.words.idRangeAsIdSeq(range);
        const enumerator = getEnumerator(wordIds);
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                const wordId = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                const domId = elementIdToDomId(domScope, wordId);
                renderStylesForDomId(domId, styles, remove);
            }
        }
        finally {
            enumerator.Dispose();
        }
    }
    else {
        const domId_1 = elementIdToDomId(domScope, element.id);
        renderStylesForDomId(domId_1, styles, remove);
    }
}

export function simpleRenderStylesForElementId(id, styles, remove) {
    const domId = elementIdToDomId("", id);
    renderStylesForDomId(domId, styles, remove);
}

export function renderStylesForElementList(elementList, styles, domScope, remove) {
    const arr = elementList.elements;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const element = arr[idx];
        renderStylesForElement(element, styles, elementList, domScope, remove);
    }
}

export class StyleLayer extends Record {
    constructor(styles, elements, domScope, supportWordRanges, stylesString) {
        super();
        this.styles = styles;
        this.elements = elements;
        this.domScope = domScope;
        this.supportWordRanges = supportWordRanges;
        this.stylesString = stylesString;
    }
}

export function StyleLayer$reflection() {
    return record_type("StyleLayers.StyleLayer", [], StyleLayer, () => [["styles", array_type(string_type)], ["elements", class_type("ElementList.IElementList")], ["domScope", string_type], ["supportWordRanges", bool_type], ["stylesString", string_type]]);
}

export function computeStylesStringForLayer(layer) {
    if (!layer.stylesString) {
        layer.stylesString = join(" ", layer.styles);
    }
    return layer.stylesString;
}

export class StyleLayersRenderer0 {
    constructor() {
        this.episodeKey = "";
        const domScope = "";
        this.layers = (new Map());
        this.layerStack = [];
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    getStyleForElement(id) {
        return StyleLayersRenderer0__getStyleForElement_Z721C83C5(this, id)
    }
    getStyleForWordAddress(index) {
        return StyleLayersRenderer0__getStyleForWordAddress_Z524259A4(this, index)
    }
    renderStyleLayers(episodeKey0, layers0) {
        return StyleLayersRenderer0__renderStyleLayers_749CA8F9(this, episodeKey0, layers0)
    }
    setStyleLayers(layers0) {
        return StyleLayersRenderer0__setStyleLayers_3ECFAB62(this, layers0)
    }
}

export function StyleLayersRenderer0$reflection() {
    return class_type("StyleLayers.StyleLayersRenderer0", void 0, StyleLayersRenderer0);
}

export function StyleLayersRenderer0_$ctor() {
    return new StyleLayersRenderer0();
}

function StyleLayersRenderer0__getStyleForWordAddress_Z524259A4(this$, index) {
    let style = "";
    const arr = this$.layerStack;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const layer = arr[idx];
        if (layer.supportWordRanges) {
            const found = layer.elements.getElementContainingWordAddress(index);
            if (found ? isWordRange(found) : false) {
                style = ((style + " ") + computeStylesStringForLayer(layer));
            }
        }
    }
    return style;
}

function StyleLayersRenderer0__getStyleForElement_Z721C83C5(this$, id) {
    const styles = [];
    const arr = this$.layerStack;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const layer = arr[idx];
        if (layer.elements.hasElement(id)) {
            System_Array__$005B$005D$1_extend_5975E3(styles, layer.styles);
        }
    }
    return join(" ", styles);
}

function StyleLayersRenderer0__setStyleLayers_3ECFAB62(this$, layers0) {
    this$.layers = layers0;
    this$.layerStack = Array.from(layers0.values());
}

function StyleLayersRenderer0__renderStyleLayers_749CA8F9(this$, episodeKey0, layers0) {
    if (this$.episodeKey === episodeKey0) {
        const enumerator = getEnumerator(layers0.keys());
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                const key = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                const newOverlay = layers0.get(key);
                const oldOverlay = this$.layers.get(key);
                if (lodash.isNil(oldOverlay)) {
                    renderStylesForElementList(newOverlay.elements, newOverlay.styles, null, false);
                }
                else {
                    const addedElements = newOverlay.elements.difference(oldOverlay.elements);
                    const removedElements = oldOverlay.elements.difference(newOverlay.elements);
                    renderStylesForElementList(removedElements, newOverlay.styles, null, true);
                    renderStylesForElementList(addedElements, newOverlay.styles, null, false);
                }
            }
        }
        finally {
            enumerator.Dispose();
        }
    }
    this$.episodeKey = episodeKey0;
    this$.layers = layers0;
    this$.layerStack = Array.from(layers0.values());
}

export function StyleLayersRenderer() {
    return StyleLayersRenderer0_$ctor();
}

// JS BOILERPLATE GENERATED
 