import { FSharpRef } from "./.fable/fable-library.3.1.0-beta-001/Types.js";
import { metadataOrder, interleave, ContentRootsBase0$reflection, ContentRootsBase0 } from "./masala-lib/editorial/content-roots/content-roots.fs.js";
import { class_type } from "./.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { System_Array__$005B$005D$1_extend_5975E3 } from "./masala-lib/basic-types.fs.js";
import { sortElements } from "./masala-lib/elements/element-sort.fs.js";
import { append, sortBy } from "./.fable/fable-library.3.1.0-beta-001/Array.js";
import { comparePrimitives } from "./.fable/fable-library.3.1.0-beta-001/Util.js";
import { ElementList } from "./masala-lib/elements/element-list.fs.js";

export class ScriptEditorContentRoots0 extends ContentRootsBase0 {
    constructor() {
        super();
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self_1 = (s0.contents);
        this.stateVersion0 = 1;
        this["init@19-15"] = 1;
        makeObservable(this, {
            words: computed({keepAlive: true}),
            content: computed({keepAlive: true}),
            translations: computed({keepAlive: true}),
            stateVersion: computed({keepAlive: true}),
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    get content() {
        return ScriptEditorContentRoots0__content(this)
    }
    get stateVersion() {
        return ScriptEditorContentRoots0__stateVersion(this)
    }
    get translations() {
        return ScriptEditorContentRoots0__translations(this)
    }
    get words() {
        return ScriptEditorContentRoots0__words(this)
    }
}

export function ScriptEditorContentRoots0$reflection() {
    return class_type("ScriptEditorContentRoots.ScriptEditorContentRoots0", void 0, ScriptEditorContentRoots0, ContentRootsBase0$reflection());
}

export function ScriptEditorContentRoots0_$ctor() {
    return new ScriptEditorContentRoots0();
}

function ScriptEditorContentRoots0__words(this$) {
    return this$.self_1.words0;
}

function ScriptEditorContentRoots0__content(this$) {
    const result = [];
    System_Array__$005B$005D$1_extend_5975E3(result, this$.self_1.sentences0);
    System_Array__$005B$005D$1_extend_5975E3(result, this$.self_1.wordGroups0);
    System_Array__$005B$005D$1_extend_5975E3(result, this$.self_1.structural0);
    sortElements(result);
    const translations = this$.self_1.translations0;
    const translationsLookup = (id) => (translations[id]);
    const result_1 = interleave(result, translationsLookup, 1);
    const metadataToOrderFunc0 = metadataOrder.functions.precedenceProjection;
    const metadataToOrderFunc = (m) => metadataToOrderFunc0(m.subKind);
    const metadata = sortBy(metadataToOrderFunc, this$.self_1.metadataBlocks0, {
        Compare: comparePrimitives,
    });
    const result_2 = append(metadata, result_1);
    const result_3 = ElementList(result_2, this$.self_1.episodeKey, null, this$.self_1.words0, null, null, null);
    return result_3;
}

function ScriptEditorContentRoots0__translations(this$) {
    return this$.self_1.translations0;
}

function ScriptEditorContentRoots0__stateVersion(this$) {
    const touch = [this$.self_1.words, this$.self_1.content, this$.self_1.translations];
    this$.stateVersion0 = (this$.stateVersion0 + 1);
    return this$.stateVersion0 | 0;
}

export function ScriptEditorContentRoots() {
    return ScriptEditorContentRoots0_$ctor();
}

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 