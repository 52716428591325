import { match } from "../../../.fable/fable-library.3.1.0-beta-001/RegExp.js";
import { interpolate, toText, split } from "../../../.fable/fable-library.3.1.0-beta-001/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../promise/promise.fs.js";
import { promise } from "../../promise/promise-impl.fs.js";
import { loadScriptContent } from "../db/loader-funcs.fs.js";
import { METADATA_URL, METADATA_BLOCK } from "../../elements/element-types.fs.js";
import { tryFind } from "../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { fetchTextFromGoogleDoc } from "../db/./loader-funcs.js";
import { initChaatFromMetadata } from "../db/./mutation-actions.js";

export function audioUrlFromGoogleUrl(url) {
    const m = match(url, "/file/d/([^/]+)/*");
    let audioDocId = null;
    if (m != null) {
        audioDocId = (m[1] || "");
    }
    else {
        audioDocId = split(url, ["="], null, 0)[1].trim();
    }
    return toText(interpolate("https://drive.google.com/uc?export=download\u0026id=%P()", [audioDocId]));
}

export function extractInitChaatDataFromScriptData(episodeKey) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (loadScriptContent(episodeKey).then(((_arg1) => {
        let metadataUrlBlockBody, m;
        const content = _arg1;
        const metadataElements = content.getKindSubList(METADATA_BLOCK).elements;
        let metadataUrl = null;
        let assetLinksBlockBody = null;
        let metadataBlockBody = null;
        let languageCode = null;
        const metadataUrlElement = (((predicate) => ((array) => tryFind(predicate, array))))((el) => (el.subKind === METADATA_URL))(metadataElements);
        const splitLines = (text) => split(text, ["\n", "\r"], null, 1);
        const afterColon = (line) => line.slice(line.indexOf(":") + 1, (line.length - 1) + 1);
        return (metadataUrlElement ? (metadataUrlBlockBody = (metadataUrlElement.content), metadataUrlBlockBody ? (m = match(metadataUrlBlockBody, "(http.*)$", 16), (m != null) ? (metadataUrl = (m[1] || "").trim(), Promise.resolve()) : (Promise.resolve())) : (Promise.resolve())) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let assetLinksBlock, metadataBlock;
            return (metadataUrl ? (fetchTextFromGoogleDoc(metadataUrl).then(((_arg2) => {
                const metadataText = _arg2;
                const m_1 = match(metadataText, "/\\*\\*!\\s*UNIT.METADATA.*?\\r\\n(.*?)\\*/", 16);
                if (m_1 != null) {
                    metadataBlockBody = (m_1[1] || "").trim();
                    assetLinksBlockBody = metadataBlockBody;
                    return Promise.resolve();
                }
                else {
                    const m_2 = match(metadataText, "/\\*\\*!\\s*ASSET.LINKS.*?\\r\\n(.*?)\\*/", 16);
                    return ((m_2 != null) ? (assetLinksBlockBody = (m_2[1] || "").trim(), Promise.resolve()) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const m_3 = match(metadataText, "/\\*\\*!\\s*METADATA.*?\\r\\n(.*?)\\*/", 16);
                        if (m_3 != null) {
                            metadataBlockBody = (m_3[1] || "").trim();
                            return Promise.resolve();
                        }
                        else {
                            return Promise.resolve();
                        }
                    }));
                }
            }))) : (assetLinksBlock = (((predicate_1) => ((array_1) => tryFind(predicate_1, array_1))))((el_1) => (el_1.subKind === "ASSET_LINKS"))(metadataElements), (assetLinksBlockBody = (assetLinksBlock.content), (metadataBlock = (((predicate_2) => ((array_2) => tryFind(predicate_2, array_2))))((el_2) => (el_2.subKind === "METADATA"))(metadataElements), (metadataBlockBody = (metadataBlock.content), Promise.resolve()))))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const metadataBlockLines = splitLines(metadataBlockBody);
                const languageCodeLine = (((predicate_3) => ((array_3) => tryFind(predicate_3, array_3))))((line_1) => (line_1.indexOf("AUDIO LANGUAGE CODE") === 0))(metadataBlockLines);
                return (languageCodeLine ? (languageCode = afterColon(languageCodeLine), Promise.resolve()) : (languageCode = "es-US", Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const audioUrlLines = splitLines(assetLinksBlockBody);
                    let audioUrlLine = (((predicate_4) => ((array_4) => tryFind(predicate_4, array_4))))((line_2) => (line_2.indexOf("CHAAT INPUT URL") === 0))(audioUrlLines);
                    return ((!audioUrlLine) ? (audioUrlLine = (((predicate_5) => ((array_5) => tryFind(predicate_5, array_5))))((line_3) => (line_3.indexOf("AUDIO TRANSCRIBE") === 0))(audioUrlLines), Promise.resolve()) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (((!audioUrlLine) ? (audioUrlLine = (((predicate_6) => ((array_6) => tryFind(predicate_6, array_6))))((line_4) => (line_4.indexOf("AUDIO NO MUSIC") === 0))(audioUrlLines), Promise.resolve()) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (((!audioUrlLine) ? (void 0, Promise.resolve()) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const audioUrl = audioUrlFromGoogleUrl(afterColon(audioUrlLine));
                        let finalAudioUrlLine = (((predicate_7) => ((array_7) => tryFind(predicate_7, array_7))))((line_5) => (line_5.indexOf("AUDIO FINAL URL:") === 0))(audioUrlLines);
                        return ((!finalAudioUrlLine) ? (finalAudioUrlLine = (((predicate_8) => ((array_8) => tryFind(predicate_8, array_8))))((line_6) => (line_6.indexOf("AUDIO:") === 0))(audioUrlLines), Promise.resolve()) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const finalAudioUrl = audioUrlFromGoogleUrl(afterColon(finalAudioUrlLine));
                            return Promise.resolve({
                                audioLanguageCode: languageCode,
                                finalAudioUrl: finalAudioUrl,
                                transcribeAudioUrl: audioUrl,
                            });
                        }));
                    }))))))));
                }));
            }));
        }));
    })))));
}

export function initChaat(episodeKey) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (extractInitChaatDataFromScriptData(episodeKey).then(((_arg1) => {
        const metadata = _arg1;
        return initChaatFromMetadata(episodeKey, metadata);
    })))));
}

