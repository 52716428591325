import React from "react";
import {observer} from "mobx-react";
import {ScriptEditor} from "../script-editor.fs.js";
import {ContextualSidepanel} from "../contextual-sidepanel.js";
import {StatusBar} from "../status-bar.js";
import {SearchBar} from "../search-bar.js";
import {ChoiceModeDialog} from "../choice-mode-dialog.js";
import {WordGroupInspectorDialog} from "../word-group-inspector/word-group-inspector-dialog.js";
import {FiltersDialog} from "../filters-dialog.js";
import {VersionsDialog} from "../versions-dialog.js";
import {Alerts} from "../masala-lib/editorial/ui/alerts.js";
import {alertMessages, appRoot } from "../app-root.fs.js";

import '../App.css';

const SideBar = observer((props) => {
  return (
      <div className={'right-sidepanel'}>
        <ContextualSidepanel/>
      </div>
  );
});

export default ({ match }) => {
  // this value comes from the URL, provided by react-router
  const {
    params: { scriptKey = null , jumpId = null}
  } = match;
  appRoot.loadEpisode(scriptKey, jumpId)
  //  TODO make ScriptEditor just access the global singleton instead of passing prop?
  return (
    <div style={{height: "100%"}}>
      <div id={'main-container'}>
        <div className={'left-sidepanel'}/>
        <div className={'editor-main'}>
          <StatusBar/>
          <SearchBar/>
          <ScriptEditor/>
          <div className={'alerts'}>
            <Alerts alerts={alertMessages}/>
          </div>
        </div>
        <SideBar/>
        <ChoiceModeDialog/>
        <FiltersDialog/>
        <VersionsDialog/>
        <WordGroupInspectorDialog/>
      </div>
    </div>
  );
};
