import { makeObservable, observable } from 'mobx';
import { isEmpty, isNil } from 'lodash-es';
import { db, fieldValueDelete, fieldValueArrayUnion } from '../db/firebase.init.js';
import { BaseLoader } from '../../firestore-db/base-loader';
import { randomString, epochSecondsFloat } from '../../utils';
import { WebClient } from '@slack/web-api';
import { deploymentConfig } from '../../deployment-config';

export const ConversationManager = (options = {}) => new ConversationManager0(options);

export class ConversationManager0 extends BaseLoader {
  constructor(options) {
    super(options);
    this.collectionRef = db.collection("Element__conversations");

    this.list = []; // todo: think more about a shared base this.data vs definiting for leaf class
    this.obj = {};
    // two modes for this loader, one fetching by episode and the other by user
    this.episodeKey = options.episode;
    this.assignee = options.assignee; // user.id
    this.participant = options.participant; // user.id
    this.stateVersion = 1;
    makeObservable(this, {
      list: observable.ref,
      obj: observable.ref,
      stateVersion: observable.ref,
    });
  }

  loadEpisode(key, listenMode=true) {
    this.episodeKey = key;
    this.listenMode = listenMode;
    this.load();
  }

  // raw: true -> return null if no conversation record yet or not yet initialized
  //      false -> returns placeholder empty record if missing or not yet initialized
  getConversation(elementId, raw = false, episodeKey = this.episodeKey) {
    const result = this.list.find( data => data.episodeKey === episodeKey && data.elementId === elementId );
    if (!raw && isNil(result)) {
      return {comments: {}, commentList: []}
    }
    return result;
  }

  // private
  buildDocId({episodeKey, elementId}) {
    // todo: give more thought to doc id's
    return `${episodeKey}-${elementId}`;
  }

  // private
  docRef({episodeKey, elementId}) {
    const docId = this.buildDocId({episodeKey, elementId});
    return this.collectionRef.doc(docId);
  }

  // async createConversation(conversationData) {
  //   console.log(`createConversation: ${JSON.stringify(conversationData)}`);
  //   const docId = this.buildDocId(conversationData);

  //   const {episodeKey, elementId, participants = [], assignee = null, resolved = false, comments = {}} = conversationData;
  //   const timestamp = epochSecondsFloat();
  //   const createData = {id: docId, episodeKey, elementId, participants, assignee, resolved, comments, timestamp};

  //   const docRef = this.collectionRef.doc(docId);
  //   await docRef.set(createData);
  // }

  async deleteConversation(elementId, episodeKey = this.episodeKey) {
    const docId = this.buildDocId({episodeKey, elementId});
    const docRef = this.collectionRef.doc(docId);
    await docRef.delete();
  }

  async postComment(elementId, author, content, episodeKey = this.episodeKey) {
    // console.log(`postComment: ${JSON.stringify({episodeKey, elementId, author, content})}`);
    const commentId = `COMMENT:${randomString(12)}`; // todo: someday revisit guid strategy?

    const timestamp = epochSecondsFloat();
    const commentData = {id: commentId, author, content, timestamp};

    const resolved = content.includes('/resolve') || content.includes('(resolved)'); // hack to test logic

    const docId = this.buildDocId({episodeKey, elementId});
    const conversationData = {id: docId, episodeKey, elementId, comments: {[commentId]: commentData}, resolved, timestamp};

    // note, this only matches against current query.
    // desired handling of resolved conversations needs product level input.
    // currently they will have createdAt and assignee reset, but retain prior comments.
    const existingConversation = this.getConversation({episodeKey, elementId, raw: true});
    if (isNil(existingConversation)) {
      conversationData.assignee = null;
    }

    const joinedParticipants = [author];

    // todo: parsing and validation needs to be much more robust here
    if (content.includes('@')) {
      const words = content.split(' ');
      const match = words.find(word => word.includes('@'));
      const mentioned = match.replace('@', '');

      if (isEmpty(mentioned)) {
        conversationData.assignee = null;
      } else {
        joinedParticipants.push(mentioned);
        conversationData.assignee = mentioned;
      }
    }
    conversationData.participants = fieldValueArrayUnion(...joinedParticipants);

    const docRef = this.docRef({episodeKey, elementId});
    await docRef.set(conversationData, {merge: true});

    const linkPath = `/episodes/${episodeKey}/${elementId}`;
    const threadId = await this.notifySlack({threadId: existingConversation?.slackThreadId, topic: docId, linkPath, author, content});
    if (!existingConversation?.slackThreadId) {
      await docRef.update({slackThreadId: threadId});
    }
  }

  async updateComment(elementId, commentId, content, episodeKey = this.episodeKey) {
    const contentPath = `comments.${commentId}.content`;
    const timestampPath = `comments.${commentId}.timestamp`;
    const editedPath = `comments.${commentId}.edited`;
    const docRef = this.docRef({episodeKey, elementId});
    await docRef.update({[contentPath]: content, [editedPath]: true, [timestampPath]: epochSecondsFloat()});
  }

  async deleteComment(elementId, commentId, episodeKey = this.episodeKey) {
    console.log(`deleteComment: ek: ${episodeKey}, ei: ${elementId}, ci: ${commentId}`);
    const timestamp = epochSecondsFloat();
    const commentPath = `comments.${commentId}`;
    const docRef = this.docRef({episodeKey, elementId});
    await docRef.update({[commentPath]: fieldValueDelete(), timestamp});
  }

  /** @return CollectionReference or DocumentReference of data to be loaded */
  loadReference(options) {
    console.log(`ConversationManager.loadReference: episode: ${this.episodeKey}, participant: ${this.participant}, assignee: ${this.assignee}`);
    const baseQuery = this.collectionRef.where("resolved", "==", false);
    if (this.episodeKey) {
      return baseQuery.where("episodeKey", "==", this.episodeKey);
    } else {
      if (this.assignee) {
        return baseQuery.where("assignee", "==", this.assignee);
      } else {
        if (this.participant) {
          return baseQuery.where("participants", "array-contains", this.participant);
        } else {
          // throw Error("ConversationsManager filter scope expected")
          return this.collectionRef.where("id", "==", "_none_");
        }
      }
    }
  }

  /**
  * @snapshot QuerySnapshot or DocumentSnapshot
  * @return model data
  */
  snapshotToData(snapshot) {
    // todo: at some point this will deserve some optimization
    const result = [];
    const objResult = {};
    snapshot.forEach(documentSnapshot => {
      const data = documentSnapshot.data();
      data.commentList = data.comments ? Object.values(data.comments).sort((a,b) => a.timestamp - b.timestamp) : [];
      // denormalized data here makes deleting easier
      data.commentList.forEach(comment => {
        comment.episodeKey = data.episodeKey;
        comment.elementId = data.elementId;
      })
      data.withMessages = true; // drives thread marker UI; we can assume true if included here

      objResult[data.elementId] = data;
      result.push(data);
    });
    this.list = result;
    this.obj = objResult;
    this.stateVersion++;
    return result;
  }

  getStateVersion() {
    return this.status + '_' + this.stateVersion;
  }

  // private
  async notifySlack({threadId, topic, linkPath, author, content}) {
    const baseUrl = deploymentConfig.scriptEditorUrl;
    const channel = deploymentConfig.slackConversationsChannel;
    const web = new WebClient(deploymentConfig.slackApiKey);
    var message = `_${author}_: ${content}`;
    if (!threadId) {
      const link = `<${baseUrl}${linkPath}|${topic}>`;
      message = `${link}\n${message}`;
    }

    const result = await web.chat.postMessage({text: message, channel, thread_ts: threadId, unfurl_links: false});
    // console.log(`slack result: ${JSON.stringify(result)}`);
    const resultThreadId = result.ts;
    return resultThreadId;
  }
}
