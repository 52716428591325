import DiffMatchPatch, {
  DIFF_DELETE,
  DIFF_EQUAL,
  DIFF_INSERT
} from "diff-match-patch";

import {
  copyMapping,
  recordInsert,
  recordRemove,
  recordSwapsert,
  SHIFTED_BY_DELETE_FLAG,
} from "./positional-ids.fs.js";

export function patchFromDiff(
  offset,
  oldContent,
  oldIndexMapping,
  newContent,
  diffList,
) {
  let position = 0;
  const newIndexMapping = copyMapping(oldIndexMapping);
  let insertCount = 0;
  let removeCount = 0;

  const matchingReplaceOp = (op, index) => {
    if (index >= diffList.length) {
      return false;
    }
    const nextDiff = diffList[index];
    if (op !== nextDiff[0] || nextDiff[1].length !== 1) {
      return false;
    }
    return true;
  }

  // TODO make last was equal a test in matchingReplace op instead?
  let lastWasEqual = true;
  let index = 0;
  while (index < diffList.length) {
    const diff = diffList[index];
    const diffOp = diff[0];
    const len = diff[1].length;

    if (diffOp === DIFF_EQUAL) {
      lastWasEqual = true;
      position += len;
    }

    if (diffOp === DIFF_INSERT) {
      if (len === 1 && lastWasEqual && matchingReplaceOp(DIFF_DELETE, index + 1)) {
        index += 2;
        lastWasEqual = false;
        continue;
      }
    lastWasEqual = false;
    for (let i = 0; i < len; i++) {
        const pos = position + i + offset;
        if (pos === 0) {
          // special case for insert at first index
          recordSwapsert(newIndexMapping, pos);
        } else {
          recordInsert(newIndexMapping, pos);
        }
      }
      position += len;
      insertCount += len;
    }

    if (diffOp === DIFF_DELETE) {
      if (len === 1 && lastWasEqual && matchingReplaceOp(DIFF_INSERT, index + 1)) {
        index += 2;
        lastWasEqual = false;
        continue;
      }
      lastWasEqual = false;
      for (let i = 0; i < len; i++) {
        recordRemove(newIndexMapping, position + offset);
      }
      removeCount += len;
    }
    index += 1;
  }

  return { newIndexMapping, insertCount, removeCount };
}

export function doDiffAndPatch(
  offset,
  oldContent,
  oldIndexMapping,
  newContent,
) {
  const oldLineDelimited = oldContent.join("\n") + "\n";
  const newLineDelimited = newContent.join("\n") + "\n";

  const dmp = new DiffMatchPatch();
  const asCharCodes = dmp.diff_linesToChars_(
    oldLineDelimited,
    newLineDelimited
  );
  const diffs = dmp.diff_main(asCharCodes.chars1, asCharCodes.chars2, false);
  return patchFromDiff(
    offset,
    oldContent,
    oldIndexMapping,
    newContent,
    diffs,
  );
}

/**
export function getDeleteCount(positionalMapping) {
  let deleteCount = 0;
  forEachPositionalId(positionalMapping, id => {
    if (positionalMapping.positions[id] & SHIFTED_BY_DELETE_FLAG) {
      deleteCount++;
    }
  });
  return deleteCount;
}

export function scanMaxPosition(positionalMapping) {
  let maxPosition = 0;
  const positions = positionalMapping.positions;
  forEachPositionalId(positionalMapping, id => {
    if (!(positions[id] & SHIFTED_BY_DELETE_FLAG)) {
      const position = positions[id];
      maxPosition = position > maxPosition ? position : maxPosition;
    }
  });
  return maxPosition;
}
 **/
