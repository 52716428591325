import { makeObservable, observable } from 'mobx';
import { loaderStatus } from './constants';

const {
  NOT_INITIATED,
  IN_PROGRESS,
  COMPLETE,
  // ERROR_NOT_LOADABLE,
} = loaderStatus;

export class BaseLoader {
  constructor({listenMode = true} = {}) {
    this.listenMode = listenMode;
    this.status = NOT_INITIATED;
    this.disposers = [];
    this.data = [];
    makeObservable(this, {
      data: observable.ref,
      status: observable.ref,
    })
  }

  // expected to be overridden for filtered lists
  get model() {
    return this.data;
  }

  load(options) {
    this.status = IN_PROGRESS;
    this.close();

    this.disposers.push(this.onSnapshot(this.loadReference(options), result => {
      // todo: error handling
      this.data = this.snapshotToData(result);
      this.status = COMPLETE;
      console.log(`status = COMPLETE`);
    }));
  }

  /**
  * @return CollectionReference or DocumentReference of data to be loaded
  */
  loadReference(db, options) {
    throw Error("Abstract method unimplemented");
  }

  /**
  * @result QuerySnapshot or DocumentSnapshot
  * @return raw model data, now assumed to be an []
  */
  snapshotToData(result) {
    throw Error("Abstract method unimplemented");
  }

  get ready() {
    return this.status === COMPLETE;
  }

  onSnapshot(firestoreReference, callback) {
    // console.log(`BaseLoader.onSnapshot - listenMode: ${JSON.stringify(this.listenMode)}`);
    // TODO wrap callback in error handling
    if (this.listenMode) {
      return firestoreReference.onSnapshot(callback);
    } else {
      firestoreReference.get().then(callback);
      return () => null;
    }
  }

  close() {
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }
}
