import { class_type } from "../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { JSObject, Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505 } from "../basic-types.fs.js";

export const DEACTIVATED = "DEACTIVATED";

export class KeyboardModes0 {
    constructor() {
        this.modes = {};
        this.currentMode = (null);
        this.kbDeactivated = false;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    addMode(key, mode) {
        return KeyboardModes0__addMode_Z224AEBC7(this, key, mode)
    }
    setKbDeactivated(value) {
        return KeyboardModes0__setKbDeactivated_Z1FBCCD16(this, value)
    }
    setMode(key) {
        return KeyboardModes0__setMode_Z721C83C5(this, key)
    }
    stopListening() {
        return KeyboardModes0__stopListening(this)
    }
}

export function KeyboardModes0$reflection() {
    return class_type("KeyboardModes.KeyboardModes0", void 0, KeyboardModes0);
}

export function KeyboardModes0_$ctor() {
    return new KeyboardModes0();
}

function KeyboardModes0__addMode_Z224AEBC7(this$, key, mode) {
    const oldMode = this$.modes[key];
    if (oldMode ? (!(oldMode === mode)) : false) {
        oldMode.stopListening();
    }
    this$.modes[key]=mode;
}

function KeyboardModes0__stopListening(this$) {
    const arr = Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.modes);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const mode0 = arr[idx];
        const mode = mode0;
        mode.stopListening();
    }
}

function KeyboardModes0__adjustKbHandlingForMode(this$) {
    KeyboardModes0__stopListening(this$);
    if (this$.currentMode ? (!this$.kbDeactivated) : false) {
        const mode = (this$.modes[this$.currentMode]);
        mode.startListening();
    }
}

function KeyboardModes0__setKbDeactivated_Z1FBCCD16(this$, value) {
    this$.kbDeactivated = value;
    KeyboardModes0__adjustKbHandlingForMode(this$);
}

function KeyboardModes0__setMode_Z721C83C5(this$, key) {
    this$.currentMode = key;
    KeyboardModes0__adjustKbHandlingForMode(this$);
}

export function KeyboardModes() {
    return KeyboardModes0_$ctor();
}

// JS BOILERPLATE GENERATED
 