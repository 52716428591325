import { ScriptLoader } from "./script-loader.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../promise/promise.fs.js";
import { promise } from "../../promise/promise-impl.fs.js";
import { metadataOrder, ContentRootsBase } from "../content-roots/content-roots.fs.js";
import { runInAction } from "mobx";
import { System_Array__$005B$005D$1_extend_5975E3 } from "../../basic-types.fs.js";
import { sortElements } from "../../elements/element-sort.fs.js";
import { append, sortBy } from "../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { comparePrimitives } from "../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { ElementList } from "../../elements/element-list.fs.js";
import { ChaatLoader } from "./chaat-loader.js";

export function loadScriptContentRoots(episodeKey) {
    const loader = new ScriptLoader();
    loader.loadEpisode(episodeKey, false);
    const loaded = loader.promiseWhenComplete();
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (loaded.then(((_arg1) => {
        const doneLoading = _arg1 | 0;
        const roots = ContentRootsBase();
        runInAction(() => {
            roots.episodeKey = episodeKey;
            loader.docSet.copyTo(roots);
        });
        return Promise.resolve(roots);
    })))));
}

export function basicScriptElementsFromContentRoots(roots) {
    const contentElements = [];
    System_Array__$005B$005D$1_extend_5975E3(contentElements, roots.sentences0);
    System_Array__$005B$005D$1_extend_5975E3(contentElements, roots.structural0);
    System_Array__$005B$005D$1_extend_5975E3(contentElements, roots.wordGroups0);
    sortElements(contentElements);
    const metadataToOrderFunc0 = metadataOrder.functions.precedenceProjection;
    const metadataToOrderFunc = (m) => metadataToOrderFunc0(m.subKind);
    const metadata = sortBy(metadataToOrderFunc, roots.metadataBlocks0, {
        Compare: comparePrimitives,
    });
    const result = append(metadata, contentElements);
    return ElementList(result, roots.episodeKey, null, roots.words0, null, null, null);
}

export function loadScriptContent(episodeKey) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (loadScriptContentRoots(episodeKey).then(((_arg1) => {
        const roots = _arg1;
        return Promise.resolve(basicScriptElementsFromContentRoots(roots));
    })))));
}

export function loadChaatRoots(episodeKey) {
    const loader = new ChaatLoader();
    loader.loadEpisode(episodeKey, false);
    const loaded = loader.promiseWhenComplete();
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (loaded.then(((_arg1) => {
        const doneLoading = _arg1 | 0;
        const roots = ContentRootsBase();
        runInAction(() => {
            roots.episodeKey = episodeKey;
            loader.docSet.copyTo(roots);
        });
        return Promise.resolve(roots);
    })))));
}

