import {MutationActions0} from "./masala-lib/editorial/db/mutation-actions.js";
import {auth, alertMessages} from "./app-root.fs.js";


export class MutationActions extends MutationActions0 {
  constructor() {
    super();
  }

  get auth() {
    return auth;
  }

  get alerts() {
    return alertMessages;
  }
}
