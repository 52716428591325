import { openWordGroupInspectorDialog as openWordGroupInspectorDialog_1 } from "./word-group-inspector/word-group-inspector-dialog.js";
import { openFiltersDialog as openFiltersDialog_1 } from "./filters-dialog.js";
import { replace } from "./.fable/fable-library.3.1.0-beta-001/RegExp.js";
import { interpolate, toText } from "./.fable/fable-library.3.1.0-beta-001/String.js";
import { renderView } from "./script-editor-view.js";
import { FSharpRef } from "./.fable/fable-library.3.1.0-beta-001/Types.js";
import { alertMessages, keyboardModes, scriptEditorModel } from "./app-root.fs.js";
import { StyleLayer, StyleLayersRenderer } from "./masala-lib/editorial/ui/style-painting/style-layers.fs.js";
import { System_Array__$005B$005D$1_get_lastIndex, getKindFromId, domIdToElementId, System_Array__$005B$005D$1_append_1505 } from "./masala-lib/basic-types.fs.js";
import { reaction } from "mobx";
import { getVisibility, scrollIfNotVisible } from "./masala-lib/editorial/ui/dom-scroll.fs.js";
import { Component } from "react";
import { class_type, obj_type } from "./.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { EDITING, LOCKED, NORMAL, CHOICE_MODE, DEACTIVATED } from "./script-editor-model.fs.js";
import { WORD_GROUP, PARAGRAPH_BREAK, SPEAKER_LABEL, PASSAGE_HINT, SIC, TRICKY, VOCAB, SENTENCE, WORD, CHAPTER_TITLE } from "./masala-lib/elements/element-types.fs.js";
import { equals } from "./.fable/fable-library.3.1.0-beta-001/Util.js";
import { AlertMessage, Alert } from "./masala-lib/alert-messages.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./masala-lib/promise/promise.fs.js";
import { promise } from "./masala-lib/promise/promise-impl.fs.js";
import { swalPromptYN } from "./masala-lib/bindings/sweetalert.fs.js";
import { test1 } from "./test.js";
import keyboardist from "keyboardist";
import { openVersionsDialog } from "./versions-dialog.js";
import { ElementList, SimpleElementList } from "./masala-lib/elements/element-list.fs.js";
import { makeAdhocWordRange } from "./masala-lib/elements/adhoc-word-range.fs.js";
import lodash from "lodash";
import { observer } from "mobx-react";

export const openWordGroupInspectorDialog = openWordGroupInspectorDialog_1;

export const openFiltersDialog = openFiltersDialog_1;

export function createChoice(text) {
    const sub = (pattern, replacement, str) => replace(str, pattern, replacement);
    const highlight = (str_1) => sub("@(.)", "\u003cstrong\u003e\u003cu\u003e$1\u003c/u\u003e\u003c/strong\u003e", str_1);
    return toText(interpolate("\u003cdiv\u003e%P()\u003c/div\u003e", [highlight(text)]));
}

export class ScriptEditor0 extends Component {
    constructor(initialProps) {
        super(initialProps);
        let clo1, clo1_1, clo1_2, clo1_3;
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = (s0.contents);
        this.model = scriptEditorModel;
        this.activeLineEditor = (null);
        this.lastClickTime = 0;
        this.currentChoiceMode = (null);
        this.disposers = [];
        this.VERBATIM_OPS = "VERBATIM_OPS";
        this.WORD_GROUP_CREATE = "WORD_GROUP_CREATE";
        this.STRUCTURAL_CREATE = "STRUCTURAL_CREATE";
        this.METADATA_BLOCK_OPS = "METADATA_BLOCK_OPS";
        this.quantizedScrollPos = 0;
        this.styleLayersRenderer = StyleLayersRenderer();
        this["init@48-23"] = 1;
        ScriptEditor0__initializeInputHandlers(this);
        this.styleLayers$Xqj7fiKBK29z = null;
        makeObservable(this, {
            quantizedScrollPos: observable.ref,
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
        this.model.setGetLineEditBuffer(() => (this.activeLineEditor.getContentFromEditor()));
        System_Array__$005B$005D$1_append_1505(this.disposers, (clo1 = reaction((() => ScriptEditor0__styleLayers(this)), () => {
            ScriptEditor0__renderStyleLayers(this);
        }, {}), () => {
            clo1();
        }));
        System_Array__$005B$005D$1_append_1505(this.disposers, (clo1_1 = reaction((() => this.model.mode), () => {
            ScriptEditor0__adjustInputHandlingForMode(this);
        }, {}), () => {
            clo1_1();
        }));
        System_Array__$005B$005D$1_append_1505(this.disposers, (clo1_2 = reaction((() => this.model.focusedLineId), () => {
            scrollIfNotVisible(this.model.focusedLineId, "nearest", "editorId");
        }, {}), () => {
            clo1_2();
        }));
        System_Array__$005B$005D$1_append_1505(this.disposers, (clo1_3 = reaction((() => this.quantizedScrollPos), () => {
            ScriptEditor0__adjustCurrentChapterForScrollPos(this);
        }, {}), () => {
            clo1_3();
        }));
    }
    handleScroll(event) {
        return ScriptEditor0__handleScroll_Z5B3E8D2(this, event)
    }
    handleLineClick(event, domId) {
        return ScriptEditor0__handleLineClick_434A1DD4(this, event, domId)
    }
    setActiveLineEditor(lineEditor, isActive) {
        return ScriptEditor0__setActiveLineEditor_Z5C18B6F(this, lineEditor, isActive)
    }
    componentWillUnmount() {
        const _ = this;
        const arr = _.disposers;
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const disposer = arr[idx];
            disposer();
        }
    }
    render() {
        const _ = this;
        return renderView(_.self);
    }
}

export function ScriptEditor0$reflection() {
    return class_type("ScriptEditor.ScriptEditor0", void 0, ScriptEditor0, class_type("Fable.React.Component`2", [obj_type, obj_type]));
}

export function ScriptEditor0_$ctor_4E60E31B(initialProps) {
    return new ScriptEditor0(initialProps);
}

function ScriptEditor0__saveLineEdit(this$) {
}

function ScriptEditor0__adjustInputHandlingForMode(this$) {
    if (this$.model.mode === DEACTIVATED) {
        keyboardModes.setKbDeactivated(true);
    }
    else {
        keyboardModes.setKbDeactivated(false);
    }
    if (this$.model.mode === CHOICE_MODE) {
        keyboardModes.setMode(this$.currentChoiceMode);
    }
    else if (this$.model.mode !== DEACTIVATED) {
        keyboardModes.setMode(this$.model.mode);
    }
}

function ScriptEditor0__handleKbFocusChange(this$) {
}

function ScriptEditor0__exitChoiceModalInputHandling(this$) {
    this$.model.setChoiceModalMode(null);
}

function ScriptEditor0__handleKBModalChoiceMade(this$) {
    ScriptEditor0__exitChoiceModalInputHandling(this$);
}

function ScriptEditor0__enterWordGroupCreateChoiceMode(this$) {
    this$.currentChoiceMode = this$.WORD_GROUP_CREATE;
    let html = "";
    const addChoice = (choiceText) => {
        html = (html + createChoice(choiceText));
    };
    addChoice("@Vocab");
    addChoice("@Tricky");
    this$.model.setChoiceModalMode(html);
}

function ScriptEditor0__enterStructuralCreateChoiceMode(this$) {
    this$.currentChoiceMode = this$.STRUCTURAL_CREATE;
    let html = "";
    const addChoice = (choiceText) => {
        html = (html + createChoice(choiceText));
    };
    addChoice("@Chapter");
    addChoice("@Passage");
    addChoice("@Speaker Label");
    addChoice("Paragraph @Break");
    this$.model.setChoiceModalMode(html);
}

function ScriptEditor0__enterVerbatimOpsChoiceMode(this$) {
    this$.currentChoiceMode = this$.VERBATIM_OPS;
    let html = "";
    const addChoice = (choiceText) => {
        html = (html + createChoice(choiceText));
    };
    addChoice("Split and create new sentence @above");
    addChoice("Split and create new sentence @below");
    this$.model.setChoiceModalMode(html);
}

function ScriptEditor0__enterMetadataBlockOpsChoiceMode(this$) {
    this$.currentChoiceMode = this$.METADATA_BLOCK_OPS;
    let html = "";
    const addChoice = (choiceText) => {
        html = (html + createChoice(choiceText));
    };
    addChoice("Add metadata @URL block");
    addChoice("@Copy metadata blocks to clipboard");
    this$.model.setChoiceModalMode(html);
}

function ScriptEditor0__handleScroll_Z5B3E8D2(this$, event) {
    this$.quantizedScrollPos = (event.target.scrollTop % 20);
}

function ScriptEditor0__adjustCurrentChapterForScrollPos(this$) {
    let chapter = null;
    const chapters = this$.model.elements.getKindSubList(CHAPTER_TITLE);
    const arr = chapters.elements;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const element = arr[idx];
        const placement = getVisibility(element.id, "editorId") | 0;
        if (placement === 1) {
            break;
        }
        chapter = element;
    }
    if (chapter ? chapter.content : false) {
        this$.model.currentChapterTitle = (chapter.content);
    }
    else {
        this$.model.currentChapterTitle = "";
    }
}

function ScriptEditor0__handleWordClick_434A1DD4(this$, event, id) {
    if (event.getModifierState("Shift")) {
        this$.model.wordRangeSelectTo(id);
    }
    else {
        this$.model.setCurrentWordId(id);
    }
}

function ScriptEditor0__handleLineDoubleClick_Z31568B16(this$, event, domId) {
    this$.model.editFocusedLine();
}

function ScriptEditor0__handleLineClick_434A1DD4(this$, event, domId) {
    const currentTime = Date.now();
    if (currentTime < (this$.lastClickTime + 500)) {
        this$.lastClickTime = 0;
        const tupledArg = [event, domId];
        ScriptEditor0__handleLineDoubleClick_Z31568B16(this$, tupledArg[0], tupledArg[1]);
    }
    else {
        this$.lastClickTime = currentTime;
    }
    const id = domIdToElementId(null, domId);
    const kind = getKindFromId(id);
    if (kind === WORD) {
        ScriptEditor0__handleWordClick_434A1DD4(this$, event, id);
    }
    else {
        this$.model.setFocusedLineId(event.currentTarget.id);
    }
}

function ScriptEditor0__getLineStylesString_1505(this$, id) {
    return "styles";
}

function ScriptEditor0__setActiveLineEditor_Z5C18B6F(this$, lineEditor, isActive) {
    if (isActive) {
        this$.activeLineEditor = lineEditor;
    }
    else if (equals(this$.activeLineEditor, lineEditor)) {
        this$.activeLineEditor = null;
    }
}

function ScriptEditor0__toggleCollapseExpand(this$) {
    this$.model.toggleCollapseExpand();
    setTimeout(() => {
        scrollIfNotVisible(this$.model.focusedLineId, "center", "editorId");
    }, 100);
}

function ScriptEditor0__handleEnterInNormalMode(this$) {
    if (this$.model.collapse) {
        ScriptEditor0__toggleCollapseExpand(this$);
    }
    else {
        const focusedLine = this$.model.focusedLineElement;
        if (focusedLine ? (focusedLine.kind === SENTENCE) : false) {
            alertMessages.add(new AlertMessage("need to use Ctrl or Cmd Enter to edit verbatim", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
        }
        else {
            this$.model.editFocusedLine();
        }
    }
}

function ScriptEditor0__handleEnterToEditSentence(this$) {
    this$.model.editFocusedLine();
}

function ScriptEditor0__abortLineEdit(this$) {
    if (this$.activeLineEditor) {
        this$.activeLineEditor.revert();
        this$.model.abortLineEdit();
    }
}

function ScriptEditor0__handleEnterInEdit(this$) {
    if (this$.activeLineEditor.handleEnter) {
        const handled = this$.activeLineEditor.handleEnter((null));
        if (!handled) {
            this$.model.saveLineEdit();
        }
    }
    else {
        this$.model.saveLineEdit();
    }
}

function ScriptEditor0__removeFocused(this$) {
    const focused = this$.model.focusedElement;
    if (focused ? (focused.kind === SENTENCE) : false) {
        PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const sentenceNum = (this$.model.sentences.getIndex(focused.id) + 1) | 0;
            return swalPromptYN({
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Delete Sentence",
                html: toText(interpolate("Do you really want to delete sentence number %P()", [sentenceNum])),
                icon: "warning",
                showCancelButton: true,
                title: "Delete Sentence?",
            }).then(((_arg1) => {
                const gohead = _arg1;
                if (gohead) {
                    this$.model.removeFocused();
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            }));
        }));
    }
    else {
        this$.model.removeFocused();
    }
}

function ScriptEditor0__goToFirst(this$) {
    const firstElementId = this$.model.lineElements.elements[0].id;
    this$.model.setFocusedLineId(firstElementId);
}

function ScriptEditor0__goToLast(this$) {
    const elements = this$.model.lineElements.elements;
    const lastElementId = elements[System_Array__$005B$005D$1_get_lastIndex(elements)].id;
    this$.model.setFocusedLineId(lastElementId);
}

function ScriptEditor0__testingHook(this$) {
    test1();
}

function ScriptEditor0__initializeInputHandlers(this$) {
    let handler = keyboardist();
    handler.subscribe("Escape", () => {
        this$.model.cancelSelections();
    });
    handler.subscribe("KeyJ", () => {
        this$.model.cursorLineDown();
    });
    handler.subscribe("KeyK", () => {
        this$.model.cursorLineUp();
    });
    handler.subscribe("Right", () => {
        this$.model.nextSearchResult();
    });
    handler.subscribe("Left", () => {
        this$.model.prevSearchResult();
    });
    handler.subscribe("KeyW", () => {
        ScriptEditor0__enterWordGroupCreateChoiceMode(this$);
    });
    handler.subscribe("KeyS", () => {
        ScriptEditor0__enterStructuralCreateChoiceMode(this$);
    });
    handler.subscribe("Shift+KeyM", () => {
        ScriptEditor0__enterMetadataBlockOpsChoiceMode(this$);
    });
    handler.subscribe("KeyV", () => {
        ScriptEditor0__enterVerbatimOpsChoiceMode(this$);
    });
    handler.subscribe("KeyI", () => {
        this$.model.setFocusedWarningSuppression(true);
    });
    handler.subscribe("Shift+KeyI", () => {
        this$.model.setFocusedWarningSuppression(false);
    });
    handler.subscribe("KeyX", () => {
        ScriptEditor0__toggleCollapseExpand(this$);
    });
    handler.subscribe("KeyM", () => {
        this$.model.markFocusedLine();
    });
    handler.subscribe("Ctrl+KeyD", () => {
        ScriptEditor0__removeFocused(this$);
    });
    handler.subscribe("Ctrl+KeyM", () => {
        this$.model.moveMarked();
    });
    handler.subscribe("KeyT", () => {
        this$.model.createOrEditTranslationAtFocusedLine();
    });
    handler.subscribe("Enter", () => {
        ScriptEditor0__handleEnterInNormalMode(this$);
    });
    handler.subscribe("Meta+Enter", () => {
        ScriptEditor0__handleEnterToEditSentence(this$);
    });
    handler.subscribe("Ctrl+Enter", () => {
        ScriptEditor0__handleEnterToEditSentence(this$);
    });
    handler.subscribe("KeyF", () => {
        openFiltersDialog(true);
    });
    handler.subscribe("Shift+KeyF", () => {
        openFiltersDialog(false);
    });
    handler.subscribe("KeyH", () => {
        openVersionsDialog();
    });
    handler.subscribe("KeyU", () => {
        this$.model.copySharableElementLinkToClipboard();
    });
    handler.subscribe("Digit0", () => {
        ScriptEditor0__goToFirst(this$);
    });
    handler.subscribe("Shift+Digit0", () => {
        ScriptEditor0__goToLast(this$);
    });
    handler.subscribe("Digit1", () => {
        openWordGroupInspectorDialog();
    });
    handler.subscribe("Digit2", () => {
        ScriptEditor0__testingHook(this$);
    });
    keyboardModes.addMode(NORMAL, handler);
    handler = keyboardist();
    handler.subscribe("Escape", () => {
        this$.model.cancelSelections();
    });
    handler.subscribe("KeyJ", () => {
        this$.model.cursorLineDown();
    });
    handler.subscribe("KeyK", () => {
        this$.model.cursorLineUp();
    });
    handler.subscribe("Right", () => {
        this$.model.nextSearchResult();
    });
    handler.subscribe("Left", () => {
        this$.model.prevSearchResult();
    });
    handler.subscribe("KeyI", () => {
        this$.model.setFocusedWarningSuppression(true);
    });
    handler.subscribe("Shift+KeyI", () => {
        this$.model.setFocusedWarningSuppression(false);
    });
    handler.subscribe("KeyX", () => {
        ScriptEditor0__toggleCollapseExpand(this$);
    });
    handler.subscribe("KeyF", () => {
        openFiltersDialog(true);
    });
    handler.subscribe("Shift+KeyF", () => {
        openFiltersDialog(false);
    });
    handler.subscribe("KeyH", () => {
        openVersionsDialog();
    });
    handler.subscribe("KeyU", () => {
        this$.model.copySharableElementLinkToClipboard();
    });
    handler.subscribe("Digit0", () => {
        ScriptEditor0__goToFirst(this$);
    });
    handler.subscribe("Shift+Digit0", () => {
        ScriptEditor0__goToLast(this$);
    });
    handler.subscribe("Digit1", () => {
        openWordGroupInspectorDialog();
    });
    handler.stopListening();
    keyboardModes.addMode(LOCKED, handler);
    handler = keyboardist();
    handler.subscribe("Ctrl+KeyS", () => {
        this$.model.saveLineEdit();
    });
    handler.subscribe("Escape", () => {
        ScriptEditor0__abortLineEdit(this$);
    });
    handler.subscribe("Enter", () => {
        ScriptEditor0__handleEnterInEdit(this$);
    });
    handler.stopListening();
    keyboardModes.addMode(EDITING, handler);
    const defineKBChoice = (key, cb) => {
        handler.subscribe(key, () => {
            ScriptEditor0__handleKBModalChoiceMade(this$);
            cb();
        });
    };
    handler = keyboardist();
    defineKBChoice("KeyV", () => {
        this$.model.createWordGroupWithWordSelection(VOCAB);
    });
    defineKBChoice("KeyT", () => {
        this$.model.createWordGroupWithWordSelection(TRICKY);
    });
    defineKBChoice("KeyS", () => {
        this$.model.createWordGroupWithWordSelection(SIC);
    });
    handler.stopListening();
    keyboardModes.addMode(this$.WORD_GROUP_CREATE, handler);
    handler = keyboardist();
    defineKBChoice("KeyC", () => {
        this$.model.createStucturalAtFocusedLine(CHAPTER_TITLE);
    });
    defineKBChoice("KeyP", () => {
        this$.model.createStucturalAtFocusedLine(PASSAGE_HINT);
    });
    defineKBChoice("KeyS", () => {
        this$.model.createStucturalAtFocusedLine(SPEAKER_LABEL);
    });
    defineKBChoice("KeyB", () => {
        this$.model.createStucturalAtFocusedLine(PARAGRAPH_BREAK);
    });
    handler.stopListening();
    keyboardModes.addMode(this$.STRUCTURAL_CREATE, handler);
    handler = keyboardist();
    defineKBChoice("KeyU", () => {
        this$.model.createMetadataUrlBlock();
    });
    defineKBChoice("KeyC", () => {
        this$.model.copyMetadataToClipboard();
    });
    handler.stopListening();
    keyboardModes.addMode(this$.METADATA_BLOCK_OPS, handler);
    handler = keyboardist();
    defineKBChoice("KeyA", () => {
        this$.model.splitSentence(1);
    });
    defineKBChoice("KeyB", () => {
        this$.model.splitSentence(0);
    });
    handler.stopListening();
    keyboardModes.addMode(this$.VERBATIM_OPS, handler);
    System_Array__$005B$005D$1_append_1505(this$.disposers, () => {
        keyboardModes.stopListening();
    });
}

function ScriptEditor0__focusedLineStyleLayer(this$) {
    const els = this$.model.focusedLineElement ? [this$.model.focusedLineElement] : [];
    return new StyleLayer(["focused-line"], SimpleElementList(els), "LINE", false, null);
}

function ScriptEditor0__markedLineStyleLayer(this$) {
    const els = this$.model.markedLineElement ? [this$.model.markedLineElement] : [];
    return new StyleLayer(["marked-line"], SimpleElementList(els), "LINE", false, null);
}

function ScriptEditor0__wordSelectionStyleLayer(this$) {
    const els = this$.model.wordRangeSelection ? [makeAdhocWordRange(this$.model.wordRangeSelection, this$.model.words)] : [];
    const elements = ElementList(els, null, null, this$.model.elements.words, null, null, null);
    return new StyleLayer(["selected"], elements, null, true, null);
}

function ScriptEditor0__hasActiveThreadStyleLayer(this$) {
    const els = this$.model.elements.elements.filter((el) => (((el.kind !== WORD_GROUP) ? el.thread.withMessages : false) ? (!el.thread.resolved) : false));
    return new StyleLayer(["has-discussion"], SimpleElementList(els), "LINE", false, null);
}

function ScriptEditor0__unfilledStyleLayer(this$) {
    const els = this$.model.elements.elements.filter((el) => {
        if (el.subKind === VOCAB) {
            const x = el.content.note;
            return lodash.isEmpty(x);
        }
        else {
            return false;
        }
    });
    const styles = ["unfilled"];
    const domScope = null;
    return new StyleLayer(styles, SimpleElementList(els), domScope, false, null);
}

function ScriptEditor0__hasValidatorWarningStyleLayer(this$) {
    return new StyleLayer(["has-validation-warning"], this$.model.validationWarnings, "LINE", false, null);
}

function ScriptEditor0__searchFilterStyleLayer(this$) {
    return new StyleLayer(["search-match"], this$.model.searchResult, null, false, null);
}

function ScriptEditor0__styleLayers(this$) {  if (!this$.styleLayers$Xqj7fiKBK29z) { this$.styleLayers$Xqj7fiKBK29z = computed(() => {
    const result = new Map();
    result.set("focusedLine", ScriptEditor0__focusedLineStyleLayer(this$));
    result.set("wordSelection", ScriptEditor0__wordSelectionStyleLayer(this$));
    result.set("markedLine", ScriptEditor0__markedLineStyleLayer(this$));
    result.set("hasDiscussion", ScriptEditor0__hasActiveThreadStyleLayer(this$));
    result.set("unfilled", ScriptEditor0__unfilledStyleLayer(this$));
    result.set("hasValidationWarning", ScriptEditor0__hasValidatorWarningStyleLayer(this$));
    return result.set("searchMatch", ScriptEditor0__searchFilterStyleLayer(this$));
})}; return this$.styleLayers$Xqj7fiKBK29z.get(); }

function ScriptEditor0__renderStyleLayers(this$) {
    this$.styleLayersRenderer.renderStyleLayers(this$.model.episodeKey, ScriptEditor0__styleLayers(this$));
}

export const ScriptEditor = (() => {
    return observer(ScriptEditor0);
})();

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 