import { mutationActions } from "../app-root.fs.js";
import { TranslationActions0$reflection, TranslationActions0 } from "../masala-lib/editorial/models/actions/translation-actions.fs.js";
import { class_type } from "../.fable/fable-library.3.1.0-beta-001/Reflection.js";

export class TranslationActions1 extends TranslationActions0 {
    constructor() {
        super();
        this["init@7-19"] = 1;
    }
    ["TranslationActions.TranslationActions0.get_mutationActions"]() {
        return mutationActions;
    }
}

export function TranslationActions1$reflection() {
    return class_type("ScriptEditorTranslationActions.TranslationActions1", void 0, TranslationActions1, TranslationActions0$reflection());
}

export function TranslationActions1_$ctor() {
    return new TranslationActions1();
}

export function TranslationActions() {
    return TranslationActions1_$ctor();
}

