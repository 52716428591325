import { FSharpRef } from "../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type } from "../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { interpolate, toText } from "../../../../.fable/fable-library.3.1.0-beta-001/String.js";
import { randomString } from "../../.././utils.js";
import { MetadataBlockDTO } from "../../../basic-types.fs.js";

export class MetadataBlockActions0 {
    constructor() {
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = s0.contents;
        this["init@14-3"] = 1;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    create(kind) {
        return MetadataBlockActions0__create_Z721C83C5(this, kind)
    }
    remove(id) {
        return MetadataBlockActions0__remove_Z721C83C5(this, id)
    }
    updateContent(id, content) {
        return MetadataBlockActions0__updateContent_Z384F8060(this, id, content)
    }
}

export function MetadataBlockActions0$reflection() {
    return class_type("MetadataBlockActions.MetadataBlockActions0", void 0, MetadataBlockActions0);
}

export function MetadataBlockActions0_$ctor() {
    return new MetadataBlockActions0();
}

function MetadataBlockActions0__create_Z721C83C5(this$, kind) {
    const newId = toText(interpolate("METADATA_BLOCK:%P()", [randomString(12)]));
    this$.self["MetadataBlockActions.MetadataBlockActions0.get_mutationActions"]().addUpdateMetadataBlock(new MetadataBlockDTO(kind, newId, ""));
    return newId;
}

function MetadataBlockActions0__updateContent_Z384F8060(this$, id, content) {
    this$.self["MetadataBlockActions.MetadataBlockActions0.get_mutationActions"]().updateContentMetadataBlock(id, content);
}

function MetadataBlockActions0__remove_Z721C83C5(this$, id) {
    this$.self["MetadataBlockActions.MetadataBlockActions0.get_mutationActions"]().removeMetadataBlock(id);
}

// JS BOILERPLATE GENERATED
 