import React from "react";
import { observer } from 'mobx-react';
import moment from 'moment';
import { ActionLink } from './action-link';
import { auth } from '../../../singletons'; // todo: clean this up

const TimelineItemFooter = ({item, children}) => {
  return (
    <div className="thread-item-attribution">
      <span>{item.author}</span>
      &nbsp;
      <span style={{float:'right'}}>
        {children}
        {moment.unix(item.timestamp).format('MMMM Do YYYY, h:mm:ss a')}
      </span>
    </div>
  );
};

const Comment = ({onClick, comment, onDelete, onEdit}) => {
  const isAdmin = auth.appUser?.isAdmin;

  const mayEdit = (comment) => {
    return isAdmin || auth.appUser?.id === comment.author
  };

  const message = comment.content.replace(/\n/g,"<br>");
  return (
    <div className="thread-item" onClick={onClick}>
      <div dangerouslySetInnerHTML={{ __html: message }}>
      </div>
      <TimelineItemFooter item={comment}>
        {comment.edited ? (
          <>
          (edited)
          &nbsp;
          </>
        ) : null}
        { mayEdit(comment) ? (
          <>
            <ActionLink onPress={() => onEdit(comment)}>
              [edit]
            </ActionLink>
            &nbsp;
            <ActionLink onPress={() => onDelete(comment)}>
              [delete]
            </ActionLink>
          </>
        ) : null}
      </TimelineItemFooter>
    </div>
  );
};

export const Conversation = observer(({conversation, onDelete, onEdit}) => {
  return (
    <div className="comment-thread">
      {conversation.assignee ? (
        <div className="comment-thread-assignee">
          Assigned to: {conversation.assignee}
        </div>
      ) : null}
      {conversation.commentList.map( (comment) =>
        <Comment key={comment.id} comment={comment} onDelete={onDelete} onEdit={onEdit}/>
      )}
    </div>
  );
});

export const VersionItem = ({onClick, className, item}) => {
  className = className || "";
  className = className + " thread-item"
  return (
    <div className={className} onClick={onClick}>
      <div>
        {item.content}
      </div>
      <TimelineItemFooter item={item} />
    </div>
  );
};

// export const VersionTimeline = ({items}) => {
//   return (
//     <div className="comment-thread">
//       {items.map( (item) =>
//         <VersionItem key={item.timestamp} item={item} onClick={}/>
//       )}
//     </div>
//   );
// };
