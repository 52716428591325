import { Record } from "../.fable/fable-library.3.1.0-beta-001/Types.js";
import { array_type, obj_type, float64_type, bool_type, string_type, record_type, int32_type } from "../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { parse } from "../.fable/fable-library.3.1.0-beta-001/Int32.js";

export function System_Array__$005B$005D$1_append_1505(x, item) {
    x.push(item);
}

export function System_Array__$005B$005D$1_extend_5975E3(x, items) {
    x.push(...items);
}

export function System_Array__$005B$005D$1_pop(x) {
    return x.pop();
}

export function System_Array__$005B$005D$1_popLeft(x) {
    return x.shift();
}

export function System_Array__$005B$005D$1_get_lastIndex(x) {
    return x.length - 1;
}

export function System_Array__$005B$005D$1_indexOf_1505(x, item) {
    return x.indexOf(item);
}

export function Fable_Core_JS_Map$2__Map$2_setDefault_2A0A0(x, key, default0) {
    const value = x.get(key);
    if (!value) {
        x.set(key, default0);
        return default0;
    }
    else {
        return value;
    }
}

export const JSObject = Object;

export function Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(x, o) {
    return Object.values(o);
}

export function Fable_Core_JS_ObjectConstructor__ObjectConstructor_entries_1505(x, o) {
    return Object.entries(o);
}

export class SpanAnchors extends Record {
    constructor(startWordId, endWordId) {
        super();
        this.startWordId = (startWordId | 0);
        this.endWordId = (endWordId | 0);
    }
}

export function SpanAnchors$reflection() {
    return record_type("BasicTypes.SpanAnchors", [], SpanAnchors, () => [["startWordId", int32_type], ["endWordId", int32_type]]);
}

export class SpanExclusiveAnchors extends Record {
    constructor(startWordId, endWordIdExclusive) {
        super();
        this.startWordId = (startWordId | 0);
        this.endWordIdExclusive = (endWordIdExclusive | 0);
    }
}

export function SpanExclusiveAnchors$reflection() {
    return record_type("BasicTypes.SpanExclusiveAnchors", [], SpanExclusiveAnchors, () => [["startWordId", int32_type], ["endWordIdExclusive", int32_type]]);
}

export class IndexRange extends Record {
    constructor(starts, ends) {
        super();
        this.starts = (starts | 0);
        this.ends = (ends | 0);
    }
}

export function IndexRange$reflection() {
    return record_type("BasicTypes.IndexRange", [], IndexRange, () => [["starts", int32_type], ["ends", int32_type]]);
}

export class IdRange extends Record {
    constructor(starts, ends) {
        super();
        this.starts = starts;
        this.ends = ends;
    }
}

export function IdRange$reflection() {
    return record_type("BasicTypes.IdRange", [], IdRange, () => [["starts", string_type], ["ends", string_type]]);
}

export class ChaatInputCue extends Record {
    constructor(wordId, timestamp) {
        super();
        this.wordId = wordId;
        this.timestamp = (timestamp | 0);
    }
}

export function ChaatInputCue$reflection() {
    return record_type("BasicTypes.ChaatInputCue", [], ChaatInputCue, () => [["wordId", string_type], ["timestamp", int32_type]]);
}

export class Cue extends Record {
    constructor(wordId, timestamp, input, navStop) {
        super();
        this.wordId = wordId;
        this.timestamp = (timestamp | 0);
        this.input = input;
        this.navStop = navStop;
    }
}

export function Cue$reflection() {
    return record_type("BasicTypes.Cue", [], Cue, () => [["wordId", string_type], ["timestamp", int32_type], ["input", bool_type], ["navStop", bool_type]]);
}

export class WordGroupContent extends Record {
    constructor(note, head) {
        super();
        this.note = note;
        this.head = head;
    }
}

export function WordGroupContent$reflection() {
    return record_type("BasicTypes.WordGroupContent", [], WordGroupContent, () => [["note", string_type], ["head", string_type]]);
}

export class SentenceDTO extends Record {
    constructor(id, anchors) {
        super();
        this.id = id;
        this.anchors = anchors;
    }
}

export function SentenceDTO$reflection() {
    return record_type("BasicTypes.SentenceDTO", [], SentenceDTO, () => [["id", string_type], ["anchors", SpanExclusiveAnchors$reflection()]]);
}

export class WordGroupDTO extends Record {
    constructor(id, subKind, anchors, content) {
        super();
        this.id = id;
        this.subKind = subKind;
        this.anchors = anchors;
        this.content = content;
    }
}

export function WordGroupDTO$reflection() {
    return record_type("BasicTypes.WordGroupDTO", [], WordGroupDTO, () => [["id", string_type], ["subKind", string_type], ["anchors", SpanAnchors$reflection()], ["content", WordGroupContent$reflection()]]);
}

export class StructuralDTO extends Record {
    constructor(kind, id, anchors, content) {
        super();
        this.kind = kind;
        this.id = id;
        this.anchors = (anchors | 0);
        this.content = content;
    }
}

export function StructuralDTO$reflection() {
    return record_type("BasicTypes.StructuralDTO", [], StructuralDTO, () => [["kind", string_type], ["id", string_type], ["anchors", int32_type], ["content", string_type]]);
}

export class MetadataBlockDTO extends Record {
    constructor(subKind, id, content) {
        super();
        this.subKind = subKind;
        this.id = id;
        this.content = content;
    }
}

export function MetadataBlockDTO$reflection() {
    return record_type("BasicTypes.MetadataBlockDTO", [], MetadataBlockDTO, () => [["subKind", string_type], ["id", string_type], ["content", string_type]]);
}

export class AudioRegionDTO extends Record {
    constructor(id, startTime, endTime) {
        super();
        this.id = id;
        this.startTime = (startTime | 0);
        this.endTime = (endTime | 0);
    }
}

export function AudioRegionDTO$reflection() {
    return record_type("BasicTypes.AudioRegionDTO", [], AudioRegionDTO, () => [["id", string_type], ["startTime", int32_type], ["endTime", int32_type]]);
}

export class AudioMarkerDTO extends Record {
    constructor(id, time) {
        super();
        this.id = id;
        this.time = (time | 0);
    }
}

export function AudioMarkerDTO$reflection() {
    return record_type("BasicTypes.AudioMarkerDTO", [], AudioMarkerDTO, () => [["id", string_type], ["time", int32_type]]);
}

export class CommentDTO extends Record {
    constructor(id, content, author, edited, timestamp) {
        super();
        this.id = id;
        this.content = content;
        this.author = author;
        this.edited = edited;
        this.timestamp = timestamp;
    }
}

export function CommentDTO$reflection() {
    return record_type("BasicTypes.CommentDTO", [], CommentDTO, () => [["id", string_type], ["content", string_type], ["author", string_type], ["edited", bool_type], ["timestamp", float64_type]]);
}

export class ConversationDTO extends Record {
    constructor(id, episodeKey, elementId, comments, participants, assignee, resolved, slackThreadId, timestamp) {
        super();
        this.id = id;
        this.episodeKey = episodeKey;
        this.elementId = elementId;
        this.comments = comments;
        this.participants = participants;
        this.assignee = assignee;
        this.resolved = resolved;
        this.slackThreadId = slackThreadId;
        this.timestamp = timestamp;
    }
}

export function ConversationDTO$reflection() {
    return record_type("BasicTypes.ConversationDTO", [], ConversationDTO, () => [["id", string_type], ["episodeKey", string_type], ["elementId", string_type], ["comments", obj_type], ["participants", array_type(string_type)], ["assignee", string_type], ["resolved", bool_type], ["slackThreadId", string_type], ["timestamp", float64_type]]);
}

export function getKindFromId(id) {
    if ((typeof id === 'number')) {
        return "WORD";
    }
    else {
        const stringId = id;
        return stringId.slice(void 0, (stringId.indexOf(":") - 1) + 1);
    }
}

export function elementIdToDomId(domScope, id) {
    if ((typeof id === 'number')) {
        return "WORD:" + id;
    }
    else {
        return id;
    }
}

export function domIdToElementId(domScope, domId) {
    if (domId.indexOf("WORD") === 0) {
        const wordIdStr = domId.slice(domId.indexOf(":") + 1, domId.length);
        return parse(wordIdStr, 511, false, 32);
    }
    else {
        return domId;
    }
}

