import React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { auth } from "../app-root.fs.js";
import { MainLayout } from '../containers/main-layout';
import '../App.css';

export const HomeScreen = observer(() => {
  return (
    <View auth={auth}/>
  );
});

const View = observer(({auth}) => {
  const user = auth.appUser;

  return (
    <MainLayout title='Home'>
      {auth.userManager.ready ? (
        user ? (
          <>
            <h3>Hi, {user.name}</h3>
            example episode url:{' '}
            <NavLink to='/episodes/el-show-editor2'>el-show-editor2</NavLink>
          </>
        ) : (
          <h3>Unrecognized user: {auth.authUser?.email}</h3>
        )
      ) : null}
    </MainLayout>
  );
});
