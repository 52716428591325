import React from 'react';
import { observer } from 'mobx-react';
import { firebaseAuth} from '../masala-lib/editorial/db/firebase.auth.js';
import { auth } from "../app-root.fs.js";

const CHEAT_SHEET_URL = 'https://jiveworld.slite.com/api/s/note/9yWyAJAdxP5apywUS68HuF/New-Script-Editor-Cheat-Sheet';

export const SimpleStatusBar = observer( ({ element, children }) => {

  return (
    <div className={'status-bar'}>
      <div className='status-bar-element status-bar-chapter'>
        {element}
      </div>

      <div style={{display:'inline-block', float:'right'}}>
        [{auth.appUser?.alias}]{' '}
        {(auth.authUser) ?
          <>
            <img style={{width:35, height:35,  objectFit:'cover', borderRadius:'50%',  padding:3}} src={auth.authUser.photoURL} alt=""/>
            <span style={{textDecoration:'underline', marginLeft:6, marginRight:6}} onClick={() => firebaseAuth.signOut()}>(Sign-out)</span>
          </>
          : null
        }
        <a href={CHEAT_SHEET_URL} target="_blank" >H&nbsp;</a>
      </div>
    </div>
  );
});
