import { FSharpRef } from "../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type } from "../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { SENTENCE } from "../../../elements/element-types.fs.js";
import { elementKindAnchorDirection } from "../../../elements/anchors.fs.js";
import { StructuralDTO, getKindFromId } from "../../../basic-types.fs.js";
import { interpolate, toText } from "../../../../.fable/fable-library.3.1.0-beta-001/String.js";
import { randomString } from "../../.././utils.js";
import { AlertMessage, Alert } from "../../../alert-messages.fs.js";

export class StructuralActions0 {
    constructor() {
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = s0.contents;
        this["init@21-6"] = 1;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    create(kind, initialTargetId) {
        return StructuralActions0__create_Z384F8060(this, kind, initialTargetId)
    }
    move(id, initialTargetId) {
        return StructuralActions0__move_Z384F8060(this, id, initialTargetId)
    }
    remove(id) {
        return StructuralActions0__remove_Z721C83C5(this, id)
    }
    revert(versionObject) {
        return StructuralActions0__revert_4E60E31B(this, versionObject)
    }
    updateContent(id, content) {
        return StructuralActions0__updateContent_Z384F8060(this, id, content)
    }
}

export function StructuralActions0$reflection() {
    return class_type("StructuralActions.StructuralActions0", void 0, StructuralActions0);
}

export function StructuralActions0_$ctor() {
    return new StructuralActions0();
}

function StructuralActions0__getSentences(this$) {
    return this$.self["StructuralActions.StructuralActions0.get_content"]().getKindSubList(SENTENCE);
}

function StructuralActions0__getAnchorWordId_Z384F8060(this$, kind, initialTargetId) {
    let targetSentence = null;
    const direction = ((elementKindAnchorDirection[kind])) | 0;
    if (getKindFromId(initialTargetId) === SENTENCE) {
        targetSentence = this$.self["StructuralActions.StructuralActions0.get_content"]().getElement(initialTargetId);
    }
    else {
        const targetId = StructuralActions0__getSentences(this$).stepId(initialTargetId, false, direction);
        targetSentence = this$.self["StructuralActions.StructuralActions0.get_content"]().getElement(targetId);
    }
    if (targetSentence) {
        const sentenceSpan = targetSentence.anchors;
        if (direction === 1) {
            return sentenceSpan.startWordId | 0;
        }
        else {
            return sentenceSpan.endWordIdExclusive | 0;
        }
    }
    else {
        return (null) | 0;
    }
}

function StructuralActions0__create_Z384F8060(this$, kind, initialTargetId) {
    const anchorWordId = StructuralActions0__getAnchorWordId_Z384F8060(this$, kind, initialTargetId) | 0;
    if (anchorWordId) {
        const newId = toText(interpolate("%P():%P()", [kind, randomString(12)]));
        this$.self["StructuralActions.StructuralActions0.get_mutationActions"]().addUpdateStructural(new StructuralDTO(kind, newId, anchorWordId, ""));
        return newId;
    }
    else {
        return null;
    }
}

function StructuralActions0__move_Z384F8060(this$, id, initialTargetId) {
    const element = this$.self["StructuralActions.StructuralActions0.get_content"]().getElement(id);
    const newAnchorWordId = StructuralActions0__getAnchorWordId_Z384F8060(this$, element.kind, initialTargetId) | 0;
    if (newAnchorWordId) {
        this$.self["StructuralActions.StructuralActions0.get_mutationActions"]().addUpdateStructural(new StructuralDTO(element.kind, element.id, newAnchorWordId, element.content));
    }
    else {
        this$.self["StructuralActions.StructuralActions0.get_alertMessages"]().add(new AlertMessage("can\u0027t find sentence anchor to move structural content", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
    }
}

function StructuralActions0__updateContent_Z384F8060(this$, id, content) {
    this$.self["StructuralActions.StructuralActions0.get_mutationActions"]().updateContentStructural(id, content);
}

function StructuralActions0__remove_Z721C83C5(this$, id) {
    this$.self["StructuralActions.StructuralActions0.get_mutationActions"]().removeStructural(id);
}

function StructuralActions0__revert_4E60E31B(this$, versionObject) {
    this$.self["StructuralActions.StructuralActions0.get_mutationActions"]().revertStructural(versionObject);
}

// JS BOILERPLATE GENERATED
 