import { mobxConfigure } from "./masala-lib/bindings/mobx.fs.js";
import * as singletons from "./singletons.js";
import { ScriptEditorContentRoots } from "./content-roots.fs.js";
import { Validations } from "./masala-lib/editorial/validations/validations.fs.js";
import { ScriptLoader } from "./masala-lib/editorial/db/./script-loader.js";
import { System_Array__$005B$005D$1_append_1505 } from "./masala-lib/basic-types.fs.js";
import { runInAction, reaction } from "mobx";
import { class_type } from "./.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { parse } from "./.fable/fable-library.3.1.0-beta-001/Int32.js";
import { some } from "./.fable/fable-library.3.1.0-beta-001/Option.js";
import { WORD_GROUP, SENTENCE } from "./masala-lib/elements/element-types.fs.js";
import { printf, toConsole } from "./.fable/fable-library.3.1.0-beta-001/String.js";
import lodash from "lodash";

mobxConfigure({
    enforceActions: "never",
});

export const scriptEditorModel = singletons.scriptEditorModel;

export const mutationActions = singletons.mutationActions;

export const structuralActions = singletons.structuralActions;

export const metadataBlockActions = singletons.metadataBlockActions;

export const wordGroupActions = singletons.wordGroupActions;

export const conversationManager = singletons.conversationManager;

export const verbatimActions = singletons.verbatimActions;

export const translationActions = singletons.translationActions;

export const versions = singletons.versions;

export const alertMessages = singletons.alertMessages;

export const keyboardModes = singletons.keyboardModes;

export const auth = singletons.auth;

export const contentRoots = ScriptEditorContentRoots();

export const validations = Validations(contentRoots);

export class AppRoot0 {
    constructor() {
        let clo1, clo1_1;
        this.episodeKey = "";
        this.loader = (new ScriptLoader());
        const disposers = [];
        this.jumpId = (null);
        System_Array__$005B$005D$1_append_1505(disposers, (clo1 = reaction(() => (this.loader.getStateVersion()), () => {
            AppRoot0__contentUpdated(this);
        }, {}), () => {
            clo1();
        }));
        System_Array__$005B$005D$1_append_1505(disposers, (clo1_1 = reaction(() => (conversationManager.getStateVersion()), () => {
            AppRoot0__contentUpdated(this);
        }, {}), () => {
            clo1_1();
        }));
        makeObservable(this, {
            episodeKey: observable.ref,
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    closeEpisode() {
        return AppRoot0__closeEpisode(this)
    }
    loadEpisode(episodeKey0, jumpId0) {
        return AppRoot0__loadEpisode_Z384F8060(this, episodeKey0, jumpId0)
    }
}

export function AppRoot0$reflection() {
    return class_type("AppRoot.AppRoot0", void 0, AppRoot0);
}

export function AppRoot0_$ctor() {
    return new AppRoot0();
}

function AppRoot0__jumpToId_Z18115A39(this$, jump0, ms) {
    if (jump0) {
        const jump = (jump0.indexOf(":") >= 0) ? jump0 : (parse(jump0, 511, false, 32));
        setTimeout(() => {
            scriptEditorModel.setFocusedElementId(jump);
        }, ms);
    }
}

function AppRoot0__loadEpisode_Z384F8060(this$, episodeKey0, jumpId0) {
    if ((this$.episodeKey === episodeKey0) ? jumpId0 : false) {
        AppRoot0__jumpToId_Z18115A39(this$, jumpId0, 20);
    }
    else {
        this$.jumpId = jumpId0;
        this$.loader.loadEpisode(episodeKey0, true);
        conversationManager.loadEpisode(episodeKey0, true);
    }
}

function AppRoot0__closeEpisode(this$) {
    this$.episodeKey = "";
    this$.loader.closeEpisode();
    conversationManager.close();
}

function AppRoot0__contentUpdated(this$) {
    console.log(some("appRoot.contentUpdated, status: " + this$.loader.getStatus()));
    if (this$.loader.getStatus() === "COMPLETE") {
        console.log(some("COMPLETE: " + this$.loader.key));
        const newEpisode = this$.episodeKey !== this$.loader.key;
        this$.episodeKey = this$.loader.key;
        runInAction(() => {
            let x;
            const t1 = Date.now();
            contentRoots.episodeKey = this$.episodeKey;
            this$.loader.docSet.copyTo(contentRoots);
            const content0 = contentRoots.content;
            const content = content0.joinWithIdMap("thread", conversationManager.obj, {
                messages: [],
            });
            const words = contentRoots.words;
            const sentences = content.getKindSubList(SENTENCE);
            const wordGroups = content.getKindSubList(WORD_GROUP);
            const arg10 = Date.now() - t1;
            toConsole(printf("new compute content time: %f %f"))(arg10)(t1);
            scriptEditorModel.editEnabled = contentRoots.editEnabled;
            scriptEditorModel.setElementList(content);
            scriptEditorModel.setTranslationLanguage(contentRoots.translationLanguage);
            mutationActions.setEpisodeKey(this$.episodeKey);
            if (newEpisode) {
                versions.lazyLoadVersions(this$.episodeKey);
            }
            if (x = this$.jumpId, !(lodash.isNull(x))) {
                AppRoot0__jumpToId_Z18115A39(this$, this$.jumpId, 500);
                this$.jumpId = (null);
            }
        });
    }
}

export function AppRoot() {
    return AppRoot0_$ctor();
}

export const appRoot = AppRoot();

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 