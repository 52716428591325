import { Record } from "../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { record_type, int32_type, lambda_type, bool_type, class_type, string_type } from "../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { indexed } from "../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { map, delay } from "../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { uncurry } from "../../.fable/fable-library.3.1.0-beta-001/Util.js";

export class PrecedenceFunctions extends Record {
    constructor(precedenceAtLeastFilter, precedenceProjection, sortComparator) {
        super();
        this.precedenceAtLeastFilter = precedenceAtLeastFilter;
        this.precedenceProjection = precedenceProjection;
        this.sortComparator = sortComparator;
    }
}

export function PrecedenceFunctions$reflection() {
    return record_type("Precedence.PrecedenceFunctions", [], PrecedenceFunctions, () => [["precedenceAtLeastFilter", lambda_type(string_type, lambda_type(class_type("BasicTypes.IElement"), bool_type))], ["precedenceProjection", lambda_type(string_type, int32_type)], ["sortComparator", lambda_type(string_type, lambda_type(string_type, int32_type))]]);
}

export class Precedence0 {
    constructor(precedence0) {
        this.precedence = precedence0;
        this.keyToPrecedenceLevel = {};
        const arr = indexed(this.precedence);
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const forLoopVar = arr[idx];
            const kind = forLoopVar[1];
            const index = forLoopVar[0] | 0;
            this.keyToPrecedenceLevel[kind]=index;
        }
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    leastPrecedenceLevel() {
        return Precedence0__leastPrecedenceLevel(this)
    }
    pickWith(obj) {
        return Precedence0__pickWith_1505(this, obj)
    }
    precedenceAtLeastFilter(kind) {
        return Precedence0__precedenceAtLeastFilter_Z721C83C5(this, kind)
    }
    precedenceProjection(kind) {
        return Precedence0__precedenceProjection_Z721C83C5(this, kind)
    }
    sortComparator(kind1, kind2) {
        return Precedence0__sortComparator(this, kind1, kind2)
    }
    get functions() {
        return Precedence0__functions(this)
    }
}

export function Precedence0$reflection() {
    return class_type("Precedence.Precedence0", void 0, Precedence0);
}

export function Precedence0_$ctor_Z6B4C8463(precedence0) {
    return new Precedence0(precedence0);
}

function Precedence0__pickWith_1505(this$, obj) {
    return Array.from(delay(() => map((p) => (obj[p]), this$.precedence)));
}

function Precedence0__leastPrecedenceLevel(this$) {
    return this$.precedence.length;
}

function Precedence0__precedenceProjection_Z721C83C5(this$, kind) {
    return (this$.keyToPrecedenceLevel[kind]);
}

function Precedence0__sortComparator(this$, kind1, kind2) {
    return Precedence0__precedenceProjection_Z721C83C5(this$, kind1) - Precedence0__precedenceProjection_Z721C83C5(this$, kind2);
}

function Precedence0__precedenceAtLeastFilter_Z721C83C5(this$, kind) {
    const level = ((this$.keyToPrecedenceLevel[kind])) | 0;
    return (el) => (((this$.keyToPrecedenceLevel[el.kind])) >= level);
}

function Precedence0__functions(this$) {
    return new PrecedenceFunctions(uncurry(2, (kind_1) => Precedence0__precedenceAtLeastFilter_Z721C83C5(this$, kind_1)), (kind) => Precedence0__precedenceProjection_Z721C83C5(this$, kind), (kind1, kind2) => Precedence0__sortComparator(this$, kind1, kind2));
}

export function Precedence(precedence) {
    return Precedence0_$ctor_Z6B4C8463(precedence);
}

// JS BOILERPLATE GENERATED
 