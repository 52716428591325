import React, {useState, useCallback, useRef, useEffect} from "react";

function useClosureRef(f) {
  const ref = useRef(f);

  function updateClosure(f) {
    ref.current = f;
  }

  function callClosure(...args) {
    ref.current(...args);
  }
  return [callClosure, updateClosure];
}

// TODO change to class because of addEventListener and closure makes it easier to use this.X
export function CommentBox({onSubmit, disabled, initialText = ""}) {
  const [comment, setComment] = useState(initialText);
  const [handleKeyPress, updateHandleKeyPress] = useClosureRef(null);

  useEffect(() => {
    setComment(initialText);
  }, [initialText]);

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    onSubmit(comment);
    setComment("");
  };

  const handleKeyPress0 =  (e) => {
    if(e.which === 13 && !e.shiftKey && !e.repeat) {
      handleSubmit(e);
      e.preventDefault();
    }
    // TODO how to handle this in logical way?
    //console.log("onKeyDown code: " + e.code);
    if(e.code === 'KeyR' && e.altKey && !e.repeat)  {
      setComment(e.target.value + ' (resolved) ')
      e.preventDefault();
    }
  };
  updateHandleKeyPress(handleKeyPress0);

  const attachKeyListeners = useCallback( (el) => {
    if (el) {
      el.addEventListener('keydown', handleKeyPress, false);
    }
  }, []);

  return (
    <div className={'comment-box'}>
      <textarea
        rows={3}
        onChange={handleChange}
        value={comment}
        disabled={disabled}
        ref={attachKeyListeners}
        placeholder="Comment"
        />
    </div>
  );
}
