import { VersionsLoader } from "../db/./versions-loader.js";
import { JSObject, Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505, getKindFromId, System_Array__$005B$005D$1_append_1505 } from "../../basic-types.fs.js";
import { runInAction, reaction } from "mobx";
import { class_type } from "../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { sortBy } from "../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { comparePrimitives } from "../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { TRANSLATION, WORD_GROUP, SENTENCE } from "../../elements/element-types.fs.js";
import { some } from "../../../.fable/fable-library.3.1.0-beta-001/Option.js";

export class Versions0 {
    constructor() {
        let clo1;
        this.sentenceVersions = {};
        this.structuralVersions = {};
        this.wordGroupVersions = {};
        this.translationVersions = {};
        const disposers = [];
        this.loader = (new VersionsLoader());
        System_Array__$005B$005D$1_append_1505(disposers, (clo1 = reaction(() => (this.loader.getStateVersion()), () => {
            Versions0__versionsUpdated(this);
        }, {}), () => {
            clo1();
        }));
        makeObservable(this, {
            sentenceVersions: observable.ref,
            structuralVersions: observable.ref,
            wordGroupVersions: observable.ref,
            translationVersions: observable.ref,
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    getVersions(id) {
        return Versions0__getVersions_Z721C83C5(this, id)
    }
    lazyLoadVersions(episodeKey) {
        return Versions0__lazyLoadVersions_Z721C83C5(this, episodeKey)
    }
}

export function Versions0$reflection() {
    return class_type("Versions.Versions0", void 0, Versions0);
}

export function Versions0_$ctor() {
    return new Versions0();
}

function Versions0__lazyLoadVersions_Z721C83C5(this$, episodeKey) {
    return setTimeout(() => {
        this$.loader.loadEpisode(episodeKey, true);
    }, 1500);
}

function Versions0__getVersions_Z721C83C5(this$, id) {
    const kind = getKindFromId(id);
    const filterElementVersions = (elements) => sortBy((el_1) => (-el_1.timestamp), elements.filter((el) => (el.id === id)), {
        Compare: comparePrimitives,
    });
    if (kind === SENTENCE) {
        return filterElementVersions(Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.sentenceVersions));
    }
    else if (kind === WORD_GROUP) {
        return filterElementVersions(Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.wordGroupVersions));
    }
    else if (kind === TRANSLATION) {
        return filterElementVersions(Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.translationVersions));
    }
    else {
        return filterElementVersions(Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(JSObject, this$.structuralVersions));
    }
}

function Versions0__versionsUpdated(this$) {
    console.log(some("versions updated: " + this$.loader.getStatus()));
    if (this$.loader.getStatus() === "COMPLETE") {
        console.log(some("COMPLETE: " + this$.loader.key));
        runInAction(() => {
            this$.sentenceVersions = this$.loader.docSet.sentenceVersionsDoc.versions;
            this$.wordGroupVersions = this$.loader.docSet.wordGroupVersionsDoc.versions;
            this$.structuralVersions = this$.loader.docSet.structuralVersionsDoc.versions;
            this$.translationVersions = this$.loader.docSet.translationVersionsDoc.versions;
        });
    }
}

export function Versions() {
    return Versions0_$ctor();
}

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 