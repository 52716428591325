import { Record } from "../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { record_type, array_type, int32_type } from "../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { copy, replicate, append, indexed } from "../../../.fable/fable-library.3.1.0-beta-001/Array.js";

export const SHIFTED_BY_DELETE_FLAG = 1 << 25;

export const MASK_OUT_DELETE_FLAG = ~SHIFTED_BY_DELETE_FLAG;

export class IndexMapping extends Record {
    constructor(indexLookup, contentLength) {
        super();
        this.indexLookup = indexLookup;
        this.contentLength = (contentLength | 0);
    }
}

export function IndexMapping$reflection() {
    return record_type("IndexMapping.IndexMapping", [], IndexMapping, () => [["indexLookup", array_type(int32_type)], ["contentLength", int32_type]]);
}

export function getIndex(mapping, contentId) {
    return mapping.indexLookup[contentId] & MASK_OUT_DELETE_FLAG;
}

export function recordInsert(mapping, index) {
    const indexLookup = mapping.indexLookup;
    const arr = indexed(indexLookup);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const i = forLoopVar[1] | 0;
        const contentId = forLoopVar[0] | 0;
        if ((i & MASK_OUT_DELETE_FLAG) >= index) {
            indexLookup[contentId] = (i + 1);
        }
    }
    mapping.indexLookup = append(mapping.indexLookup, new Int32Array([index]), Int32Array);
    mapping.contentLength = (mapping.contentLength + 1);
}

export function recordInsertAtId(mapping, contentId) {
    recordInsert(mapping, getIndex(mapping, contentId));
}

export function recordRemove(mapping, index) {
    const indexLookup = mapping.indexLookup;
    const arr = indexed(indexLookup);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const i = forLoopVar[1] | 0;
        const contentId = forLoopVar[0] | 0;
        if ((i & MASK_OUT_DELETE_FLAG) > index) {
            indexLookup[contentId] = (i - 1);
        }
        else if (i === index) {
            indexLookup[contentId] = (index | SHIFTED_BY_DELETE_FLAG);
        }
    }
    mapping.contentLength = (mapping.contentLength - 1);
}

export function recordRemoveAtId(mapping, contentId) {
    recordRemove(mapping, getIndex(mapping, contentId));
}

export function recordSwapsert(mapping, index) {
    const indexLookup = mapping.indexLookup;
    const arr = indexed(indexLookup);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const i = forLoopVar[1] | 0;
        const contentId = forLoopVar[0] | 0;
        if ((i & MASK_OUT_DELETE_FLAG) > index) {
            indexLookup[contentId] = (i + 1);
        }
    }
    mapping.indexLookup = append(mapping.indexLookup, new Int32Array([index + 1]), Int32Array);
    mapping.contentLength = (mapping.contentLength + 1);
}

export function isDeletedId(mapping, contentId) {
    return (mapping.indexLookup[contentId] & SHIFTED_BY_DELETE_FLAG) !== 0;
}

export function createMapping(length) {
    const indexLookup = new Int32Array(length + 1);
    for (let contentId = 0; contentId <= length; contentId++) {
        indexLookup[contentId] = contentId;
    }
    return new IndexMapping(indexLookup, length);
}

export function makeIndexToIdMapping(mapping) {
    const length = mapping.contentLength | 0;
    const result = new Int32Array(length + 1);
    const arr = indexed(mapping.indexLookup);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const i = forLoopVar[1] | 0;
        const contentId = forLoopVar[0] | 0;
        if ((contentId & SHIFTED_BY_DELETE_FLAG) === 0) {
            result[i] = contentId;
        }
    }
    return result;
}

export function adaptContentDimensionedArray(oldArray, oldMapping, newMapping, fillValue) {
    const result = replicate(newMapping.contentLength + 1, fillValue);
    const oldMaxId = (oldMapping.indexLookup.length - 1) | 0;
    for (let contentId = 0; contentId <= oldMaxId; contentId++) {
        const oldIndex = getIndex(oldMapping, contentId) | 0;
        const newIndex = getIndex(newMapping, contentId) | 0;
        result[newIndex] = oldArray[oldIndex];
    }
    return result;
}

export function copyMapping(mapping) {
    return new IndexMapping(copy(mapping.indexLookup), mapping.contentLength);
}

