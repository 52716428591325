import { mutationActions } from "../app-root.fs.js";
import { MetadataBlockActions0$reflection, MetadataBlockActions0 } from "../masala-lib/editorial/models/actions/metadata-block-actions.fs.js";
import { class_type } from "../.fable/fable-library.3.1.0-beta-001/Reflection.js";

export class MetadataBlockActions1 extends MetadataBlockActions0 {
    constructor() {
        super();
        this["init@8-17"] = 1;
    }
    ["MetadataBlockActions.MetadataBlockActions0.get_mutationActions"]() {
        return mutationActions;
    }
}

export function MetadataBlockActions1$reflection() {
    return class_type("ScriptEditorMetadataBlockActions.MetadataBlockActions1", void 0, MetadataBlockActions1, MetadataBlockActions0$reflection());
}

export function MetadataBlockActions1_$ctor() {
    return new MetadataBlockActions1();
}

export function MetadataBlockActions() {
    return MetadataBlockActions1_$ctor();
}

