import React from 'react';
import {observer} from "mobx-react";
import {scriptEditorModel} from "./app-root.fs.js";


export const SearchBar = observer( () => {
  const handleChange = (ev) => {
    scriptEditorModel.filters.uiText = ev.target.value;
  };

  return (
    <div className={'search-bar'}>
      <div className={'filters-label'}>search: </div>
      <input type="text" size="60" onChange={handleChange} value={scriptEditorModel.filters.uiText}/>
    </div>
  );
});
