import { alertMessages, scriptEditorModel, mutationActions } from "../app-root.fs.js";
import { WordGroupActions0$reflection, WordGroupActions0 } from "../masala-lib/editorial/models/actions/word-group-actions.fs.js";
import { class_type } from "../.fable/fable-library.3.1.0-beta-001/Reflection.js";

export class WordGroupActions1 extends WordGroupActions0 {
    constructor() {
        super();
        this["init@6-18"] = 1;
    }
    ["WordGroupActions.WordGroupActions0.get_mutationActions"]() {
        return mutationActions;
    }
    ["WordGroupActions.WordGroupActions0.get_content"]() {
        return scriptEditorModel.elements;
    }
    ["WordGroupActions.WordGroupActions0.get_currentWordSelection"]() {
        return scriptEditorModel.wordRangeSelection;
    }
    ["WordGroupActions.WordGroupActions0.get_currentFocusedElement"]() {
        return scriptEditorModel.focusedElement;
    }
    ["WordGroupActions.WordGroupActions0.get_alertMessages"]() {
        return alertMessages;
    }
}

export function WordGroupActions1$reflection() {
    return class_type("ScriptEditorWordGroupActions.WordGroupActions1", void 0, WordGroupActions1, WordGroupActions0$reflection());
}

export function WordGroupActions1_$ctor() {
    return new WordGroupActions1();
}

export function WordGroupActions() {
    return WordGroupActions1_$ctor();
}

