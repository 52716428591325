import lodash from "lodash";
import { isDeletedId, makeIndexToIdMapping, getIndex } from "../../ids/positional-ids.fs.js";
import { rangeNumber, map, delay } from "../../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { tryFind, indexed, equalsWith } from "../../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { equals, comparePrimitives } from "../../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { strongNormalizeWordArray, getTranscriptWordsFromString } from "../../.././content-funcs.js";
import { getSentenceWordIdRange, getSentencesWordStrings, getSentenceWordStrings } from "../../../content-funcs.fs.js";
import { doDiffAndPatch } from "../../ids/positional-diff-patch.js";
import { SentenceDTO, SpanExclusiveAnchors, System_Array__$005B$005D$1_append_1505, System_Array__$005B$005D$1_extend_5975E3 } from "../../../basic-types.fs.js";
import { FSharpRef } from "../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type } from "../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { WORD, WORD_GROUP, SENTENCE } from "../../../elements/element-types.fs.js";
import { AlertMessage, Alert } from "../../../alert-messages.fs.js";
import { ElementList } from "../../../elements/element-list.fs.js";

export function sanityCheckVerbatimUpdate(oldIndexMap, newIndexMap, oldWords, newWords, oldSentences) {
    let mismatchRegionsCount = 0;
    let mismatchCount = 0;
    let maxMismatchCount = 0;
    const allAreWords = (words) => {
        let x;
        for (let idx = 0; idx <= (words.length - 1); idx++) {
            const word = words[idx];
            if (x = word.trim(), lodash.isEmpty(x)) {
                return false;
            }
        }
        return true;
    };
    if ((!allAreWords(oldWords)) ? true : (!allAreWords(newWords))) {
        return false;
    }
    for (let idx_1 = 0; idx_1 <= (oldSentences.length - 1); idx_1++) {
        const sentence = oldSentences[idx_1];
        const sentenceStartWordId = sentence.anchors.startWordId | 0;
        const sentenceEndWordId = sentence.anchors.endWordIdExclusive | 0;
        const oldSentenceStartIndex = getIndex(oldIndexMap, sentenceStartWordId) | 0;
        const newSentenceStartIndex = getIndex(newIndexMap, sentenceStartWordId) | 0;
        const oldSentenceEndIndex = (getIndex(oldIndexMap, sentenceEndWordId) - 1) | 0;
        const newSentenceEndIndex = (getIndex(newIndexMap, sentenceEndWordId) - 1) | 0;
        const oldSentenceWords = Array.from(delay(() => map((i) => oldWords[i], rangeNumber(oldSentenceStartIndex, 1, oldSentenceEndIndex))));
        const newSentenceWords = Array.from(delay(() => map((i_1) => newWords[i_1], rangeNumber(newSentenceStartIndex, 1, newSentenceEndIndex))));
        if ((!allAreWords(oldSentenceWords)) ? true : (!allAreWords(newSentenceWords))) {
            return false;
        }
        let mismatch = false;
        if (oldSentenceWords.length !== newSentenceWords.length) {
            mismatch = true;
        }
        if (!mismatch) {
            mismatch = (!equalsWith(comparePrimitives, oldSentenceWords, newSentenceWords));
        }
        if (mismatch) {
            if (mismatchCount === 0) {
                mismatchRegionsCount = (mismatchRegionsCount + 1);
            }
            mismatchCount = (mismatchCount + 1);
            if (mismatchCount > maxMismatchCount) {
                maxMismatchCount = mismatchCount;
            }
        }
        else {
            mismatchCount = 0;
        }
    }
    if (mismatchRegionsCount <= 1) {
        return maxMismatchCount <= 2;
    }
    else {
        return false;
    }
}

export function computeVerbatimUpdate(sentenceId, newSentenceVerbatim, currentSentences, currentIndexMap) {
    const sentence = currentSentences.getElement(sentenceId);
    const currentDomainWords = currentSentences.words;
    const newSentenceWords = getTranscriptWordsFromString(newSentenceVerbatim);
    const currentSentenceWords = getSentenceWordStrings(sentence, currentDomainWords);
    const normalizedNewWords = strongNormalizeWordArray(newSentenceWords);
    const normalizedCurrentWords = strongNormalizeWordArray(currentSentenceWords);
    const currentStartIndex = currentSentences.wordAddress(sentenceId) | 0;
    const patchResult = doDiffAndPatch(currentStartIndex, normalizedCurrentWords, currentIndexMap, normalizedNewWords);
    const newIndexMap = patchResult.newIndexMapping;
    const currentStartWordId = sentence.anchors.startWordId | 0;
    const newStartIndex = getIndex(newIndexMap, currentStartWordId) | 0;
    const newWords = [];
    const arr = currentSentences.elements;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const s = arr[idx];
        if (s.id === sentenceId) {
            System_Array__$005B$005D$1_extend_5975E3(newWords, newSentenceWords);
        }
        else {
            System_Array__$005B$005D$1_extend_5975E3(newWords, getSentenceWordStrings(s, currentDomainWords));
        }
    }
    let adjustedStartWordId = (null) | 0;
    if (currentStartIndex !== newStartIndex) {
        const indexToId = makeIndexToIdMapping(newIndexMap);
        adjustedStartWordId = indexToId[currentStartIndex];
    }
    return {
        adjustedStartWordId: adjustedStartWordId,
        newIndexMapping: newIndexMap,
        newWords: newWords,
    };
}

export class VerbatimActions0 {
    constructor() {
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = s0.contents;
        this["init@95-4"] = 1;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    createSentence(text, adjacent, above) {
        return VerbatimActions0__createSentence_5D83414A(this, text, adjacent, above)
    }
    removeSentence(id) {
        return VerbatimActions0__removeSentence_Z721C83C5(this, id)
    }
    splitSentence(id, wordId, above) {
        return VerbatimActions0__splitSentence_5D83414A(this, id, wordId, above)
    }
    updateSentence(id, content, allowZeroLength) {
        return VerbatimActions0__updateSentence_5D83414A(this, id, content, allowZeroLength)
    }
}

export function VerbatimActions0$reflection() {
    return class_type("VerbatimActions.VerbatimActions0", void 0, VerbatimActions0);
}

export function VerbatimActions0_$ctor() {
    return new VerbatimActions0();
}

function VerbatimActions0__getCurrentSentences(this$) {
    return this$.self["VerbatimActions.VerbatimActions0.get_content"]().getKindSubList(SENTENCE);
}

function VerbatimActions0__getCurrentWordGroups(this$) {
    return this$.self["VerbatimActions.VerbatimActions0.get_content"]().getKindSubList(WORD_GROUP);
}

function VerbatimActions0__computeAndSanityCheckVerbatimUpdate_434EA3A2(this$, sentenceId, content, currentSentences) {
    let copyOfStruct;
    const currentIndexMap = this$.self["VerbatimActions.VerbatimActions0.get_content"]().words.wordsIndexMapping;
    const verbatimUpdate = computeVerbatimUpdate(sentenceId, content, currentSentences, currentIndexMap);
    const newIndexMap = verbatimUpdate.newIndexMapping;
    const indexToId = makeIndexToIdMapping(newIndexMap);
    const newWords = verbatimUpdate.newWords;
    const currentWords = getSentencesWordStrings(currentSentences);
    const wordElements = [];
    const arr = indexed(newWords);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const word = forLoopVar[1];
        const index = forLoopVar[0] | 0;
        System_Array__$005B$005D$1_append_1505(wordElements, {
            id: indexToId[index],
            kind: WORD,
            text: word,
        });
    }
    if (sanityCheckVerbatimUpdate(currentIndexMap, newIndexMap, currentWords, newWords, (copyOfStruct = currentSentences, copyOfStruct.elements))) {
        return {
            adjustedStartWordId: verbatimUpdate.adjustedStartWordId,
            newIndexMap: newIndexMap,
            wordElements: wordElements,
        };
    }
    else {
        return null;
    }
}

function VerbatimActions0__computeSentenceShiftData_Z384F8060(this$, id, newStartWordId) {
    const currentSentences = VerbatimActions0__getCurrentSentences(this$);
    const sentence = currentSentences.getElement(id);
    const prevSentence = currentSentences.getElement(currentSentences.prevId(id));
    const sentenceMutationData = new SentenceDTO(sentence.id, new SpanExclusiveAnchors(newStartWordId, sentence.anchors.endWordIdExclusive));
    const prevSentenceMutationData = new SentenceDTO(prevSentence.id, new SpanExclusiveAnchors(prevSentence.anchors.startWordId, newStartWordId));
    return {
        prevSentence: prevSentenceMutationData,
        sentence: sentenceMutationData,
    };
}

function VerbatimActions0__updateSentence_5D83414A(this$, id, content, allowZeroLength) {
    const newSentenceWords = getTranscriptWordsFromString(content);
    if ((newSentenceWords.length === 0) ? (!allowZeroLength) : false) {
        this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("cannot edit sentence to contain no words, delete instead", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
        return;
    }
    const normalizedNewWords = strongNormalizeWordArray(newSentenceWords);
    const emptyWord = tryFind((text) => (text.length === 0), normalizedNewWords);
    if (!(lodash.isNil(emptyWord))) {
        this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("edit contains disallowed standalone punctuation", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
        return;
    }
    const sentence = this$.self["VerbatimActions.VerbatimActions0.get_content"]().getElement(id);
    const sentenceWordRange = getSentenceWordIdRange(sentence, this$.self["VerbatimActions.VerbatimActions0.get_content"]().words);
    const currentWordGroups = VerbatimActions0__getCurrentWordGroups(this$).getElementsIntersectWordIdRange(sentenceWordRange);
    let testCurrentWordGroups = true;
    if (currentWordGroups) {
        for (let idx = 0; idx <= (currentWordGroups.length - 1); idx++) {
            const group = currentWordGroups[idx];
            if (group.wordAddress > group.endWordAddress) {
                testCurrentWordGroups = false;
            }
        }
    }
    else {
        testCurrentWordGroups = false;
    }
    const verbatimUpdate = VerbatimActions0__computeAndSanityCheckVerbatimUpdate_434EA3A2(this$, id, content, VerbatimActions0__getCurrentSentences(this$));
    if (testCurrentWordGroups) {
        const newMap = verbatimUpdate.newIndexMap;
        for (let idx_1 = 0; idx_1 <= (currentWordGroups.length - 1); idx_1++) {
            const group_1 = currentWordGroups[idx_1];
            const groupStart = getIndex(newMap, group_1.anchors.startWordId) | 0;
            const groupEnd = getIndex(newMap, group_1.anchors.endWordId) | 0;
            if ((groupStart >= groupEnd) ? isDeletedId(newMap, group_1.anchors.endWordId) : false) {
                this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("edit not allowed because completely destroys word region of existing word group", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
                return;
            }
        }
    }
    if (verbatimUpdate) {
        let sentenceUpdates = null;
        if (verbatimUpdate.adjustedStartWordId) {
            const shiftAdjustments = VerbatimActions0__computeSentenceShiftData_Z384F8060(this$, id, verbatimUpdate.adjustedStartWordId);
            sentenceUpdates = [shiftAdjustments.sentence, shiftAdjustments.prevSentence];
        }
        else {
            sentenceUpdates = [new SentenceDTO(id, sentence.anchors)];
        }
        this$.self["VerbatimActions.VerbatimActions0.get_mutationActions"]().updateVerbatim(verbatimUpdate.wordElements, verbatimUpdate.newIndexMap, sentenceUpdates);
    }
    else {
        this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("error with verbatim sanity check", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
    }
}

function VerbatimActions0__createSentence_5D83414A(this$, text, adjacent, above) {
    const currentSentences = VerbatimActions0__getCurrentSentences(this$);
    const adjacentSentence = currentSentences.getElement(adjacent);
    const newSentences = [];
    let dummyAnchors = null;
    if (above) {
        const startWordId = adjacentSentence.anchors.startWordId;
        dummyAnchors = {
            endWordIdExclusive: startWordId,
            startWordId: startWordId,
        };
    }
    else {
        const endWordId = adjacentSentence.anchors.endWordIdExclusive;
        dummyAnchors = {
            endWordIdExclusive: endWordId,
            startWordId: endWordId,
        };
    }
    const dummySentenceId = "DUMMY";
    const dummySentence = {
        anchors: dummyAnchors,
        id: dummySentenceId,
        kind: SENTENCE,
    };
    const arr = currentSentences.elements;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const sentence = arr[idx];
        if (equals(sentence, adjacentSentence)) {
            if (above) {
                System_Array__$005B$005D$1_append_1505(newSentences, dummySentence);
                System_Array__$005B$005D$1_append_1505(newSentences, sentence);
            }
            else {
                System_Array__$005B$005D$1_append_1505(newSentences, sentence);
                System_Array__$005B$005D$1_append_1505(newSentences, dummySentence);
            }
        }
    }
    const existingWords = currentSentences.words;
    const newSentencesList = ElementList(newSentences, "", null, existingWords, existingWords.words.wordsIndexMapping, null, null);
    const verbatimUpdate = VerbatimActions0__computeAndSanityCheckVerbatimUpdate_434EA3A2(this$, dummySentenceId, text, newSentencesList);
    if (verbatimUpdate) {
        let sentenceUpdates = null;
        if (verbatimUpdate.adjustedStartWordId) {
            const shiftAdjustments = VerbatimActions0__computeSentenceShiftData_Z384F8060(this$, dummySentenceId, verbatimUpdate.adjustedStartWordId);
            const newSentenceData = shiftAdjustments.sentence;
            newSentenceData.id = (null);
            sentenceUpdates = [newSentenceData, shiftAdjustments.prevSentence];
            this$.self["VerbatimActions.VerbatimActions0.get_mutationActions"]().updateVerbatim(verbatimUpdate.wordElements, verbatimUpdate.newIndexMap, sentenceUpdates);
        }
        else {
            this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("can\u0027t add sentence with no content", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
        }
    }
    else {
        this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("error with verbatim sanity check", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
    }
}

function VerbatimActions0__splitSentence_5D83414A(this$, id, wordId, above) {
    const wordGroups = VerbatimActions0__getCurrentWordGroups(this$);
    const wordAddress = this$.self["VerbatimActions.VerbatimActions0.get_content"]().words.getIndex(wordId) | 0;
    const boundaryWordGroup = wordGroups.getElementContainingWordAddress(wordAddress);
    if (boundaryWordGroup) {
        if (boundaryWordGroup.wordAddress !== wordAddress) {
            this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("cannot split sentence in middle of word group", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
            return;
        }
    }
    const sentence = VerbatimActions0__getCurrentSentences(this$).getElement(id);
    if (sentence.anchors.startWordId === wordId) {
        this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("cannot split sentence in way that creates empty sentence", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
        return;
    }
    this$.self["VerbatimActions.VerbatimActions0.get_mutationActions"]().splitSentence(id, wordId, above, this$.self["VerbatimActions.VerbatimActions0.get_content"]().getKindSubList(SENTENCE));
}

function VerbatimActions0__removeSentence_Z721C83C5(this$, id) {
    const sentence = VerbatimActions0__getCurrentSentences(this$).getElement(id);
    const sentenceWordRange = getSentenceWordIdRange(sentence, this$.self["VerbatimActions.VerbatimActions0.get_content"]().words);
    const wordGroups = VerbatimActions0__getCurrentWordGroups(this$);
    if (wordGroups.hasElementsIntersectWordIdRange(sentenceWordRange)) {
        this$.self["VerbatimActions.VerbatimActions0.get_alertMessages"]().add(new AlertMessage("error can\u0027t delete sentence that contains word groups, delete word groups first", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
    }
    else {
        VerbatimActions0__updateSentence_5D83414A(this$, id, "", true);
        this$.self["VerbatimActions.VerbatimActions0.get_mutationActions"]().removeSentence(id);
    }
}

// JS BOILERPLATE GENERATED
 