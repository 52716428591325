import { rangeNumber, map, delay } from "../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { indexed } from "../.fable/fable-library.3.1.0-beta-001/Array.js";
import { System_Array__$005B$005D$1_extend_5975E3, IdRange, System_Array__$005B$005D$1_append_1505 } from "./basic-types.fs.js";
import { WORD_GROUP, SENTENCE, WORD } from "./elements/element-types.fs.js";
import { join } from "../.fable/fable-library.3.1.0-beta-001/String.js";

export function wordIndexRangeToWordStrings(indexRange, words) {
    const wordElements = words.elements;
    return Array.from(delay(() => map((i) => wordElements[i].text, rangeNumber(indexRange.starts, 1, indexRange.ends))));
}

export function wordIdRangeToWordStrings(idRange, words) {
    const wordsSeq = words.idRangeAsElementSeq(idRange);
    return Array.from(delay(() => map((word) => word.text, wordsSeq)));
}

export function wordStringsToWordElements(words, startIndex, indexToId) {
    const wordElements = [];
    const arr = indexed(words);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const word = forLoopVar[1];
        const index = forLoopVar[0] | 0;
        System_Array__$005B$005D$1_append_1505(wordElements, {
            id: indexToId[((startIndex + index))],
            kind: WORD,
            text: word,
        });
    }
    return wordElements;
}

export function getContentStringFromWordIdMaxChars(wordId, words, maxChars) {
    const index = words.getIndex(wordId) | 0;
    if (index === -1) {
        return "";
    }
    const resultWords = [];
    let charCount = 0;
    let i = index | 0;
    const wordElements = words.elements;
    while ((charCount < maxChars) ? (i < wordElements.length) : false) {
        const word = wordElements[i];
        const text = word.text;
        System_Array__$005B$005D$1_append_1505(resultWords, text);
        charCount = (charCount + text.length);
        i = (i + 1);
    }
    return join(" ", resultWords);
}

export function getSentenceWordIdRange(sentence, words) {
    const startWordId = sentence.anchors.startWordId;
    const endWordId = words.prevId(sentence.anchors.endWordIdExclusive);
    return new IdRange(startWordId, endWordId);
}

export function getSentenceWordStrings(sentence, words) {
    const wordRange = getSentenceWordIdRange(sentence, words);
    return wordIdRangeToWordStrings(wordRange, words);
}

export function getSentencesWordStrings(sentences) {
    const result = [];
    const arr = sentences.elements;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const sentence = arr[idx];
        System_Array__$005B$005D$1_extend_5975E3(result, getSentenceWordStrings(sentence, sentences.words));
    }
    return result;
}

export function getElementEditableContentString(element, words) {
    if (element.kind === SENTENCE) {
        return join(" ", getSentenceWordStrings(element, words));
    }
    else if (element.kind === WORD_GROUP) {
        throw (new Error("word groups do not have editable content"));
    }
    else {
        return element.content;
    }
}

export function getWordGroupTranscriptText(group, words) {
    const wordRange = new IdRange(group.anchors.startWordId, group.anchors.endWordId);
    const words_1 = wordIdRangeToWordStrings(wordRange, words);
    return join(" ", words_1);
}

export const wordGroupKindSymbols = {
    SIC: "$",
    TRICKY: "~",
    VOCAB: "",
};

export function getWordGroupJWScriptRepresentation(group, words) {
    let result = "\u003c";
    const note = group.content.note ? (group.content.note) : "";
    const delimiter = (note.indexOf("=") >= 0) ? "|" : "=";
    result = (result + ((wordGroupKindSymbols[group.subKind])));
    result = (result + getWordGroupTranscriptText(group, words));
    if (note) {
        result = ((result + delimiter) + note);
    }
    result = (result + "\u003e");
    return result;
}

export function getElementVersionDescriptiveContent(element, words) {
    const kind = element.kind;
    if (kind === WORD_GROUP) {
        return getWordGroupJWScriptRepresentation(element, words);
    }
    else if (kind === SENTENCE) {
        const sentenceWords = element.words;
        return join(" ", Array.from(delay(() => map((word) => word.text, sentenceWords))));
    }
    else {
        return element.content;
    }
}

