import { FSharpRef } from "../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type } from "../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { interpolate, toText } from "../../../../.fable/fable-library.3.1.0-beta-001/String.js";

export class TranslationActions0 {
    constructor() {
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = s0.contents;
        this["init@15-7"] = 1;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    addUpdate(id, translationLanguage, translation) {
        return TranslationActions0__addUpdate_30230F9B(this, id, translationLanguage, translation)
    }
    getTranslationElementId(id, translationLanguage) {
        return TranslationActions0__getTranslationElementId_Z384F8060(this, id, translationLanguage)
    }
    revert(versionObject) {
        return TranslationActions0__revert_4E60E31B(this, versionObject)
    }
}

export function TranslationActions0$reflection() {
    return class_type("TranslationActions.TranslationActions0", void 0, TranslationActions0);
}

export function TranslationActions0_$ctor() {
    return new TranslationActions0();
}

function TranslationActions0__getTranslationElementId_Z384F8060(this$, id, translationLanguage) {
    return toText(interpolate("TRANSLATION:%P()/%P()", [id, translationLanguage]));
}

function TranslationActions0__addUpdate_30230F9B(this$, id, translationLanguage, translation) {
    this$.self["TranslationActions.TranslationActions0.get_mutationActions"]().addUpdateTranslation(id, translationLanguage, translation);
    return TranslationActions0__getTranslationElementId_Z384F8060(this$, id, translationLanguage);
}

function TranslationActions0__revert_4E60E31B(this$, versionObject) {
    this$.self["TranslationActions.TranslationActions0.get_mutationActions"]().revertTranslation(versionObject);
}

// JS BOILERPLATE GENERATED
 