import React from 'react';
import {observer} from "mobx-react";
import {appRoot, scriptEditorModel} from "./app-root.fs.js";
// import {useHistory} from 'react-router-dom';
// import {appRoot, auth, scriptEditorModel} from "./app-root.fs.js";
// import {signInWithGoogle, firebaseAuth} from "./masala-lib/editorial/db/firebase.auth.js";
import { SimpleStatusBar } from './containers/simple-status-bar';

export const StatusBar = observer( () => {
  const elementText = scriptEditorModel && appRoot.episodeKey !== "" ?
    `${scriptEditorModel.episodeKey}/${scriptEditorModel.currentChapterTitle}` : '';
  return <SimpleStatusBar element={elementText} />;
});

//
// // TODO rework
//
// const StatusBarElement = ({children}) => {
//   return (
//    <div
//      style = {{
//        paddingTop: 10,
//        paddingLeft: 30,
//        paddingRight: 40,
//        display: 'inline-block',
//      }}
//    >
//      {children}
//    </div>
//   );
// };
//
// export const OldStatusBar = observer( () => {
//   const history = useHistory();
//
//   const handleClose = e => {
//     e.preventDefault();
//     appRoot.closeEpisode();
//     history.push("/");
//   };
//
//   return (
//     <div className={'status-bar'}>
//       {/*// for now, appRoot.episodeKey is the proxy to indicate if an episode is opened or not. */}
//       {/*// todo: also reset the scriptEditorModal */}
//       {scriptEditorModel && appRoot.episodeKey !== "" ? (
//         <div className='status-bar-element status-bar-chapter'>{scriptEditorModel.episodeKey}/{scriptEditorModel.currentChapterTitle}</div>
//       ) : null}
//       <div style={{display:'inline-block', float:'right'}}>
//         {(auth.user) ?
//           <>
//             <img style={{width:35, height:35,  objectFit:'cover', borderRadius:'50%',  padding:3}} src={auth.user.photoURL}/>
//             {/*<span>{auth.user.email}</span>*/}
//             <span style={{textDecoration:'underline', marginLeft:6, marginRight:6}} onClick={() => firebaseAuth.signOut()}>(Sign-out)</span>
//           </>
//           : <span style={{textDecoration:'underline', marginLeft:6, marginRight:6, fontWeight:900, fontSize: "1.5em", color:"red", background:"yellow"}} onClick={signInWithGoogle}>(Sign-in)</span>
//         }
//         <a
//           href={'https://jiveworld.slite.com/api/s/note/Cp7aFgzKqKAvCx5cLUMqeu/Script-Editor-Cheat-Sheet'}
//           target="_blank"
//         >
//           H&nbsp;
//         </a>
//         {/*<NavLink to="/">X&nbsp;</NavLink>*/}
//         <a href='#' onClick={handleClose}>X&nbsp;</a>
//       </div>
//     </div>
//   );
// });
//
