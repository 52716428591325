import { renderView } from "./word-range-select-view.js";
import { FSharpRef } from "../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { randomString } from "../.././utils.js";
import { Component } from "react";
import { class_type, obj_type } from "../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { getKindFromId, domIdToElementId, IdRange } from "../../basic-types.fs.js";
import { WORD } from "../../elements/element-types.fs.js";
import { makeAdhocWordRange } from "../../elements/adhoc-word-range.fs.js";
import { ElementList } from "../../elements/element-list.fs.js";
import { StyleLayer } from "./style-painting/style-layers.fs.js";
import { observer } from "mobx-react";

export class WordRangeSelect0 extends Component {
    constructor(initialProps) {
        super(initialProps);
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = s0.contents;
        const clo2 = (this.self.props).setWordRangeSelection;
        this.setWordRangeSelection = ((arg20) => {
            clo2(arg20);
        });
        const clo2_1 = (this.self.props).getWordRangeSelection;
        this.getWordRangeSelection = (clo2_1);
        this.domScope = randomString(8);
        this["init@20-12"] = 1;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    render() {
        let wordRange, styleLayers, wordGroups;
        const _ = this;
        return renderView((wordRange = WordRangeSelect0__displayWordIndexRange(_), (styleLayers = WordRangeSelect0__styleLayers(_), (wordGroups = WordRangeSelect0__wordGroups(_), {
            domScope: _.domScope,
            domainWords: WordRangeSelect0__domainWords(_),
            onClick: (event) => {
                WordRangeSelect0__handleClick_Z11029FF1(_, event);
            },
            styleLayers: styleLayers,
            wordGroups: wordGroups,
            wordRange: wordRange,
        }))));
    }
}

export function WordRangeSelect0$reflection() {
    return class_type("WordRangeSelect.WordRangeSelect0", void 0, WordRangeSelect0, class_type("Fable.React.Component`2", [obj_type, obj_type]));
}

export function WordRangeSelect0_$ctor_4E60E31B(initialProps) {
    return new WordRangeSelect0(initialProps);
}

function WordRangeSelect0__givenStyleLayers(this$) {
    return (this$.self.props).givenStyleLayers;
}

function WordRangeSelect0__wordGroups(this$) {
    return (this$.self.props).wordGroups;
}

function WordRangeSelect0__domainWords(this$) {
    return (this$.self.props).domainWords;
}

function WordRangeSelect0__displayWordRange(this$) {
    return (this$.self.props).displayWordRange;
}

function WordRangeSelect0__displayWordIndexRange(this$) {
    return WordRangeSelect0__domainWords(this$).idRangeToIndexRange(WordRangeSelect0__displayWordRange(this$));
}

function WordRangeSelect0__handleWordClick_434A1DD4(this$, event, id) {
    const currentSelection = this$.getWordRangeSelection();
    if (event.getModifierState("Shift") ? currentSelection : false) {
        this$.setWordRangeSelection(new IdRange(currentSelection.starts, id));
    }
    else {
        this$.setWordRangeSelection(new IdRange(id, id));
    }
}

function WordRangeSelect0__handleClick_Z11029FF1(this$, event) {
    const domId = event.target.id;
    const id = domIdToElementId(this$.domScope, domId);
    const kind = getKindFromId(id);
    if (kind === WORD) {
        WordRangeSelect0__handleWordClick_434A1DD4(this$, event, id);
    }
}

function WordRangeSelect0__wordSelectionStyleLayer(this$) {
    const currentSelection = this$.getWordRangeSelection();
    const els = currentSelection ? [makeAdhocWordRange(currentSelection, WordRangeSelect0__domainWords(this$))] : [];
    const elements = ElementList(els, null, null, WordRangeSelect0__domainWords(this$), null, null, null);
    return new StyleLayer(["word-range-selection"], elements, this$.domScope, true, null);
}

function WordRangeSelect0__styleLayers(this$) {
    const result = new Map(WordRangeSelect0__givenStyleLayers(this$).entries());
    result.set("wordSelection", WordRangeSelect0__wordSelectionStyleLayer(this$));
    return result;
}

export const WordRangeSelect = (() => {
    return observer(WordRangeSelect0);
})();

