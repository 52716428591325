import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Root from './containers/root';
import { DefaultRoute } from './screens/default-route';
import { NotFoundScreen } from './screens/not-found-screen';
import { LoginScreen } from './screens/login-screen';
import { HomeScreen } from './screens/home-screen';

export default () => (
  <Router>
    <Switch>
      <Route path="/" exact component={DefaultRoute} />
      <Route path="/login" exact component={LoginScreen} />
      <Route path="/home" exact component={HomeScreen} />
      <Route path="/episodes/:scriptKey/:jumpId" exact component={Root} />
      <Route path="/episodes/:scriptKey" exact component={Root} />
      <Route component={NotFoundScreen} />
    </Switch>
  </Router>
);
