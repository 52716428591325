import { Record } from "../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { record_type, int32_type, string_type } from "../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { IdRange$reflection } from "../basic-types.fs.js";
import { ADHOC_WORD_RANGE } from "./element-types.fs.js";

export class AdhocWordRange extends Record {
    constructor(kind, id, range, wordAddress, endWordAddress) {
        super();
        this.kind = kind;
        this.id = id;
        this.range = range;
        this.wordAddress = (wordAddress | 0);
        this.endWordAddress = (endWordAddress | 0);
    }
}

export function AdhocWordRange$reflection() {
    return record_type("AdhocWordRange.AdhocWordRange", [], AdhocWordRange, () => [["kind", string_type], ["id", string_type], ["range", IdRange$reflection()], ["wordAddress", int32_type], ["endWordAddress", int32_type]]);
}

export function makeAdhocWordRange(range, words) {
    const wordAddress = words.getIndex(range.starts) | 0;
    const endWordAddress = words.getIndex(range.ends) | 0;
    return (new AdhocWordRange(ADHOC_WORD_RANGE, (((ADHOC_WORD_RANGE + ":") + range.starts) + ":") + range.ends, range, wordAddress, endWordAddress));
}

export function isWordRange(element) {
    return element.kind === ADHOC_WORD_RANGE;
}

