import { deploymentConfig } from '../../deployment-config.js';
// import fetch from 'node-fetch';


// const masalaServer = 'https://masala-v1-server.jiveworld.com';
const masalaServer = deploymentConfig.falconServerUrl;

export async function fetchTextFromGoogleDoc(url) {
  let result = await fetch(`${masalaServer}/jw_script_text_from_url?url=${url}`);
  const text = await result.text();
  return text;
}
