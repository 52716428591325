import { Precedence } from "./masala-lib/elements/precedence.fs.js";
import { METADATA_URL as METADATA_URL_1, WORD, METADATA_BLOCK, structuralKinds, WORD_GROUP, TRANSLATION, CHAPTER_TITLE, PASSAGE_HINT, SENTENCE, CULTURAL_NOTE, SPEAKER_LABEL, PARAGRAPH_BREAK, CHAPTER_SUMMARY, CHAPTER_COMPLETE } from "./masala-lib/elements/element-types.fs.js";
import { FSharpRef } from "./.fable/fable-library.3.1.0-beta-001/Types.js";
import { SimpleElementList, EmptyElementList } from "./masala-lib/elements/element-list.fs.js";
import { byFilter, openFilter, unfilledFilter, structuralFilter, sentenceFilter, sicFilter, trickyFilter, vocabFilter, wordGroupFilter, FilterModel, AREPARTICIPANT, ASSIGNED, FilterDef, regexTermParser, WARNING } from "./masala-lib/editorial/filters/filters-model.fs.js";
import { IdRange, getKindFromId, System_Array__$005B$005D$1_append_1505 } from "./masala-lib/basic-types.fs.js";
import { runInAction, reaction } from "mobx";
import { class_type } from "./.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { tryFind, contains } from "./.fable/fable-library.3.1.0-beta-001/Array.js";
import { stringHash } from "./.fable/fable-library.3.1.0-beta-001/Util.js";
import { auth, mutationActions, validations, alertMessages, wordGroupActions, metadataBlockActions, translationActions, verbatimActions, structuralActions } from "./app-root.fs.js";
import { comparer } from "./masala-lib/bindings/mobx.fs.js";
import lodash from "lodash";
import { AlertMessage, Alert } from "./masala-lib/alert-messages.fs.js";
import { interpolate, toText, join } from "./.fable/fable-library.3.1.0-beta-001/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./masala-lib/promise/promise.fs.js";
import { promise } from "./masala-lib/promise/promise-impl.fs.js";
import { value as value_1 } from "./.fable/fable-library.3.1.0-beta-001/Option.js";
import { deploymentConfig } from "./masala-lib/./deployment-config.js";

export const collapseOrder = Precedence([CHAPTER_COMPLETE, CHAPTER_SUMMARY, PARAGRAPH_BREAK, SPEAKER_LABEL, CULTURAL_NOTE, SENTENCE, PASSAGE_HINT, CHAPTER_TITLE]);

export const NORMAL = "NORMAL";

export const EDITING = "EDITING";

export const LOCKED = "LOCKED";

export const DEACTIVATED = "DEACTIVATED";

export const CHOICE_MODE = "CHOICE_MODE";

export class ScriptEditorModel0 {
    constructor() {
        let clo1;
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = (s0.contents);
        this.elements = EmptyElementList;
        this.words = EmptyElementList;
        this.translations = EmptyElementList;
        this.translationLanguage = (null);
        this.editEnabled = true;
        this.collapse = false;
        this.focusedLineId = (null);
        this.wordRangeSelection = (null);
        this.editingId = (null);
        this.currentChapterTitle = "";
        this.episodeKey = "";
        this.markedLineId = (null);
        this.kbDeactivated = false;
        this.getLineEditBuffer = (null);
        this.choiceModalMode = false;
        this.choiceModalHtml = "";
        const disposers = [];
        const warningFilter = new FilterDef(WARNING, true, (t) => "#warning ", (term) => {
            regexTermParser(WARNING, "#warning", term);
        }, (term_1, el) => (this.self.validationWarnings.getElement(el.id)));
        const assignedToSelfFilter = new FilterDef(ASSIGNED, true, (t_1) => "#assigned ", (term_2) => {
            regexTermParser(ASSIGNED, "#assigned", term_2);
        }, (term_3, el_1) => ScriptEditorModel0__selfAssigned_Z2A90BB13(this, el_1));
        const areParticipantFilter = new FilterDef(AREPARTICIPANT, true, (t_2) => "#areparticipant ", (term_4) => {
            regexTermParser(AREPARTICIPANT, "#areparticipant", term_4);
        }, (term_5, el_2) => ScriptEditorModel0__selfParticipant_Z2A90BB13(this, el_2));
        this.filters = FilterModel([wordGroupFilter, vocabFilter, trickyFilter, sicFilter, sentenceFilter, structuralFilter, unfilledFilter, openFilter, byFilter, warningFilter, assignedToSelfFilter, areParticipantFilter]);
        this["init@39-22"] = 1;
        this.lineElements$RDxdBuR8lA6E = null;
        this.visibleElements$P0GdFNBYmxOb = null;
        makeObservable(this, {
            elements: observable.ref,
            editEnabled: observable.ref,
            collapse: observable.ref,
            focusedLineId: observable.ref,
            wordRangeSelection: observable.ref,
            editingId: observable.ref,
            currentChapterTitle: observable.ref,
            episodeKey: observable.ref,
            markedLineId: observable.ref,
            kbDeactivated: observable.ref,
            choiceModalMode: observable.ref,
            choiceModalHtml: observable.ref,
            mode: computed,
            currentWordId: computed,
            focusedElementId: computed,
            searchResult: computed,
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
        System_Array__$005B$005D$1_append_1505(disposers, (clo1 = reaction((() => this.focusedLineId), () => {
            ScriptEditorModel0__adjustCurrentWordIdForFocusedLineElement(this);
        }, {}), () => {
            clo1();
        }));
    }
    abortLineEdit() {
        return ScriptEditorModel0__abortLineEdit(this)
    }
    cancelSelections() {
        return ScriptEditorModel0__cancelSelections(this)
    }
    copyMetadataToClipboard() {
        return ScriptEditorModel0__copyMetadataToClipboard(this)
    }
    copySharableElementLinkToClipboard() {
        return ScriptEditorModel0__copySharableElementLinkToClipboard(this)
    }
    createMetadataUrlBlock() {
        return ScriptEditorModel0__createMetadataUrlBlock(this)
    }
    createOrEditTranslationAtFocusedLine() {
        return ScriptEditorModel0__createOrEditTranslationAtFocusedLine(this)
    }
    createStucturalAtFocusedLine(kind) {
        return ScriptEditorModel0__createStucturalAtFocusedLine_Z721C83C5(this, kind)
    }
    createWordGroupWithWordSelection(kind) {
        return ScriptEditorModel0__createWordGroupWithWordSelection_Z721C83C5(this, kind)
    }
    cursorLineDown() {
        return ScriptEditorModel0__cursorLineDown(this)
    }
    cursorLineUp() {
        return ScriptEditorModel0__cursorLineUp(this)
    }
    displayLineCharCount() {
        return ScriptEditorModel0__displayLineCharCount(this)
    }
    editFocusedLine() {
        return ScriptEditorModel0__editFocusedLine(this)
    }
    markFocusedLine() {
        return ScriptEditorModel0__markFocusedLine(this)
    }
    moveMarked() {
        return ScriptEditorModel0__moveMarked(this)
    }
    nextSearchResult() {
        return ScriptEditorModel0__nextSearchResult(this)
    }
    prevSearchResult() {
        return ScriptEditorModel0__prevSearchResult(this)
    }
    removeFocused() {
        return ScriptEditorModel0__removeFocused(this)
    }
    revertToVersion(versionObject) {
        return ScriptEditorModel0__revertToVersion_4E60E31B(this, versionObject)
    }
    saveLineEdit() {
        return ScriptEditorModel0__saveLineEdit(this)
    }
    setChoiceModalMode(choicesHtml) {
        return ScriptEditorModel0__setChoiceModalMode_Z721C83C5(this, choicesHtml)
    }
    setCurrentWordId(id) {
        return ScriptEditorModel0__setCurrentWordId_Z721C83C5(this, id)
    }
    setEditingId(id) {
        return ScriptEditorModel0__setEditingId_Z721C83C5(this, id)
    }
    setElementList(elements0) {
        return ScriptEditorModel0__setElementList_Z1712FFE(this, elements0)
    }
    setFocusedElementId(id) {
        return ScriptEditorModel0__setFocusedElementId_Z721C83C5(this, id)
    }
    setFocusedLineId(id) {
        return ScriptEditorModel0__setFocusedLineId_Z721C83C5(this, id)
    }
    setFocusedWarningSuppression(suppress) {
        return ScriptEditorModel0__setFocusedWarningSuppression_Z1FBCCD16(this, suppress)
    }
    setGetLineEditBuffer(f) {
        return ScriptEditorModel0__setGetLineEditBuffer_3689BAF1(this, f)
    }
    setKbDeactivated(value) {
        return ScriptEditorModel0__setKbDeactivated_Z1FBCCD16(this, value)
    }
    setTranslationLanguage(language) {
        return ScriptEditorModel0__setTranslationLanguage_Z721C83C5(this, language)
    }
    setWordRangeSelection(range0) {
        return ScriptEditorModel0__setWordRangeSelection_Z13EDD9F8(this, range0)
    }
    splitSentence(direction) {
        return ScriptEditorModel0__splitSentence_Z524259A4(this, direction)
    }
    toggleCollapseExpand() {
        return ScriptEditorModel0__toggleCollapseExpand(this)
    }
    wordRangeSelectTo(id) {
        return ScriptEditorModel0__wordRangeSelectTo_Z721C83C5(this, id)
    }
    get currentWordId() {
        return ScriptEditorModel0__currentWordId(this)
    }
    get focusedElement() {
        return ScriptEditorModel0__focusedElement(this)
    }
    get focusedElementId() {
        return ScriptEditorModel0__focusedElementId(this)
    }
    get focusedElementValidatorWarning() {
        return ScriptEditorModel0__focusedElementValidatorWarning(this)
    }
    get focusedLineElement() {
        return ScriptEditorModel0__focusedLineElement(this)
    }
    get isEditing() {
        return ScriptEditorModel0__isEditing(this)
    }
    get lineElements() {
        return ScriptEditorModel0__lineElements(this)
    }
    get markedLineElement() {
        return ScriptEditorModel0__markedLineElement(this)
    }
    get mode() {
        return ScriptEditorModel0__mode(this)
    }
    get searchResult() {
        return ScriptEditorModel0__searchResult(this)
    }
    get sentences() {
        return ScriptEditorModel0__sentences(this)
    }
    get validationWarnings() {
        return ScriptEditorModel0__validationWarnings(this)
    }
    get visibleElements() {
        return ScriptEditorModel0__visibleElements(this)
    }
    get wordGroups() {
        return ScriptEditorModel0__wordGroups(this)
    }
}

export function ScriptEditorModel0$reflection() {
    return class_type("ScriptEditorModel.ScriptEditorModel0", void 0, ScriptEditorModel0);
}

export function ScriptEditorModel0_$ctor() {
    return new ScriptEditorModel0();
}

function ScriptEditorModel0__setElementList_Z1712FFE(this$, elements0) {
    this$.episodeKey = elements0.episodeKey;
    this$.words = elements0.words;
    this$.elements = elements0;
    this$.translations = this$.elements.getKindSubList(TRANSLATION);
}

function ScriptEditorModel0__setTranslationLanguage_Z721C83C5(this$, language) {
    this$.translationLanguage = language;
}

function ScriptEditorModel0__wordGroups(this$) {
    return this$.elements.getKindSubList(WORD_GROUP);
}

function ScriptEditorModel0__sentences(this$) {
    return this$.elements.getKindSubList(SENTENCE);
}

function ScriptEditorModel0__lineElements(this$) {  if (!this$.lineElements$RDxdBuR8lA6E) { this$.lineElements$RDxdBuR8lA6E = computed(() => {
    if (this$.elements) {
        return this$.elements.filter((el) => (el.kind !== WORD_GROUP));
    }
    else {
        return null;
    }
})}; return this$.lineElements$RDxdBuR8lA6E.get(); }

function ScriptEditorModel0__visibleElements(this$) {  if (!this$.visibleElements$P0GdFNBYmxOb) { this$.visibleElements$P0GdFNBYmxOb = computed(() => {
    const all = ScriptEditorModel0__lineElements(this$);
    if (all ? this$.collapse : false) {
        return all.filter(collapseOrder.precedenceAtLeastFilter(PASSAGE_HINT));
    }
    else {
        return all;
    }
})}; return this$.visibleElements$P0GdFNBYmxOb.get(); }

function ScriptEditorModel0__toggleCollapseExpand(this$) {
    this$.collapse = (!this$.collapse);
}

function ScriptEditorModel0__setEditingId_Z721C83C5(this$, id) {
    this$.editingId = id;
}

function ScriptEditorModel0__isEditing(this$) {
    return this$.editingId;
}

function ScriptEditorModel0__isVerbatimEditing(this$) {
    return false;
}

function ScriptEditorModel0__mode(this$) {
    if (this$.kbDeactivated) {
        return "DEACTIVATED";
    }
    else if (this$.choiceModalMode) {
        return "CHOICE_MODE";
    }
    else if (!this$.editEnabled) {
        return "LOCKED";
    }
    else if (ScriptEditorModel0__isEditing(this$)) {
        return "EDITING";
    }
    else {
        return "NORMAL";
    }
}

function ScriptEditorModel0__setGetLineEditBuffer_3689BAF1(this$, f) {
    this$.getLineEditBuffer = f;
}

function ScriptEditorModel0__saveContent_433E080(this$, id, content) {
    const kind = getKindFromId(id);
    if (contains(kind, structuralKinds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })) {
        structuralActions.updateContent(id, content);
    }
    else if (kind === SENTENCE) {
        verbatimActions.updateSentence(id, content);
    }
    else if (kind === TRANSLATION) {
        const translationElement = this$.elements.getElement(id);
        const elementId = translationElement.elementId;
        const language = translationElement.translationLanguage;
        const value = translationActions.addUpdate(elementId, language, content);
        void value;
    }
    else if (kind === METADATA_BLOCK) {
        metadataBlockActions.updateContent(id, content);
    }
}

function ScriptEditorModel0__saveLineEdit(this$) {
    if (this$.getLineEditBuffer) {
        const content = this$.getLineEditBuffer();
        ScriptEditorModel0__saveContent_433E080(this$, this$.editingId, content);
        ScriptEditorModel0__setEditingId_Z721C83C5(this$, null);
    }
}

function ScriptEditorModel0__abortLineEdit(this$) {
    ScriptEditorModel0__setEditingId_Z721C83C5(this$, null);
}

function ScriptEditorModel0__cancelSelections(this$) {
    this$.markedLineId = (null);
    this$.wordRangeSelection = (null);
}

function ScriptEditorModel0__setFocusedLineId_Z721C83C5(this$, id) {
    this$.focusedLineId = id;
    if (ScriptEditorModel0__isEditing(this$) ? (this$.focusedLineId !== this$.editingId) : false) {
        ScriptEditorModel0__saveLineEdit(this$);
    }
}

function ScriptEditorModel0__normalizeWordRange_Z13EDD9F8(this$, range) {
    if (range) {
        const startIndex = this$.words.getIndex(range.starts) | 0;
        const endIndex = this$.words.getIndex(range.ends) | 0;
        if (startIndex > endIndex) {
            return new IdRange(range.ends, range.starts);
        }
        else {
            return range;
        }
    }
    else {
        return range;
    }
}

function ScriptEditorModel0__setWordRangeSelection_Z13EDD9F8(this$, range0) {
    const range = ScriptEditorModel0__normalizeWordRange_Z13EDD9F8(this$, range0);
    if (!comparer.shallow(range, this$.wordRangeSelection)) {
        runInAction(() => {
            if (range) {
                const shouldFocusSentence = ScriptEditorModel0__sentences(this$).getElementContainingWordId(range.starts);
                const shouldFocusLineId = shouldFocusSentence ? shouldFocusSentence.id : (null);
                ScriptEditorModel0__setFocusedLineId_Z721C83C5(this$, shouldFocusLineId);
            }
            this$.wordRangeSelection = range;
        });
    }
}

function ScriptEditorModel0__setCurrentWordId_Z721C83C5(this$, id) {
    if (lodash.isNull(id)) {
        this$.wordRangeSelection = (null);
    }
    ScriptEditorModel0__setWordRangeSelection_Z13EDD9F8(this$, new IdRange(id, id));
}

function ScriptEditorModel0__wordRangeSelectTo_Z721C83C5(this$, id) {
    if (this$.wordRangeSelection) {
        ScriptEditorModel0__setWordRangeSelection_Z13EDD9F8(this$, new IdRange(this$.wordRangeSelection.starts, id));
    }
    else {
        ScriptEditorModel0__setCurrentWordId_Z721C83C5(this$, id);
    }
}

function ScriptEditorModel0__setFocusedElementId_Z721C83C5(this$, id) {
    if (id) {
        const kind = getKindFromId(id);
        if (kind === WORD_GROUP) {
            const group = this$.elements.getElement(id);
            ScriptEditorModel0__setCurrentWordId_Z721C83C5(this$, group.anchors.startWordId);
        }
        else if (kind === WORD) {
            ScriptEditorModel0__setCurrentWordId_Z721C83C5(this$, id);
        }
        else if (kind === SENTENCE) {
            ScriptEditorModel0__setCurrentWordId_Z721C83C5(this$, null);
            this$.focusedLineId = id;
        }
        else {
            this$.focusedLineId = id;
        }
    }
}

function ScriptEditorModel0__focusedLineElement(this$) {
    if (this$.elements) {
        return this$.elements.getElement(this$.self.focusedLineId);
    }
    else {
        return null;
    }
}

function ScriptEditorModel0__currentWordId(this$) {
    if (this$.wordRangeSelection) {
        return this$.wordRangeSelection.starts;
    }
    else {
        return null;
    }
}

function ScriptEditorModel0__focusedElementId(this$) {
    const focusedWordId = this$.self.currentWordId;
    if (focusedWordId) {
        const focusedWordGroup = ScriptEditorModel0__wordGroups(this$).getElementContainingWordId(focusedWordId);
        if (focusedWordGroup) {
            return focusedWordGroup.id;
        }
        else {
            return this$.focusedLineId;
        }
    }
    else {
        return this$.focusedLineId;
    }
}

function ScriptEditorModel0__focusedElement(this$) {
    if (this$.elements) {
        return this$.elements.getElement(this$.self.focusedElementId);
    }
    else {
        return null;
    }
}

function ScriptEditorModel0__adjustCurrentWordIdForFocusedLineElement(this$) {
    const focused = ScriptEditorModel0__focusedLineElement(this$);
    if (focused ? this$.self.currentWordId : false) {
        if (focused.kind !== SENTENCE) {
            this$.wordRangeSelection = (null);
        }
        else if (!(focused === this$.self.sentences.getElementContainingWordId(this$.self.currentWordId))) {
            this$.wordRangeSelection = (null);
        }
    }
}

function ScriptEditorModel0__searchResultCursorPointId(this$) {
    if ((this$.self.focusedElementId ? (getKindFromId(this$.self.focusedElementId) === SENTENCE) : false) ? this$.self.currentWordId : false) {
        return this$.self.currentWordId;
    }
    else {
        return this$.self.focusedElementId;
    }
}

function ScriptEditorModel0__cursorLineUp(this$) {
    const next = ScriptEditorModel0__visibleElements(this$).prevId(this$.focusedLineId);
    if (next) {
        this$.focusedLineId = next;
    }
}

function ScriptEditorModel0__cursorLineDown(this$) {
    const next = ScriptEditorModel0__visibleElements(this$).nextId(this$.focusedLineId);
    if (next) {
        this$.focusedLineId = next;
    }
}

function ScriptEditorModel0__editFocusedLine(this$) {
    ScriptEditorModel0__setEditingId_Z721C83C5(this$, this$.focusedLineId);
}

function ScriptEditorModel0__markFocusedLine(this$) {
    if (this$.focusedLineId) {
        const kind = getKindFromId(this$.focusedLineId);
        if (contains(kind, structuralKinds, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        })) {
            this$.markedLineId = this$.focusedLineId;
        }
    }
}

function ScriptEditorModel0__markedLineElement(this$) {
    if (this$.markedLineId) {
        return this$.elements.getElement(this$.markedLineId);
    }
    else {
        return null;
    }
}

function ScriptEditorModel0__moveMarked(this$) {
    if (this$.markedLineId ? this$.focusedLineId : false) {
        structuralActions.move(this$.markedLineId, this$.focusedLineId);
        this$.markedLineId = (null);
    }
}

function ScriptEditorModel0__createWordGroupWithWordSelection_Z721C83C5(this$, kind) {
    return wordGroupActions.createFromCurrentWordSelection(kind);
}

function ScriptEditorModel0__createStucturalAtFocusedLine_Z721C83C5(this$, kind) {
    const newId = structuralActions.create(kind, this$.focusedLineId);
    ScriptEditorModel0__setFocusedLineId_Z721C83C5(this$, newId);
    ScriptEditorModel0__editFocusedLine(this$);
}

function ScriptEditorModel0__createOrEditTranslationAtFocusedLine(this$) {
    if (this$.focusedLineId) {
        const kind = getKindFromId(this$.focusedLineId);
        if (kind === TRANSLATION) {
            ScriptEditorModel0__editFocusedLine(this$);
        }
        else {
            const translationId = translationActions.getTranslationElementId(this$.focusedLineId, this$.translationLanguage);
            const translation = this$.translations.getElement(translationId);
            if (translation) {
                ScriptEditorModel0__setFocusedLineId_Z721C83C5(this$, translationId);
                ScriptEditorModel0__editFocusedLine(this$);
            }
            else {
                ScriptEditorModel0__setFocusedLineId_Z721C83C5(this$, translationActions.addUpdate(this$.focusedLineId, this$.translationLanguage, ""));
                ScriptEditorModel0__editFocusedLine(this$);
            }
        }
    }
}

function ScriptEditorModel0__removeFocused(this$) {
    if (this$.focusedLineId) {
        const kind = getKindFromId(this$.focusedLineId);
        if (contains(kind, structuralKinds, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        })) {
            structuralActions.remove(this$.focusedLineId);
        }
        else if (kind === SENTENCE) {
            verbatimActions.removeSentence(this$.focusedLineId);
        }
        else if (kind === METADATA_BLOCK) {
            metadataBlockActions.remove(this$.focusedLineId);
        }
    }
}

function ScriptEditorModel0__nextSearchResult(this$) {
    const cursorPointId = ScriptEditorModel0__searchResultCursorPointId(this$);
    let nextResultId = null;
    const currentResults = this$.self.searchResult;
    if (cursorPointId) {
        nextResultId = currentResults.nextId(cursorPointId, true);
    }
    else if (this$.self.searchResult.elements.length > 0) {
        nextResultId = currentResults.elements[0].id;
    }
    ScriptEditorModel0__setFocusedElementId_Z721C83C5(this$, nextResultId);
}

function ScriptEditorModel0__prevSearchResult(this$) {
    const cursorPointId = ScriptEditorModel0__searchResultCursorPointId(this$);
    let prevResultId = null;
    const currentResults = this$.self.searchResult;
    if (cursorPointId) {
        prevResultId = currentResults.prevId(cursorPointId, true);
    }
    else if (this$.self.searchResult.elements.length > 0) {
        prevResultId = currentResults.elements[0].id;
    }
    ScriptEditorModel0__setFocusedElementId_Z721C83C5(this$, prevResultId);
}

function ScriptEditorModel0__splitSentence_Z524259A4(this$, direction) {
    const splitWordId = this$.self.currentWordId;
    if (splitWordId) {
        const sentence = ScriptEditorModel0__focusedLineElement(this$);
        if (sentence.kind === SENTENCE) {
            verbatimActions.splitSentence(sentence.id, splitWordId, direction);
        }
    }
}

function ScriptEditorModel0__createMetadataUrlBlock(this$) {
    const blocks = this$.elements.getKindSubList(METADATA_BLOCK).elements;
    const metadataUrlBlock = tryFind((b) => (b.subKind === METADATA_URL_1), blocks);
    if (metadataUrlBlock) {
        alertMessages.add(new AlertMessage("cannot add more than one METADATA URL", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
    }
    else {
        const value = metadataBlockActions.create(METADATA_URL_1);
        void value;
    }
}

function ScriptEditorModel0__copyMetadataToClipboard(this$) {
    let lines = [];
    const prefixes = {
        ASSET_LINKS: "/**! ASSET-LINKS\n",
        CAST: "/**! CAST\n",
        METADATA: "/**! METADATA\n",
        METADATA_URL: "/**! METADATA URL\n",
        NOTES: "/**! NOTES\n",
    };
    const blocks = this$.elements.getKindSubList(METADATA_BLOCK).elements;
    for (let idx = 0; idx <= (blocks.length - 1); idx++) {
        const block = blocks[idx];
        System_Array__$005B$005D$1_append_1505(lines, prefixes[block.subKind]);
        System_Array__$005B$005D$1_append_1505(lines, block.content);
        System_Array__$005B$005D$1_append_1505(lines, "*/\n\n");
    }
    const output = join("\n", lines);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (navigator.permissions.query({
        name: "clipboard-write",
        sysex: false,
        userVisibleOnly: false,
    }).then(((_arg1) => {
        const permission = _arg1;
        return value_1(navigator.clipboard).writeText(output);
    })))));
}

function ScriptEditorModel0__copySharableElementLinkToClipboard(this$) {
    let x;
    const baseUrl = deploymentConfig.scriptEditorUrl;
    let target = this$.self.focusedElementId ? this$.self.focusedElementId : this$.self.currentWordId;
    if (x = target, lodash.isNil(x)) {
        return;
    }
    if ((getKindFromId(target) === SENTENCE) ? this$.self.currentWordId : false) {
        target = this$.self.currentWordId;
    }
    const url = toText(interpolate("%P()/episodes/%P()/%P()", [baseUrl, this$.episodeKey, target]));
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (navigator.permissions.query({
        name: "clipboard-write",
        sysex: false,
        userVisibleOnly: false,
    }).then(((_arg2) => {
        const permission = _arg2;
        return value_1(navigator.clipboard).writeText(url);
    })))));
}

function ScriptEditorModel0__validationWarnings(this$) {
    const els = [];
    const arr = validations.activeWarnings;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const warning = arr[idx];
        System_Array__$005B$005D$1_append_1505(els, this$.elements.getElement(warning.elementId));
    }
    return SimpleElementList(els);
}

function ScriptEditorModel0__revertToVersion_4E60E31B(this$, versionObject) {
    const kind = versionObject.kind;
    if (kind === WORD_GROUP) {
        wordGroupActions.revert(versionObject);
    }
    else if (kind === TRANSLATION) {
        translationActions.revert(versionObject);
    }
    else if (kind === SENTENCE) {
        alertMessages.add(new AlertMessage("revert of sentences not yet supported", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
    }
    else if (contains(kind, structuralKinds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })) {
        structuralActions.revert(versionObject);
    }
}

function ScriptEditorModel0__focusedElementValidatorWarning(this$) {
    const focusedId = this$.self.focusedElementId;
    if (!(lodash.isNull(focusedId))) {
        return (((predicate) => ((array) => tryFind(predicate, array))))((warn) => (warn.elementId === focusedId))(validations.activeWarnings);
    }
    else {
        return null;
    }
}

function ScriptEditorModel0__setFocusedWarningSuppression_Z1FBCCD16(this$, suppress) {
    if (this$.self.focusedElementId) {
        mutationActions.setElementWarningSuppression(this$.self.focusedElementId, suppress);
    }
}

function ScriptEditorModel0__selfAssigned_Z2A90BB13(this$, el) {
    return el.thread.assignee === auth.appUser.id;
}

function ScriptEditorModel0__selfParticipant_Z2A90BB13(this$, el) {
    const currentUserId = auth.appUser.id;
    const participants = el.thread.participants;
    if (participants) {
        return contains(currentUserId, participants, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        });
    }
    else {
        return false;
    }
}

function ScriptEditorModel0__searchResult(this$) {
    return this$.elements.filter(this$.filters.filterFunction);
}

function ScriptEditorModel0__setKbDeactivated_Z1FBCCD16(this$, value) {
    this$.kbDeactivated = value;
}

function ScriptEditorModel0__displayLineCharCount(this$) {
}

function ScriptEditorModel0__setChoiceModalMode_Z721C83C5(this$, choicesHtml) {
    if (choicesHtml) {
        runInAction(() => {
            this$.choiceModalMode = true;
            this$.choiceModalHtml = choicesHtml;
        });
    }
    else {
        this$.choiceModalMode = false;
    }
}

export function ScriptEditorModel() {
    return ScriptEditorModel0_$ctor();
}

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 