import { Precedence } from "./precedence.fs.js";
import { WORD_GROUP, WORD, SENTENCE, SPEAKER_LABEL, PASSAGE_HINT, CULTURAL_NOTE, CHAPTER_SUMMARY, CHAPTER_TITLE, CHAPTER_COMPLETE, PARAGRAPH_BREAK } from "./element-types.fs.js";
import { fastSortOnIntKey } from "../fast-sort-on-int-key.js";

export const elementSecondarySortOrder = Precedence([PARAGRAPH_BREAK, CHAPTER_COMPLETE, CHAPTER_TITLE, CHAPTER_SUMMARY, CULTURAL_NOTE, PASSAGE_HINT, SPEAKER_LABEL, SENTENCE, WORD, WORD_GROUP]);

export const elementKindToSortPrecedence = elementSecondarySortOrder.keyToPrecedenceLevel;

export function elementSortProjection(element) {
    const wordIndex = element.wordAddress | 0;
    const kindPrecedence = ((elementKindToSortPrecedence[element.kind])) | 0;
    return ((wordIndex << 4) | kindPrecedence) | 0;
}

export function sortElements(elements) {
    fastSortOnIntKey(elements, (elementSortProjection));
}

