import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import './index.css';

// TODO turn off mobx strict mode

ReactDOM.render( <App/>, document.getElementById('root'));
