import { alertMessages, scriptEditorModel, mutationActions } from "../app-root.fs.js";
import { StructuralActions0$reflection, StructuralActions0 } from "../masala-lib/editorial/models/actions/structural-actions.fs.js";
import { class_type } from "../.fable/fable-library.3.1.0-beta-001/Reflection.js";

export class StructuralActions1 extends StructuralActions0 {
    constructor() {
        super();
        this["init@8-16"] = 1;
    }
    ["StructuralActions.StructuralActions0.get_mutationActions"]() {
        return mutationActions;
    }
    ["StructuralActions.StructuralActions0.get_content"]() {
        return scriptEditorModel.elements;
    }
    ["StructuralActions.StructuralActions0.get_alertMessages"]() {
        return alertMessages;
    }
}

export function StructuralActions1$reflection() {
    return class_type("ScriptEditorStructuralActions.StructuralActions1", void 0, StructuralActions1, StructuralActions0$reflection());
}

export function StructuralActions1_$ctor() {
    return new StructuralActions1();
}

export function StructuralActions() {
    return StructuralActions1_$ctor();
}

