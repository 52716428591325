
export const SENTENCE = "SENTENCE";

export const WORD_GROUP = "WORD_GROUP";

export const CHAPTER_TITLE = "CHAPTER_TITLE";

export const PASSAGE_HINT = "PASSAGE_HINT";

export const PARAGRAPH_BREAK = "PARAGRAPH_BREAK";

export const SPEAKER_LABEL = "SPEAKER_LABEL";

export const CULTURAL_NOTE = "CULTURAL_NOTE";

export const CHAPTER_SUMMARY = "CHAPTER_SUMMARY";

export const CHAPTER_COMPLETE = "CHAPTER_COMPLETE";

export const WORD = "WORD";

export const TRICKY = "TRICKY";

export const VOCAB = "VOCAB";

export const SIC = "SIC";

export const TRANSLATION = "TRANSLATION";

export const METADATA_BLOCK = "METADATA_BLOCK";

export const METADATA_URL = "METADATA_URL";

export const ADHOC_WORD_RANGE = "ADHOC_WORD_RANGE";

export const structuralKinds = [CHAPTER_TITLE, CHAPTER_SUMMARY, SPEAKER_LABEL, PASSAGE_HINT, PARAGRAPH_BREAK];

