// this file gets sync'ed between:
//   [peer-proj]/src/masala-lib/platform/
//   [masala-lib]/src/platform/browser/

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";


const config = {
    apiKey: "AIzaSyCk7qf6Qbsq2oRwCel1g08st-su4pYDB_0",
    authDomain: "jiveworld.firebaseapp.com",
    databaseURL: "https://jiveworld.firebaseio.com",
    projectId: "jiveworld",
    storageBucket: "jiveworld.appspot.com",
    messagingSenderId: "903123262392",
    appId: "1:903123262392:web:3daaccb9ba95b3e4cf0d4d"
};

function initFirebase() {
  firebase.initializeApp(config);
  firebase.firestore().enablePersistence()
    .catch(function(err) {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
}

initFirebase();

export const db = firebase.firestore();

export const fieldValueDelete = () => firebase.firestore.FieldValue.delete();
export const fieldValueArrayUnion = (...elements) => firebase.firestore.FieldValue.arrayUnion(...elements);
// not sure how to import/reference this, but just '{merge: true}' seemed to work
// export const setOptionsMerge = () => firebase.firestore.SetOptions.merge();

export {firebase};
