import { FSharpRef } from "../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type } from "../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { SENTENCE, WORD_GROUP } from "../../../elements/element-types.fs.js";
import { AlertMessage, Alert } from "../../../alert-messages.fs.js";
import { equals } from "../../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { IdRange, WordGroupDTO, SpanAnchors, WordGroupContent } from "../../../basic-types.fs.js";

export class WordGroupActions0 {
    constructor() {
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = s0.contents;
        this["init@19-5"] = 1;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    create(kind, wordRange) {
        return WordGroupActions0__create_Z59BEDA6D(this, kind, wordRange)
    }
    createFromCurrentWordSelection(kind) {
        return WordGroupActions0__createFromCurrentWordSelection_Z721C83C5(this, kind)
    }
    remove(id) {
        return WordGroupActions0__remove_Z721C83C5(this, id)
    }
    removeFocused() {
        return WordGroupActions0__removeFocused(this)
    }
    revert(versionObject) {
        return WordGroupActions0__revert_4E60E31B(this, versionObject)
    }
    update(data) {
        return WordGroupActions0__update_47D28974(this, data)
    }
}

export function WordGroupActions0$reflection() {
    return class_type("WordGroupActions.WordGroupActions0", void 0, WordGroupActions0);
}

export function WordGroupActions0_$ctor() {
    return new WordGroupActions0();
}

function WordGroupActions0__getCurrentWordGroups(this$) {
    return this$.self["WordGroupActions.WordGroupActions0.get_content"]().getKindSubList(WORD_GROUP);
}

function WordGroupActions0__getCurrentSentences(this$) {
    return this$.self["WordGroupActions.WordGroupActions0.get_content"]().getKindSubList(SENTENCE);
}

function WordGroupActions0__focusedWordGroup(this$) {
    if (this$.self["WordGroupActions.WordGroupActions0.get_currentFocusedElement"]() ? (this$.self["WordGroupActions.WordGroupActions0.get_currentFocusedElement"]().kind === WORD_GROUP) : false) {
        return this$.self["WordGroupActions.WordGroupActions0.get_currentFocusedElement"]();
    }
    else {
        return null;
    }
}

function WordGroupActions0__create_Z59BEDA6D(this$, kind, wordRange) {
    if (WordGroupActions0__getCurrentWordGroups(this$).hasElementsIntersectWordIdRange(wordRange)) {
        this$.self["WordGroupActions.WordGroupActions0.get_alertMessages"]().add(new AlertMessage("can\u0027t create word group overlapping with existing word groups", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
    }
    else {
        const sentences = WordGroupActions0__getCurrentSentences(this$);
        const sentence1 = sentences.getElementContainingWordId(wordRange.starts);
        const sentence2 = sentences.getElementContainingWordId(wordRange.ends);
        if (!equals(sentence1, sentence2)) {
            this$.self["WordGroupActions.WordGroupActions0.get_alertMessages"]().add(new AlertMessage("can\u0027t create word group spanning multiple sentences", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
        }
        else {
            let wordGroupData;
            const content = new WordGroupContent("", "");
            wordGroupData = (new WordGroupDTO(null, kind, new SpanAnchors(wordRange.starts, wordRange.ends), content));
            this$.self["WordGroupActions.WordGroupActions0.get_mutationActions"]().addUpdateWordGroup(wordGroupData);
        }
    }
}

function WordGroupActions0__update_47D28974(this$, data) {
    const wordRange = new IdRange(data.anchors.startWordId, data.anchors.endWordId);
    const intersecting0 = WordGroupActions0__getCurrentWordGroups(this$).getElementsIntersectWordIdRange(wordRange);
    if (intersecting0) {
        const intersecting = intersecting0.filter((el) => (el.id !== data.id));
        if (intersecting.length > 0) {
            this$.self["WordGroupActions.WordGroupActions0.get_alertMessages"]().add(new AlertMessage("can\u0027t update word group to overlap with other word groups", Alert.timestamp, Alert.forceAcknowlege, Alert.level));
            return;
        }
    }
    this$.self["WordGroupActions.WordGroupActions0.get_mutationActions"]().addUpdateWordGroup(data);
}

function WordGroupActions0__remove_Z721C83C5(this$, id) {
    this$.self["WordGroupActions.WordGroupActions0.get_mutationActions"]().removeWordGroup(id);
}

function WordGroupActions0__createFromCurrentWordSelection_Z721C83C5(this$, kind) {
    const wordRange = this$.self["WordGroupActions.WordGroupActions0.get_currentWordSelection"]();
    if (wordRange) {
        WordGroupActions0__create_Z59BEDA6D(this$, kind, wordRange);
    }
}

function WordGroupActions0__removeFocused(this$) {
    const focused = WordGroupActions0__focusedWordGroup(this$);
    if (focused) {
        WordGroupActions0__remove_Z721C83C5(this$, focused.id);
    }
}

function WordGroupActions0__revert_4E60E31B(this$, versionObject) {
    this$.self["WordGroupActions.WordGroupActions0.get_mutationActions"]().revertWordGroup(versionObject);
}

// JS BOILERPLATE GENERATED
 