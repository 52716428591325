import {
  MDBModal,
  MDBModalBody,
} from "mdbreact";
import React from "react";
import { observer } from "mobx-react";
import { scriptEditorModel } from "./app-root.fs.js";

export const ChoiceModeDialog = observer(() => {
  const closeModal = () => {
      scriptEditorModel.setChoiceModalMode(null)
  };

  return (
    <MDBModal size="sm" centered isOpen={scriptEditorModel.choiceModalMode} toggle={closeModal}>
      <MDBModalBody>
          <div dangerouslySetInnerHTML={{__html: scriptEditorModel.choiceModalHtml}}/>
      </MDBModalBody>
    </MDBModal>
  );
});
