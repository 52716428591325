import React from "react";
import { NavLink } from 'react-router-dom';

import '../App.css';

export const NotFoundScreen = () => {
  return (
    <div id={'main-container'}>
      <h3>404 - page not found</h3>
      <NavLink to="/">home</NavLink>
    </div>
  );
};
