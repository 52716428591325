import {reaction, makeObservable, observable} from "mobx";

export const NOT_INITIATED = "NOT_INITIATED";
export const IN_PROGRESS = "IN_PROGRESS";
export const COMPLETE = "COMPLETE";
export const ERROR_NOT_LOADABLE = "ERROR_NOT_LOADABLE";

export class FirestoreDocSet {

  constructor(key, listenMode=true) {
    this.key = key;
    this.listenMode = listenMode;
    this.stateVersion = 1;
    this.status = NOT_INITIATED;
    this.errorInfo = null; // TODO
    this.disposers = [];
    // TODO
    makeObservable(this, {
      stateVersion: observable.ref,
      status: observable.ref,
      errorInfo: observable.ref,
    });
  }

  load() {
    this.status = IN_PROGRESS;
    this.disposers.push(this.listenUpdates(() => this.handleUpdate()));
  }

  close() {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  watchDocument(f, cb) {
    return reaction(f, () => cb());
  }

  clearAllDocs() {
    throw "Abstract method unimplemented";
  }

  get hasAllDocs() {
    throw "Abstract method unimplemented";
  }

  verifyDocSet() {
    if (this.hasAllDocs) {
      this.status = COMPLETE;
    }
  }

  listenFirestoreUpdates() {
    throw "Abstract method unimplemented";
  }

  watchAllDocs(cb) {
    throw "Abstract method unimplemented";
  }

  onSnapshot(docref, callback) {
    // TODO wrap callback in error handling that sets ERROR_NOT_LOADABLE when error (no doc for example)
    if (this.listenMode) {
      return docref.onSnapshot(callback);
    } else {
      docref.get().then(callback);
      return () => null;
    }
  }

  handleUpdate() {
    this.verifyDocSet();
    this.stateVersion++;
  }

  listenUpdates(cb) {
    const disposers = [];
    // TODO need to setup reaction to error info???
    disposers.push(this.watchAllDocs(cb));
    disposers.push(this.listenFirestoreUpdates());
    return () => {for (let d of disposers) d()}
  }
}
