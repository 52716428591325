
export const elementKindAnchorDirection = {
    CHAPTER_COMPLETE: 1,
    CHAPTER_SUMMARY: 1,
    CHAPTER_TITLE: 1,
    CULTURAL_NOTE: 1,
    PARAGRAPH_BREAK: -1,
    PASSAGE_HINT: 1,
    SPEAKER_LABEL: 1,
};

