import { renderView } from "./word-group-editor-view.js";
import { FSharpRef } from "./.fable/fable-library.3.1.0-beta-001/Types.js";
import { WordGroupDTO, SpanAnchors, WordGroupContent, IdRange, System_Array__$005B$005D$1_append_1505 } from "./masala-lib/basic-types.fs.js";
import { autorun } from "mobx";
import { Component } from "react";
import { class_type, obj_type } from "./.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { WORD_GROUP, SENTENCE } from "./masala-lib/elements/element-types.fs.js";
import { getSentenceWordIdRange } from "./masala-lib/content-funcs.fs.js";
import { wordGroupActions } from "./app-root.fs.js";
import { observer } from "mobx-react";

export class WordGroupEditor0 extends Component {
    constructor(initialProps) {
        super(initialProps);
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = s0.contents;
        const disposers = [];
        const sentenceTranslation = "";
        this.noteInputEl = (null);
        this.selectedWordRange = (null);
        this.noteTextValue = "";
        this["init@30-21"] = 1;
        System_Array__$005B$005D$1_append_1505(disposers, autorun(() => {
            WordGroupEditor0__configureEditableValues(this);
        }));
        this.wordGroupSentence$85kuWj7Uay58 = null;
        this.wordGroups$wtU_iUv8RaPW = null;
        makeObservable(this, {
            selectedWordRange: observable.ref,
            noteTextValue: observable.ref,
        });
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    domainWords() {
        return WordGroupEditor0__domainWords(this)
    }
    getSelectedWordRange() {
        return WordGroupEditor0__getSelectedWordRange(this)
    }
    handleNoteTextChange(e) {
        return WordGroupEditor0__handleNoteTextChange_Z5B3E8D2(this, e)
    }
    handleWordGroupChangeSubmit() {
        return WordGroupEditor0__handleWordGroupChangeSubmit(this)
    }
    handleWordGroupDeleteSubmit() {
        return WordGroupEditor0__handleWordGroupDeleteSubmit(this)
    }
    sentenceWordRange() {
        return WordGroupEditor0__sentenceWordRange(this)
    }
    setSelectedWordRange(range) {
        return WordGroupEditor0__setSelectedWordRange_Z13EDD9F8(this, range)
    }
    valuesChanged() {
        return WordGroupEditor0__valuesChanged(this)
    }
    wordGroup() {
        return WordGroupEditor0__wordGroup(this)
    }
    wordGroups() {
        return WordGroupEditor0__wordGroups(this)
    }
    render() {
        const _ = this;
        return renderView(_.self);
    }
}

export function WordGroupEditor0$reflection() {
    return class_type("WordGroupEditor.WordGroupEditor0", void 0, WordGroupEditor0, class_type("Fable.React.Component`2", [obj_type, obj_type]));
}

export function WordGroupEditor0_$ctor_4E60E31B(initialProps) {
    return new WordGroupEditor0(initialProps);
}

function WordGroupEditor0__wordGroup(this$) {
    return (this$.self.props).element;
}

function WordGroupEditor0__content(this$) {
    return (this$.self.props).content;
}

function WordGroupEditor0__domainWords(this$) {
    return WordGroupEditor0__content(this$).words;
}

function WordGroupEditor0__sentences(this$) {
    return WordGroupEditor0__content(this$).getKindSubList(SENTENCE);
}

function WordGroupEditor0__wordGroupSentence(this$) {  if (!this$.wordGroupSentence$85kuWj7Uay58) { this$.wordGroupSentence$85kuWj7Uay58 = computed(() => {
    return WordGroupEditor0__sentences(this$).getElementContainingWordAddress(WordGroupEditor0__wordGroup(this$).wordAddress);
})}; return this$.wordGroupSentence$85kuWj7Uay58.get(); }

function WordGroupEditor0__sentenceWordRange(this$) {
    return getSentenceWordIdRange(WordGroupEditor0__wordGroupSentence(this$), WordGroupEditor0__domainWords(this$));
}

function WordGroupEditor0__wordGroups(this$) {  if (!this$.wordGroups$wtU_iUv8RaPW) { this$.wordGroups$wtU_iUv8RaPW = computed(() => {
    return WordGroupEditor0__content(this$).getKindSubList(WORD_GROUP);
})}; return this$.wordGroups$wtU_iUv8RaPW.get(); }

function WordGroupEditor0__configureEditableValues(this$) {
    let note = WordGroupEditor0__wordGroup(this$).content.note;
    note = (note ? note : "");
    this$.noteTextValue = note.trimStart();
    this$.selectedWordRange = (new IdRange(WordGroupEditor0__wordGroup(this$).anchors.startWordId, WordGroupEditor0__wordGroup(this$).anchors.endWordId));
}

function WordGroupEditor0__revert(this$) {
    WordGroupEditor0__configureEditableValues(this$);
}

function WordGroupEditor0__getSelectedWordRange(this$) {
    return this$.selectedWordRange;
}

function WordGroupEditor0__setSelectedWordRange_Z13EDD9F8(this$, range) {
    this$.selectedWordRange = range;
}

function WordGroupEditor0__handleNoteTextChange_Z5B3E8D2(this$, e) {
    this$.noteTextValue = e.target.value;
}

function WordGroupEditor0__focusNoteInput(this$) {
    if (this$.noteInputEl) {
        this$.noteInputEl.focus();
        const value = setTimeout(() => {
            this$.noteInputEl.focus();
        }, 400) | 0;
        void value;
    }
}

function WordGroupEditor0__valuesChanged(this$) {
    let edited = false;
    const origNote = WordGroupEditor0__wordGroup(this$).content.note;
    const origRange = new IdRange(WordGroupEditor0__wordGroup(this$).anchors.startWordId, WordGroupEditor0__wordGroup(this$).anchors.endWordId);
    if (origNote) {
        if (this$.noteTextValue !== origNote.trimStart()) {
            edited = true;
        }
    }
    else if (this$.noteTextValue.length > 0) {
        edited = true;
    }
    if (edited ? true : (origRange.starts !== this$.selectedWordRange.starts)) {
        return true;
    }
    else {
        return origRange.ends !== this$.selectedWordRange.ends;
    }
}

function WordGroupEditor0__handleWordGroupChangeSubmit(this$) {
    const group = WordGroupEditor0__wordGroup(this$);
    let data;
    const id = group.id;
    const subKind = group.subKind;
    const content = new WordGroupContent(this$.noteTextValue, null);
    data = (new WordGroupDTO(id, subKind, new SpanAnchors(this$.selectedWordRange.starts, this$.selectedWordRange.ends), content));
    wordGroupActions.update(data);
}

function WordGroupEditor0__handleWordGroupDeleteSubmit(this$) {
    wordGroupActions.removeFocused();
}

export const WordGroupEditor = (() => {
    return observer(WordGroupEditor0);
})();

/* eslint-disable import/first */
import {observable, computed, action, makeObservable} from "mobx";
// JS BOILERPLATE GENERATED
 