import { FSharpRef } from "../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type } from "../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { getKindFromId, IdRange, IndexRange, System_Array__$005B$005D$1_append_1505, System_Array__$005B$005D$1_get_lastIndex } from "../basic-types.fs.js";
import { Interval, SortedOptsRecord, SortedOpts, Sorted, NO_INDEX } from "../sorted/sorted.fs.js";
import lodash from "lodash";
import { WORD } from "./element-types.fs.js";
import { tryFind, map as map_1, delay, rangeNumber } from "../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { map as map_2 } from "../../.fable/fable-library.3.1.0-beta-001/Option.js";
import { indexed, replicate } from "../../.fable/fable-library.3.1.0-beta-001/Array.js";

export class ElementList0 {
    constructor(elements0, episodeKey0, domain0, words0, wordsIndexMapping0, idToIndex0, idToIndexF0) {
        const s0 = new FSharpRef(null);
        s0.contents = this;
        this.self = (s0.contents);
        this.episodeKey = episodeKey0;
        this.domain = domain0;
        this.words = words0;
        this.wordsIndexMapping = wordsIndexMapping0;
        this.elements = elements0;
        this.kindsSublists = (new Map());
        this.idToIndexF = idToIndexF0;
        this.idToIndexMap0 = idToIndex0;
        this.wordIntervals0 = (null);
        this.timeIntervals0 = (null);
        this["init@62"] = 1;
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    addKindsSubLists(map) {
        return ElementList0__addKindsSubLists_1505(this, map)
    }
    difference(elementList) {
        return ElementList0__difference_Z1712FFE(this, elementList)
    }
    endTime(id) {
        return ElementList0__endTime_Z721C83C5(this, id)
    }
    endWordAddress(id) {
        return ElementList0__endWordAddress_Z721C83C5(this, id)
    }
    filter(f) {
        return ElementList0__filter_Z2B0597EF(this, f)
    }
    findNext(id, f) {
        return ElementList0__findNext_Z61569476(this, id, f)
    }
    findPrevious(id, f) {
        return ElementList0__findPrevious_Z61569476(this, id, f)
    }
    findStep(id, f, direction) {
        return ElementList0__findStep_Z2194856A(this, id, f, direction)
    }
    fromIds(ids) {
        return ElementList0__fromIds_Z6B4C8463(this, ids)
    }
    fromIndexes(indexes) {
        return ElementList0__fromIndexes_13C0A23A(this, indexes)
    }
    getDomainIndex(id) {
        return ElementList0__getDomainIndex_Z721C83C5(this, id)
    }
    getElement(id) {
        return ElementList0__getElement_Z721C83C5(this, id)
    }
    getElementContainingTime(time) {
        return ElementList0__getElementContainingTime_Z524259A4(this, time)
    }
    getElementContainingWordAddress(address) {
        return ElementList0__getElementContainingWordAddress_Z524259A4(this, address)
    }
    getElementContainingWordId(id) {
        return ElementList0__getElementContainingWordId_Z721C83C5(this, id)
    }
    getElementsIntersectWordIdRange(wordRange) {
        return ElementList0__getElementsIntersectWordIdRange_Z13EDD9F8(this, wordRange)
    }
    getId(index) {
        return ElementList0__getId_Z524259A4(this, index)
    }
    getIndex(id) {
        return ElementList0__getIndex_Z721C83C5(this, id)
    }
    getKindSubList(kind) {
        return ElementList0__getKindSubList_Z721C83C5(this, kind)
    }
    getKindsSubLists(kinds) {
        return ElementList0__getKindsSubLists_Z6B4C8463(this, kinds)
    }
    getKindsSubListsAsArray(kinds) {
        return ElementList0__getKindsSubListsAsArray_Z6B4C8463(this, kinds)
    }
    getTimeInterval(id) {
        return ElementList0__getTimeInterval_Z721C83C5(this, id)
    }
    getWordInterval(id) {
        return ElementList0__getWordInterval_Z721C83C5(this, id)
    }
    hasElement(id) {
        return ElementList0__hasElement_Z721C83C5(this, id)
    }
    hasElementsIntersectWordIdRange(wordRange) {
        return ElementList0__hasElementsIntersectWordIdRange_Z13EDD9F8(this, wordRange)
    }
    idRangeAsElementSeq(range) {
        return ElementList0__idRangeAsElementSeq_Z13EDD9F8(this, range)
    }
    idRangeAsIdSeq(range) {
        return ElementList0__idRangeAsIdSeq_Z13EDD9F8(this, range)
    }
    idRangeToIndexRange(range) {
        return ElementList0__idRangeToIndexRange_Z13EDD9F8(this, range)
    }
    indexRangeToIdRange(range) {
        return ElementList0__indexRangeToIdRange_ZDD3925(this, range)
    }
    joinWithIdMap(key, map, defaults) {
        return ElementList0__joinWithIdMap_Z3B30EC65(this, key, map, defaults)
    }
    map(f) {
        return ElementList0__map_1505(this, f)
    }
    nextId(id, useWordAddresses) {
        return ElementList0__nextId_Z55EFCE8F(this, id, useWordAddresses)
    }
    prevId(id, useWordAddresses) {
        return ElementList0__prevId_Z55EFCE8F(this, id, useWordAddresses)
    }
    rangeAsSeq(range) {
        return ElementList0__rangeAsSeq_ZDD3925(this, range)
    }
    remapContentDimensionedArray(remap, newList) {
        return ElementList0__remapContentDimensionedArray_50698A1F(this, remap, newList)
    }
    stepId(id, useWordAddresses, direction) {
        return ElementList0__stepId_41ABF9CD(this, id, useWordAddresses, direction)
    }
    time(id) {
        return ElementList0__time_Z721C83C5(this, id)
    }
    wordAddress(id) {
        return ElementList0__wordAddress_Z721C83C5(this, id)
    }
    get idToIndexMap() {
        return ElementList0__idToIndexMap(this)
    }
    get timeIntervals() {
        return ElementList0__timeIntervals(this)
    }
    get wordIntervals() {
        return ElementList0__wordIntervals(this)
    }
}

export function ElementList0$reflection() {
    return class_type("ElementList.ElementList0", void 0, ElementList0);
}

export function ElementList0_$ctor_Z56C302EF(elements0, episodeKey0, domain0, words0, wordsIndexMapping0, idToIndex0, idToIndexF0) {
    return new ElementList0(elements0, episodeKey0, domain0, words0, wordsIndexMapping0, idToIndex0, idToIndexF0);
}

function ElementList0__idToIndexMap(this$) {
    if (this$.idToIndexMap0) {
        return this$.idToIndexMap0;
    }
    else {
        const result = {};
        for (let i = 0; i <= System_Array__$005B$005D$1_get_lastIndex(this$.elements); i++) {
            result[this$.elements[i].id]=i;
        }
        this$.idToIndexMap0 = result;
        return this$.idToIndexMap0;
    }
}

function ElementList0__getIndex_Z721C83C5(this$, id) {
    if (this$.idToIndexF) {
        return this$.idToIndexF(id) | 0;
    }
    else {
        const result = this$.self.idToIndexMap[id];
        if ((typeof result === 'number')) {
            return (result) | 0;
        }
        else {
            return NO_INDEX | 0;
        }
    }
}

function ElementList0__getId_Z524259A4(this$, index) {
    return this$.elements[index].id;
}

function ElementList0__getElement_Z721C83C5(this$, id) {
    const index = ElementList0__getIndex_Z721C83C5(this$, id) | 0;
    if ((index !== NO_INDEX) ? ((typeof index === 'number')) : false) {
        return this$.elements[ElementList0__getIndex_Z721C83C5(this$, id)];
    }
    else {
        return null;
    }
}

function ElementList0__hasElement_Z721C83C5(this$, id) {
    const x = ElementList0__getElement_Z721C83C5(this$, id);
    return !(lodash.isNull(x));
}

function ElementList0__wordIntervals(this$) {
    let x, x_1;
    if (x = this$.wordIntervals0, lodash.isNull(x)) {
        const startPoints = [];
        const endPoints = [];
        let hasEndPoints = false;
        if (this$.elements.length > 0) {
            if (x_1 = this$.elements[0].endWordAddress, !(lodash.isNil(x_1))) {
                hasEndPoints = true;
            }
        }
        const arr = this$.elements;
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const elem = arr[idx];
            System_Array__$005B$005D$1_append_1505(startPoints, elem.wordAddress);
            if (hasEndPoints) {
                System_Array__$005B$005D$1_append_1505(endPoints, elem.endWordAddress);
            }
        }
        if (hasEndPoints) {
            this$.wordIntervals0 = Sorted(new SortedOptsRecord(startPoints, endPoints, SortedOpts.domainStart, SortedOpts.domainEnd));
        }
        else if (startPoints.length > 0) {
            this$.wordIntervals0 = Sorted(new SortedOptsRecord(startPoints, null, SortedOpts.domainStart, SortedOpts.domainEnd));
        }
        else {
            this$.wordIntervals0 = Sorted(new SortedOptsRecord(startPoints, new Int32Array([]), SortedOpts.domainStart, SortedOpts.domainEnd));
        }
    }
    return this$.wordIntervals0;
}

function ElementList0__timeIntervals(this$) {
    let x;
    if (x = this$.timeIntervals0, lodash.isNull(x)) {
        const startPoints = [];
        const endPoints = [];
        const arr = this$.elements;
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const elem = arr[idx];
            System_Array__$005B$005D$1_append_1505(startPoints, elem.time);
            System_Array__$005B$005D$1_append_1505(endPoints, elem.endTime);
        }
        this$.timeIntervals0 = Sorted(new SortedOptsRecord(startPoints, endPoints, 0, 0));
    }
    return this$.timeIntervals0;
}

function ElementList0__idRangeToIndexRange_Z13EDD9F8(this$, range) {
    return new IndexRange(ElementList0__getIndex_Z721C83C5(this$, range.starts), ElementList0__getIndex_Z721C83C5(this$, range.ends));
}

function ElementList0__indexRangeToIdRange_ZDD3925(this$, range) {
    return new IdRange(ElementList0__getId_Z524259A4(this$, range.starts), ElementList0__getId_Z524259A4(this$, range.ends));
}

function ElementList0__stepId_41ABF9CD(this$, id, useWordAddresses, direction) {
    const isIdRange = (el) => false;
    const index = ElementList0__getIndex_Z721C83C5(this$, id) | 0;
    if (index !== NO_INDEX) {
        const testIndex = (index + direction) | 0;
        if ((testIndex >= 0) ? (testIndex <= System_Array__$005B$005D$1_get_lastIndex(this$.elements)) : false) {
            return this$.elements[index + direction].id;
        }
        else {
            return null;
        }
    }
    else if (this$.domain.hasElement(id)) {
        const nextElement = this$.domain.findStep(id, (el_1) => ElementList0__hasElement_Z721C83C5(this$, el_1.id), direction);
        if (nextElement) {
            return nextElement.id;
        }
        else {
            return null;
        }
    }
    else if (useWordAddresses) {
        if (getKindFromId(id) === WORD) {
            const index_1 = this$.words.getIndex(id) | 0;
            const wordIntervals = this$.self.wordIntervals;
            const elementIndex = ((direction === 1) ? wordIntervals.firstStartsAfter(index_1) : wordIntervals.lastEndsBeforeOrAt(index_1)) | 0;
            if (elementIndex !== NO_INDEX) {
                return this$.elements[elementIndex].id;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
}

function ElementList0__nextId_Z55EFCE8F(this$, id, useWordAddresses) {
    return ElementList0__stepId_41ABF9CD(this$, id, useWordAddresses, 1);
}

function ElementList0__prevId_Z55EFCE8F(this$, id, useWordAddresses) {
    return ElementList0__stepId_41ABF9CD(this$, id, useWordAddresses, -1);
}

function ElementList0__rangeAsSeq_ZDD3925(this$, range) {
    return rangeNumber(range.starts, 1, range.ends);
}

function ElementList0__idRangeAsIdSeq_Z13EDD9F8(this$, range) {
    const indexSeq = ElementList0__rangeAsSeq_ZDD3925(this$, ElementList0__idRangeToIndexRange_Z13EDD9F8(this$, range));
    return delay(() => map_1((index) => this$.elements[index].id, indexSeq));
}

function ElementList0__idRangeAsElementSeq_Z13EDD9F8(this$, range) {
    const indexSeq = ElementList0__rangeAsSeq_ZDD3925(this$, ElementList0__idRangeToIndexRange_Z13EDD9F8(this$, range));
    return delay(() => map_1((index) => this$.elements[index], indexSeq));
}

function ElementList0__findNext_Z61569476(this$, id, f) {
    const start = ElementList0__getIndex_Z721C83C5(this$, id) | 0;
    const f_1 = (index) => f(this$.elements[index]);
    const result = map_2((i) => this$.elements[i], tryFind(f_1, rangeNumber(start, 1, System_Array__$005B$005D$1_get_lastIndex(this$.elements))));
    return result;
}

function ElementList0__findPrevious_Z61569476(this$, id, f) {
    const ends = ElementList0__getIndex_Z721C83C5(this$, id) | 0;
    const f_1 = (index) => f(this$.elements[index]);
    const result = map_2((i) => this$.elements[ends - i], tryFind(f_1, rangeNumber(ends, -1, 0)));
    return result;
}

function ElementList0__findStep_Z2194856A(this$, id, f, direction) {
    if (direction === -1) {
        return ElementList0__findPrevious_Z61569476(this$, id, f);
    }
    else {
        return ElementList0__findNext_Z61569476(this$, id, f);
    }
}

function ElementList0__filter_Z2B0597EF(this$, f) {
    const filtered = [];
    const filterDomain = this$.domain ? this$.domain : this$.self;
    const arr = this$.elements;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const element = arr[idx];
        if (f(element)) {
            System_Array__$005B$005D$1_append_1505(filtered, element);
        }
    }
    return ElementList0_$ctor_Z56C302EF(filtered, this$.episodeKey, filterDomain, this$.words, this$.wordsIndexMapping, null, null);
}

function ElementList0__fromIds_Z6B4C8463(this$, ids) {
    const filtered = [];
    for (let idx = 0; idx <= (ids.length - 1); idx++) {
        const id = ids[idx];
        const element = ElementList0__getElement_Z721C83C5(this$, id);
        if (element) {
            System_Array__$005B$005D$1_append_1505(filtered, element);
        }
    }
    return ElementList0_$ctor_Z56C302EF(filtered, this$.episodeKey, this$.domain, this$.words, this$.wordsIndexMapping, null, null);
}

function ElementList0__fromIndexes_13C0A23A(this$, indexes) {
    const filtered = [];
    for (let idx = 0; idx <= (indexes.length - 1); idx++) {
        const index = indexes[idx] | 0;
        const element = this$.elements[index];
        if (element) {
            System_Array__$005B$005D$1_append_1505(filtered, element);
        }
    }
    return ElementList0_$ctor_Z56C302EF(filtered, this$.episodeKey, this$.domain, this$.words, this$.wordsIndexMapping, null, null);
}

function ElementList0__map_1505(this$, f) {
    return void 0;
}

function ElementList0__getKindSubList_Z721C83C5(this$, kind) {
    const result = this$.kindsSublists.get(kind);
    if (result) {
        return result;
    }
    else {
        const filtered = [];
        const arr = this$.elements;
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const element = arr[idx];
            if (element.kind === kind) {
                System_Array__$005B$005D$1_append_1505(filtered, element);
            }
        }
        const list = ElementList0_$ctor_Z56C302EF(filtered, this$.episodeKey, this$.domain, this$.words, this$.wordsIndexMapping, null, null);
        this$.kindsSublists.set(kind, list);
        return list;
    }
}

function ElementList0__getKindsSubListsAsArray_Z6B4C8463(this$, kinds) {
    return Array.from(delay(() => map_1((kind) => ElementList0__getKindSubList_Z721C83C5(this$, kind), kinds)));
}

function ElementList0__getKindsSubLists_Z6B4C8463(this$, kinds) {
    const result = {};
    for (let idx = 0; idx <= (kinds.length - 1); idx++) {
        const kind = kinds[idx];
        result[kind]=ElementList0__getKindSubList_Z721C83C5(this$, kind);
    }
    return result;
}

function ElementList0__wordAddress_Z721C83C5(this$, id) {
    return ElementList0__getElement_Z721C83C5(this$, id).wordAddress;
}

function ElementList0__endWordAddress_Z721C83C5(this$, id) {
    return ElementList0__getElement_Z721C83C5(this$, id).endWordAddress;
}

function ElementList0__getWordInterval_Z721C83C5(this$, id) {
    const element = ElementList0__getElement_Z721C83C5(this$, id);
    return new Interval(element.wordAddress, element.endWordAddress);
}

function ElementList0__getElementsIntersectWordIdRange_Z13EDD9F8(this$, wordRange) {
    const wordIntervals = this$.self.wordIntervals;
    const wordIndexRange = this$.words.idRangeToIndexRange(wordRange);
    const elementRange = wordIntervals.rangeIntersecting(wordIndexRange.starts, wordIndexRange.ends);
    if (elementRange) {
        return Array.from(delay(() => map_1((i) => this$.elements[i], rangeNumber(elementRange.starts, 1, elementRange.ends))));
    }
    else {
        return null;
    }
}

function ElementList0__hasElementsIntersectWordIdRange_Z13EDD9F8(this$, wordRange) {
    const wordIntervals = this$.self.wordIntervals;
    const wordIndexRange = this$.words.idRangeToIndexRange(wordRange);
    const x = wordIntervals.rangeIntersecting(wordIndexRange.starts, wordIndexRange.ends);
    return !(lodash.isNil(x));
}

function ElementList0__time_Z721C83C5(this$, id) {
    return ElementList0__getElement_Z721C83C5(this$, id).time;
}

function ElementList0__endTime_Z721C83C5(this$, id) {
    return ElementList0__getElement_Z721C83C5(this$, id).endTime;
}

function ElementList0__getTimeInterval_Z721C83C5(this$, id) {
    const element = ElementList0__getElement_Z721C83C5(this$, id);
    return new Interval(element.time, element.endTime);
}

function ElementList0__getElementContainingWordAddress_Z524259A4(this$, address) {
    const wordIntervals = this$.self.wordIntervals;
    const elementIndex = wordIntervals.containing(address) | 0;
    if (elementIndex !== NO_INDEX) {
        return this$.elements[elementIndex];
    }
    else {
        return null;
    }
}

function ElementList0__getElementContainingWordId_Z721C83C5(this$, id) {
    const wordIndex = this$.words.getIndex(id) | 0;
    return ElementList0__getElementContainingWordAddress_Z524259A4(this$, wordIndex);
}

function ElementList0__getElementContainingTime_Z524259A4(this$, time) {
    const timeIntervals = this$.self.timeIntervals;
    const elementIndex = timeIntervals.containing(time) | 0;
    if (elementIndex !== NO_INDEX) {
        return this$.elements[elementIndex];
    }
    else {
        return null;
    }
}

function ElementList0__getDomainIndex_Z721C83C5(this$, id) {
    return this$.domain.getIndex(id);
}

function ElementList0__addKindsSubLists_1505(this$, map) {
}

function ElementList0__joinWithIdMap_Z3B30EC65(this$, key, map, defaults) {
    const mergedElements = [];
    let attach = defaults;
    const arr = this$.elements;
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const element0 = arr[idx];
        const element = Object.assign({}, element0);
        attach = defaults;
        const toJoin = map[element.id];
        if (toJoin) {
            attach = Object.assign({}, defaults);
            attach = Object.assign(attach, toJoin);
        }
        element[key]=attach;
        System_Array__$005B$005D$1_append_1505(mergedElements, element);
    }
    return ElementList0_$ctor_Z56C302EF(mergedElements, this$.episodeKey, null, this$.words, this$.wordsIndexMapping, null, null);
}

function ElementList0__difference_Z1712FFE(this$, elementList) {
    return ElementList0__filter_Z2B0597EF(this$, (element) => (!elementList.hasElement(element.id)));
}

function ElementList0__remapContentDimensionedArray_50698A1F(this$, remap, newList) {
    if (lodash.isNull(remap)) {
        return null;
    }
    else {
        const len = newList.elements.length | 0;
        const result = replicate(len + 1, null);
        const arr = indexed(remap);
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const forLoopVar = arr[idx];
            const value = forLoopVar[1];
            const i = forLoopVar[0] | 0;
            const id = ElementList0__getId_Z524259A4(this$, i);
            const newIndex = newList.getIndex(id) | 0;
            result[newIndex] = value;
        }
        return result;
    }
}

export function ElementList(elements0, episodeKey0, domain0, words0, wordsIndexMapping0, idToIndex0, idToIndexF0) {
    return ElementList0_$ctor_Z56C302EF(elements0, episodeKey0, domain0, words0, wordsIndexMapping0, idToIndex0, idToIndexF0);
}

export function SimpleElementList0(elements0) {
    return ElementList0_$ctor_Z56C302EF(elements0, "", null, null, null, null, null);
}

export function SimpleElementList(elements0) {
    return ElementList0_$ctor_Z56C302EF(elements0, "", null, SimpleElementList0([]), null, null, null);
}

export const EmptyElementList = SimpleElementList([]);

// JS BOILERPLATE GENERATED
 