import React from 'react';
import { observer } from 'mobx-react';
import { ConversationView } from "./ui/conversation-view";

export const DiscussionSidepanel = observer(({element, warning}) => {

  return (
    <>
      <div className="right-sidepanel-header" >Element Comments</div>
      {warning
        ? <div className="focused-element-warning"> Warning: {warning.message} </div>
        : null
      }
      <ConversationView element={element} />
    </>
  );
});
